import * as types from '../types';

const initialState = {
  data: [],
  error: false,
  pending: false,
  passwordChanged: false,
  newPasswordData: {
    code: '',
    email: '',
    password: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_PASSWORD.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
        passwordChanged: false,
      };
    }
    case types.RESET_PASSWORD.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: false,
        pending: false,
      };
    }
    case types.RESET_PASSWORD.ERROR: {
      return {
        ...state,
        data: action.payload,
        error: true,
        pending: false,
      };
    }

    case types.SET_USER_EMAIL: {
      return {
        ...state,
        newPasswordData: {
          ...state.newPasswordData,
          email: action.payload.email,
        },
      };
    }

    case types.SET_USER_PASSWORD: {
      return {
        ...state,
        newPasswordData: {
          ...state.newPasswordData,
          password: action.payload.password,
        },
      };
    }

    case types.CHANGE_PASSWORD.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
        passwordChanged: false,
      };
    }
    case types.CHANGE_PASSWORD.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: false,
        pending: false,
        passwordChanged: true,
      };
    }
    case types.CHANGE_PASSWORD.ERROR: {
      return {
        ...state,
        data: action.payload,
        error: true,
        pending: false,
      };
    }

    case types.CLEAR_PASSWORD_ERRORS: {
      return {
        ...state,
        error: false,
        pending: false,
      };
    }

    case types.CLEAR_PASSWORD_DATA: {
      return {
        ...initialState,
      };
    }

    case types.VALIDATE_SECURITY_CODE.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
        passwordChanged: false,
      };
    }
    case types.VALIDATE_SECURITY_CODE.SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.payload.data,
        newPasswordData: {
          ...state.newPasswordData,
          code: action.payload.userData.code,
          email: action.payload.userData.email,
        },
      };
    }
    case types.VALIDATE_SECURITY_CODE.ERROR: {
      return {
        ...state,
        data: action.payload,
        error: true,
        pending: false,
      };
    }

    default: {
      return state;
    }
  }
};
