/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import Cookies from 'js-cookie';
import api from '../../../../api';

export const getSchoolData = createAsyncThunk(
  'school/get_school_data',
  async ({ lat, lng, reachableIn, transitMethod }) => {
    const cookie = JSON.parse(Cookies.get(process.env.REACT_APP_AUTH_COOKIE));
    try {
      const transitMethodType =
        transitMethod === 'DRIVING' ? 'drive' : 'WALKING' ? 'foot' : 'BICYCLING' ? 'bike' : 'foot';
      const url = `school/nearby?origin=coordinate:${lng},${lat}&reachableIn=${reachableIn}mins&transitMethod=${transitMethodType}`;
      const response = await api.get(url, {
        headers: { Authorization: `Bearer ${cookie.access_token}` },
      });
      if (!response) throw new Error('Could not fetch');
      const { closestSchools, schools } = response.data.payload;
      return {
        closestSchools: isArray(closestSchools) ? closestSchools[0] : null,
        schools,
      };
    } catch (err) {
      // handle error
      throw new Error('No Schools found');
    }
  },
);
