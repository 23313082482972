import * as Theme from './Theme';
import * as Mocks from './Mocks';
import * as endpoints from './api_enpoint';

// const ApiEndpoints = process.env.REACT_APP_ENV === 'development' ? dev : production;
// process.env.REACT_APP_ENV can be one of `development`, `fs`, `production`

const ApiEndpoints = endpoints['production'];
// const ApiEndpoints = endpoints[process.env.NODE_ENV]

export { Theme, Mocks, ApiEndpoints };
