/**
 * Set a new state whenever a search term is typed in the search box
 */
const searchTerm = (defaultState = '', action) => {
  if (action.type === 'SEARCH_TERM') {
    return action.payload;
  }
  return defaultState;
};

const esSearchReducer = (defaultState = [], action) => {
  switch (action.type) {
    case 'POPULATE_ES_RESULT':
      return action.payload;
    default:
      return defaultState;
  }
};

/**
 * Set a new state when an item is selected from the search result suggestions
 */
const esItemSelected = (state = {}, action) => {
  if (action.type === 'SEARCH_RESULT_SELECTED') {
    return action.payload;
  }
  return state;
};

const isSuggestionSelected = (defaultState = false, action) => {
  if (action.type === 'SUGESTION_SELECTED') {
    return action.payload;
  }
  return defaultState;
};

export { isSuggestionSelected, searchTerm, esSearchReducer, esItemSelected };
