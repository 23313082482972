import {
  SET_BUCKETS,
  SET_CURRENT_BUCKET_DETAILS,
  SET_PROFILE_LISTENER,
  SET_MATCHES_CURRENT_DATA,
  SET_SHOW_MATCHES_LIST,
} from '../types';

const initialState = {
  profileListener: 0,
  data: {},
  showList: false,
  currentBucket: null,
  loading: false,
  propertyData: null,
  propertyInfo: null,
  propertyWeather: null,
  propertyCenter: [0, 1],
};

const matchesReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE_LISTENER:
      return { ...state, profileListener: Math.random() };
    case SET_BUCKETS:
      return {
        ...state,
        data: action.payload,
      };
    case SET_SHOW_MATCHES_LIST:
      return { ...state, showList: action.payload };
    case SET_CURRENT_BUCKET_DETAILS:
      return {
        ...state,
        currentBucket: action.payload.currentBucket,
        bucketTitle: action.payload.bucketTitle,
      };
    case SET_MATCHES_CURRENT_DATA.PENDING: {
      return { ...state, loading2: true };
    }
    case SET_MATCHES_CURRENT_DATA.SUCCESS: {
      return {
        ...state,
        loading: false,
        propertyData: action.payload.data,
        propertyInfo: action.payload.info,
        propertyWeather: action.payload.weather,
        propertyCenter: action.payload.center,
      };
    }
    case SET_MATCHES_CURRENT_DATA.ERROR: {
      return {
        ...state,
        loading2: false,
        propertyData: null,
        propertyInfo: null,
        propertyWeather: null,
        center: { lat: 0, lng: 0 },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default matchesReducers;
