/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import {
  getShortlistedProperties,
  refetchShortlistedProperties,
  shortlistProperty,
  updateShortlistedProperties,
} from './shortlist.action';

const initialState = {
  shortlistedProperties: [],
  pending: false,
  error: null,
  refetchError: null,
  refetchPending: null,
  showShortlistPanel: false,
  value: null,
  propertyId: null,
};

const shortlistSlice = createSlice({
  name: 'shortlist',
  initialState,
  reducers: {
    setShowShortlistPanel(state, action) {
      state.showShortlistPanel = action.payload;
    },
    addShortlist(state, action) {
      state.shortlistedProperties.push(action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getShortlistedProperties.pending, state => {
        state.error = null;
        state.pending = true;
      })
      .addCase(getShortlistedProperties.fulfilled, (state, action) => {
        state.shortlistedProperties = action.payload.properties;
        state.pending = false;
      })
      .addCase(getShortlistedProperties.rejected, (state, action) => {
        state.error = action.error;
        state.pending = false;
      })
      .addCase(refetchShortlistedProperties.pending, state => {
        state.refetchError = null;
        state.refetchPending = true;
      })
      .addCase(refetchShortlistedProperties.fulfilled, (state, action) => {
        state.shortlistedProperties = action.payload.properties;
        state.refetchPending = false;
      })
      .addCase(refetchShortlistedProperties.rejected, (state, action) => {
        state.refetchError = action.error;
        state.refetchPending = false;
      })
      .addCase(updateShortlistedProperties.pending, state => {
        state.error = null;
        state.pending = true;
      })
      .addCase(updateShortlistedProperties.fulfilled, (state, action) => {
        // state.shortlistedProperties = action.payload.data;
        state.pending = false;
        // state.propertyId = action.payload.propertyId;
        state.value = action.payload.value;
      })
      .addCase(updateShortlistedProperties.rejected, (state, action) => {
        // state.propertyId = action.payload.propertyId;
        state.error = action.error;
        state.pending = false;
      })
      .addCase(shortlistProperty.pending, state => {
        state.error = null;
        state.pending = true;
      })
      .addCase(shortlistProperty.fulfilled, (state, action) => {
        // state.shortlistedProperties = action.payload;
        state.pending = false;
        state.propertyId = action.payload.propertyId;
        state.value = action.payload.value;
      })
      .addCase(shortlistProperty.rejected, (state, action) => {
        // state.propertyId = action.payload.propertyId;
        state.error = action.error;
        state.pending = false;
      });
  },
});

export const { setShowShortlistPanel, addShortlist } = shortlistSlice.actions;

export default shortlistSlice.reducer;
