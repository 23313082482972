import axios from 'axios';
import {
  SET_TRAVEL_MODE_SCHOOL,
  SET_TIME_SCHOOL,
  SET_MAPS_SCHOOL,
  SET_MAP_SCHOOL,
  SET_SCHOOL_DATA,
} from '../types';
import { ApiEndpoints } from '../../../../Constants';

export const setMapSchool = map => dispatch => {
  dispatch({ type: SET_MAP_SCHOOL, payload: map });
};

export const setMapsSchool = maps => dispatch => {
  dispatch({ type: SET_MAPS_SCHOOL, payload: maps });
};

export const setTimeSchool = time => dispatch => {
  dispatch({ type: SET_TIME_SCHOOL, payload: time });
};

export const setTravelModeSchool = travelMode => dispatch => {
  dispatch({ type: SET_TRAVEL_MODE_SCHOOL, payload: travelMode });
};

export const getSchoolData = ({
  lat,
  lng,
  reachableIn,
  transitMethod,
  enqueueSnackbar,
}) => dispatch => {
  dispatch({ type: SET_SCHOOL_DATA.PENDING, payload: null });

  const transitMethodType =
    transitMethod == 'DRIVING' ? 'drive' : 'WALKING' ? 'foot' : 'BICYCLING' ? 'bike' : 'foot';
  const url = `${ApiEndpoints.api_endpoint}school/nearby?origin=coordinate:${lng},${lat}&reachableIn=${reachableIn}mins&transitMethod=${transitMethodType}`;

  axios({ method: 'get', url })
    .then(response => {
      const { closestSchools, schools } = response.data.payload;
      dispatch({
        type: SET_SCHOOL_DATA.SUCCESS,
        payload: { closestSchools: closestSchools[0], schools: [closestSchools[0], ...schools] },
      });
    })
    .catch(() => {
      dispatch({ type: SET_SCHOOL_DATA.ERROR, payload: { closestSchool: null, schools: [] } });
      enqueueSnackbar(`No schools exist in this criteria`, {
        variant: 'info',
      });
    });
};
