/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { changePassword, resetPassword, validateSecurityCode } from './password.actions';

const initialState = {
  data: [],
  error: false,
  pending: false,
  passwordChanged: false,
  newPasswordData: {
    code: '',
    email: '',
    password: '',
  },
};
const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    setUserEmail(state, action) {
      state.newPasswordData = {
        ...state.newPasswordData,
        email: action.payload.email,
      };
    },

    setUserPassword(state, action) {
      state.newPasswordData = {
        ...state.newPasswordData,
        password: action.payload.password,
      };
    },
    clearPasswordErrors(state) {
      state.error = null;
      state.pending = false;
    },
    clearPasswordData() {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetPassword.pending, state => {
        state.pending = true;
        state.error = null;
        state.passwordChanged = false;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.pending = false;
        state.error = { message: action.error.message };
      })
      .addCase(changePassword.pending, state => {
        state.pending = true;
        state.error = null;
        state.passwordChanged = false;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.data = action.payload;
        state.passwordChanged = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.pending = false;
        state.error = { message: action.error.message };
      })
      .addCase(validateSecurityCode.pending, state => {
        state.pending = true;
        state.error = null;
        state.passwordChanged = false;
      })
      .addCase(validateSecurityCode.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.data = action.payload;
        state.newPasswordData = {
          ...state.newPasswordData,
          code: action.payload.userData.code,
          email: action.payload.userData.email,
        };
      })
      .addCase(validateSecurityCode.rejected, (state, action) => {
        state.pending = false;
        state.error = { message: action.error.message };
      });
  },
});

export const {
  setUserEmail,
  setUserPassword,
  clearPasswordData,
  clearPasswordErrors,
} = passwordSlice.actions;

export default passwordSlice.reducer;
