import * as types from '../types';
import api from '../../../../api';

export const setAuthHeader = async data => {
  const refreshToken = data.auth?.refresh_token ?? data.auth?.auth?.refresh_token;
  api.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;
  const request = await api.post(`/auth/refresh`);
  const acccessToken = await request.data.access_token;
  api.defaults.headers.common.Authorization = `Bearer ${acccessToken}`;
};

export const getShortlistProperties = ({ data }) => async dispatch => {
  dispatch({ type: types.GET_ALL_SHORTLIST.PENDING, payload: null });
  await setAuthHeader(data);
  await api
    .get(`/customer/current_customer/shortlist`)
    .then(response => {
      dispatch({
        type: types.GET_ALL_SHORTLIST.SUCCESS,
        payload: { list: response.data.properties, count: response.data.count },
      });
    })
    .catch(() => {
      dispatch({ type: types.GET_ALL_SHORTLIST.ERROR, payload: { list: [], count: null } });
    });
};

export const setPropertyId = value => async dispatch => {
  dispatch({ type: types.SET_PROPERTY_ID, payload: value });
};
export const setCurrentIndex = value => async dispatch => {
  dispatch({ type: types.SET_PROPERTY_INDEX, payload: value });
};
export const getPropertyData = ({ propertyId }) => async dispatch => {
  dispatch({ type: types.SET_SHORTLIST_CURRENT_DATA.PENDING, payload: null });

  async function asyncPromAll() {
    let data = null;
    let info = null;
    let weather = null;
    let center = { lat: 0, lng: 0 };
    const resultArray = await Promise.all([
      api.get(`property/${propertyId}/information`),
      api.get(`property/${propertyId}/media-data`),
      api.get(`property/weather/${propertyId}/average`),
    ]);
    resultArray.forEach(item => {
      if (item.config.url === `property/${propertyId}/information`) info = item.data;
      if (item.config.url === `property/${propertyId}/media-data`) {
        data = item.data;
        center = { lat: item.data.latitude, lng: item.data.longitude };
      }
      if (item.config.url === `property/weather/${propertyId}/average`) {
        const temp = item.data.month.map((month, index) => ({
          id: item.data.weather_id[index],
          month,
          temperature: item.data.average_temperature[index],
        }));
        weather = { temp, address: item.data.address };
      }
    });
    dispatch({
      type: types.SET_SHORTLIST_CURRENT_DATA.SUCCESS,
      payload: { data, info, weather, center },
    });
  }
  asyncPromAll();
};
