import { combineReducers } from 'redux';

const property_address = (default_state = { addresses: [] }, action) => {
  if (action.type === 'GET_ADDRESS') {
    return action.payload;
  }
  return default_state;
};

const postcode = (default_state = '', action) => {
  if (action.type === 'SET_POSTCODE') {
    return action.payload;
  }
  return default_state;
};

export default combineReducers({
  property_address: property_address,
  postcode: postcode,
});
