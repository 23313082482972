import { GET_PROPERTIES, GET_SAVED_PROPERTIES, SET_PROPERTY_INDEX } from '../types';

export const setPropertyIndex = (initialState = 0, action) => {
  if (action.type === SET_PROPERTY_INDEX) return action.payload;
  return initialState;
};

export const allPropertiesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROPERTIES.PENDING: {
      return {
        ...state,
        message: 'PENDING',
      };
    }
    case GET_PROPERTIES.SUCCESS: {
      return {
        ...state,
        allProperties: action.payload,
        message: 'SUCCESS',
      };
    }
    case GET_PROPERTIES.REMOVE: {
      const index = state.allProperties.findIndex(
        value => action.payload == value.vendor_property_criteria_id,
      );

      return {
        ...state,
        allProperties:
          index > -1
            ? [...state.allProperties.slice(0, index), ...state.allProperties.slice(index + 1)]
            : state.allProperties,
      };
    }
    case GET_PROPERTIES.ERROR: {
      return {
        ...state,
        message: 'ERROR',
      };
    }

    case GET_PROPERTIES.ADD_ONE:
      return {
        ...state,
        allProperties: [
          { ...action.payload, customer: action.payload.customer },
          ...state.allProperties,
        ],
      };

    case GET_SAVED_PROPERTIES.SUCCESS: {
      return {
        ...state,
        savedProperties: action.payload,
      };
    }
    case GET_SAVED_PROPERTIES.REMOVE: {
      const index = state.savedProperties.findIndex(
        value => action.payload == value.vendor_property_criteria.vendor_property_criteria_id,
      );

      return {
        ...state,
        savedProperties:
          index > -1
            ? [...state.savedProperties.slice(0, index), ...state.savedProperties.slice(index + 1)]
            : state.savedProperties,
      };
    }
    case GET_SAVED_PROPERTIES.ADD_ONE: {
      return {
        ...state,
        savedProperties: [
          { vendor_property_criteria: { ...action.payload }, vendor: action.payload.customer },
          ...state.savedProperties,
        ],
      };
    }
    default:
      return state;
  }
};
