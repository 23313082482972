/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as types from '../types';

const initialState = {
  data: {},
  error: false,
  pending: false,
};

export default produce((draft, action) => {
  switch (action.type) {
    case types.GET_COST_OF_LIVING.PENDING:
      draft.data = {};
      draft.error = false;
      draft.pending = true;
      break;

    case types.GET_COST_OF_LIVING.SUCESS:
      draft.data = action.payload;
      draft.error = false;
      draft.pending = false;
      break;

    case types.GET_COST_OF_LIVING.ERROR:
      draft.data = {};
      draft.error = true;
      draft.pending = false;
      break;

    default:
      break;
  }
}, initialState);
