/* eslint-disable import/no-cycle */
/* eslint-disable prefer-promise-reject-errors */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setActiveId, setViewType } from './search.slice';
import { setActiveMapData } from '../map/map.slice';
import api, { endpoints } from '../../../../api';
import { setProperties } from '../../../propertiesV2/slices/property/property.slice';
import { reportGAAction } from '../../../../utils/helpers/analytics';

export const getMatchedProperties = (mapDataList = [], type) => async dispatch => {
  const typeMap = { student: 'student_accommodation', care_homes: 'care_home', venues: 'venue' };
  const placeType = typeMap[type] || type;

  const fetchPropertyData = async propertyId => {
    const placeResponse = await api.get(`/customer/place/${placeType}/${propertyId}`);
    const placeInfo = {
      property_id: propertyId,
      type: placeType,
      ...placeResponse.data.place_info,
    };

    const imageResponse = await api.get(`/blob/place_images/${placeType}/${propertyId}`);
    const images = imageResponse.data.images.map(imgPath => `${endpoints.blob_url}/${imgPath}`);
    return {
      ...placeInfo,
      images,
      price: placeInfo.price || placeInfo.care_home_price,
      name: placeInfo.carehome_name || placeInfo.stu_acc_name || placeInfo.venue_name,
    };
  };

  const properties = await Promise.all(mapDataList.map(fetchPropertyData));

  dispatch(setProperties(properties));
};

export const updateMap = ({ roomId, type, docIds, ...rest }) => dispatch => {
  dispatch(setActiveId(roomId));

  const { outputIds = [] } = rest;

  const propertyType = type;
  const formattedMapData = Object.entries(docIds).map(([k, v]) => {
    return {
      ...v,
      property_id: k,
      place_id: k,
      type: propertyType,
      recommended: outputIds.includes(String(k)),
    };
  });
  const mapData = { homes: formattedMapData };
  dispatch(setViewType('detail'));
  dispatch(setActiveMapData(mapData));
};

export const handlePropertySearch = createAsyncThunk(
  'search/handle-property-search',
  async ({ text, id, history, refreshToken }, {}) => {
    try {
      reportGAAction({
        category: 'Input',
        action: 'Search input',
      });
      if (refreshToken) {
        const tokenResponse = await api.post(`auth/refresh`, undefined, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        const { access_token: access } = tokenResponse.data;
        api.defaults.headers.common.Authorization = `Bearer ${access}`;
      }
      const endpoint = !refreshToken ? `${endpoints.aiEngineURL}/search2` : '/chats/prompt/';
      const response = await api.post(
        endpoint,
        id
          ? {
              room_id: id,
              prompt: text,
            }
          : { prompt: text, query: text, model: 'claude' },
      );
      if (!response) throw new Error('Could not fetch');

      if (refreshToken) {
        const roomId = id || response.data.data.room_id;

        history.push(`/search?room=${roomId}`);
      } else {
        const res = {
          data: {
            data: {
              prompt: text,
              room_id: 'new-chat',
              agent_response: response.data,
            },
          },
        };

        return res;
      }

      return response;
    } catch (err) {
      throw new Error('Could not fetch');
    }
  },
);
