import { toast } from 'react-toastify';
import api from '../api';

const getDirections = async ({
  origin,
  destination,
  travelMethod,
  destinationType = 'coordinate',
  contents,
}) => {
  let travelMethodFormatted;

  switch (travelMethod) {
    case 'WALKING':
      travelMethodFormatted = 'foot';
      break;

    case 'DRIVING':
      travelMethodFormatted = 'drive';
      break;

    case 'BICYCLING':
      travelMethodFormatted = 'bike';
      break;
    case 'drive':
      travelMethodFormatted = 'drive';
      break;
    case 'bike':
      travelMethodFormatted = 'bike';
      break;
    case 'foot':
      travelMethodFormatted = 'foot';
      break;

    default:
      travelMethodFormatted = 'foot';
  }
  const response = await api.get(
    `routine/direction?origin=coordinate:${origin?.lng},${origin?.lat}&destination=${
      destinationType === 'coordinate'
        ? `coordinate:${destination?.lng},${destination?.lat}`
        : `place:${destination}`
    }&transitMethod=${travelMethodFormatted}`,
  );

  if (!response.data?.payload) {
    return toast.error('Could not process your request', { position: 'bottom-left' });
  }

  const { route, markers = [] } = response.data.payload;

  if (markers.length === 0) {
    return toast.error('There are no POI in your current search', { position: 'bottom-left' });
  }

  const { distance = 0, duration = 0, geojson } = route;

  const coordinates = (geojson?.features).reduce((accumFeatures, feature) => {
    const coordinatesForFeature = feature.geometry.coordinates.reduce((accum, coordinate) => {
      return [
        ...accum,
        {
          lat: coordinate[1],
          lng: coordinate[0],
        },
      ];
    }, []);

    return [...accumFeatures, ...coordinatesForFeature];
  }, []);

  return {
    ...contents,
    distance,
    duration,
    coordinates: [origin, ...coordinates, destinationType === 'place' ? markers[0] : destination],
    markers,
  };
};

export default getDirections;
