export const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const GET_ALL_SHORTLIST = asyncType('GET_ALL_SHORTLIST');
export const SET_SHORTLIST_CURRENT_DATA = asyncType('SET_SHORTLIST_CURRENT_DATA');
export const SET_PROPERTY_INDEX = 'SET_PROPERTY_INDEX';
export const SET_PROPERTY_ID = 'SET_PROPERTY_ID';
