import {
  SET_BUCKETS,
  SET_CURRENT_BUCKET_DETAILS,
  SET_PROFILE_LISTENER,
  SET_SHOW_MATCHES_LIST,
  SET_MATCHES_CURRENT_DATA,
} from '../types';
import api from '../../../../api';

export const profileListener = () => dispatch => {
  dispatch({ type: SET_PROFILE_LISTENER, payload: null });
};
export const setBucket = ({ data, list }) => dispatch => {
  const newObject = {};
  Object.keys(data).forEach(item => {
    if (data[item].status === 'success') {
      const shortlistIDs = Object.values(list).map(id => id.property_id);
      const newBucketList = data[item].properties.filter(
        ob => !shortlistIDs.includes(ob.propertyId),
      );
      newObject[item] = { ...data[item], properties: newBucketList };
    } else {
      newObject[item] = { properties: [], propertyCounts: 0, status: 'fail' };
    }
  });
  dispatch({ type: SET_BUCKETS, payload: newObject });
};

export const setShowMatchesList = data => dispatch => {
  dispatch({ type: SET_SHOW_MATCHES_LIST, payload: data });
};

export const setCurrentBucketDetails = (title, data) => dispatch => {
  const currentBucket = data;
  dispatch({
    type: SET_CURRENT_BUCKET_DETAILS,
    payload: { currentBucket, bucketTitle: title },
  });
};

export const getMatchesData = ({ propertyId }) => async dispatch => {
  dispatch({ type: SET_MATCHES_CURRENT_DATA.PENDING, payload: null });

  async function asyncPromAll() {
    let data = null;
    let info = null;
    let weather = null;
    let center = { lat: 0, lng: 0 };
    const resultArray = await Promise.all([
      api.get(`property/${propertyId}/information`),
      api.get(`property/${propertyId}/media-data`),
      api.get(`property/weather/${propertyId}/average`),
    ]);
    resultArray.forEach(item => {
      if (item.config.url === `property/${propertyId}/information`) info = item.data;
      if (item.config.url === `property/${propertyId}/media-data`) {
        data = item.data;
        center = { lat: item.data.latitude, lng: item.data.longitude };
      }
      if (item.config.url === `property/weather/${propertyId}/average`) {
        const temp = item.data.month.map((month, index) => ({
          id: item.data.weather_id[index],
          month,
          temperature: item.data.average_temperature[index],
        }));
        weather = { temp, address: item.data.address };
      }
    });
    dispatch({
      type: SET_MATCHES_CURRENT_DATA.SUCCESS,
      payload: { data, info, weather, center },
    });
  }
  asyncPromAll();
};
