const selectReducers = (initialState = { index: 0 }, action) => {
  switch (action.type) {
    case 'TAB_TOGGLE':
      return {
        ...initialState,
        index: action.payload,
      };
    default:
      return initialState;
  }
};

export default selectReducers;
