// eslint-disable-next-line import/named
import { asyncType } from './auth';

export const FETCH_SOCIAL_REDIRECT = asyncType('FETCH_SOCIAL_REDIRECT');
export const CLEAR_REDIRECT_ERRORS = 'CLEAR_REDIRECT_ERRORS';
export const SET_SOCIAL_PARAMS = 'SET_SOCIAL_PARAMS';
export const LOGOUT_SOCIAL_USER = 'LOGOUT_SOCIAL_USER';
export const SOCIAL_AUNTENTICATION = asyncType('SOCIAL_AUNTENTICATION');
export const GET_USER_DETAILS = asyncType('GET_USER_DETAILS');
export const UPDATE_USER_DETAILS = asyncType('UPDATE_USER_DETAILS');
