import * as types from '../types';

export default (
  state = {
    sent: [],
    requested: [],
    shortlisted: [],
    archived: [],
    declined: [],
    pending: [],
    inProgress: [],
    completed: [],
  },
  action,
) => {
  switch (action.type) {
    case types.LISTING_PROPOSALS.SUCCESS:
      return { ...state, sent: action.payload };
    case types.LISTING_PROPOSALS.MOVE_TO_PENDING: {
      const index = state.sent.findIndex(value => {
        return action.payload == value.vendor_property_criteria_id;
      });

      if (index > -1) {
        return {
          ...state,
          sent:
            index > -1
              ? [...state.sent.slice(0, index), ...state.sent.slice(index + 1)]
              : state.sent,
          pending: [
            {
              vendor_property_criteria: {
                ...state.sent[index].vendor_property_criteria,
                marketing_let_price: state.sent[index].marketing_let_price,
                marketing_sale_price: state.sent[index].marketing_sale_price,
                agent_agreement_id: state.sent[index].agent_agreement_id,
              },
              customer: state.sent[index].vendor,
              status_ref: 'VENDOR_ACCEPTED',
            },
            ...state.pending,
          ],
        };
      } else {
        return state;
      }
    }
    case types.LISTING_PROPOSALS_REQUESTED.SUCCESS:
      return { ...state, requested: action.payload };
    case types.LISTING_PROPOSALS_REQUESTED.REMOVE:
      const index = state.requested.findIndex(
        value => action.payload == value.vendor_property_criteria.vendor_property_criteria_id,
      );

      return {
        ...state,
        requested:
          index > -1
            ? [...state.requested.slice(0, index), ...state.requested.slice(index + 1)]
            : state.requested,
      };

    case types.LISTING_PROPOSALS_REQUESTED.ADD_ONE:
      return {
        ...state,
        requested: [
          { vendor_property_criteria: action.payload, vendor: action.payload.customer },
          ...state.requested,
        ],
      };
    case types.LISTING_PROPOSALS_SHORTLISTED.SUCCESS:
      return {
        ...state,
        shortlisted: action.payload,
      };
    case types.LISTING_PROPOSALS_SHORTLISTED.REMOVE: {
      const index = state.shortlisted.findIndex(
        value => action.payload == value.vendor_property_criteria.vendor_property_criteria_id,
      );

      return {
        ...state,
        shortlisted:
          index > -1
            ? [...state.shortlisted.slice(0, index), ...state.shortlisted.slice(index + 1)]
            : state.shortlisted,
      };
    }
    case types.LISTING_PROPOSALS_SHORTLISTED.ADD_ONE:
      return {
        ...state,
        shortlisted: [
          { vendor_property_criteria: action.payload, vendor: action.payload.customer },
          ...state.shortlisted,
        ],
      };

    case types.LISTING_PROPOSALS_ARCHIVED.SUCCESS:
      return {
        ...state,
        archived: action.payload,
      };
    case types.LISTING_PROPOSALS_ARCHIVED.REMOVE: {
      const index = state.archived.findIndex(
        value => action.payload == value.vendor_property_criteria.vendor_property_criteria_id,
      );

      return {
        ...state,
        archived:
          index > -1
            ? [...state.archived.slice(0, index), ...state.archived.slice(index + 1)]
            : state.archived,
      };
    }
    case types.LISTING_PROPOSALS_ARCHIVED.ADD_ONE:
      return {
        ...state,
        archived: [
          { vendor_property_criteria: action.payload, vendor: action.payload.customer },
          ...state.archived,
        ],
      };

    case types.HIRES_IN_PROGRESS.SUCCESS:
      return {
        ...state,
        inProgress: action.payload,
      };

    case types.HIRES_IN_PROGRESS.ADD_ONE:
      return {
        ...state,
        inProgress: [
          {
            vendor_property_criteria: action.payload,
            customer: action.payload.customer,
            status_ref: 'AGENT_AGREED',
          },
          ...state.inProgress,
        ],
      };
    case types.HIRES_IN_PROGRESS.REMOVE: {
      const index = state.inProgress.findIndex(
        value => action.payload == value.vendor_property_criteria.agent_agreement_id,
      );

      return {
        ...state,
        inProgress:
          index > -1
            ? [...state.inProgress.slice(0, index), ...state.inProgress.slice(index + 1)]
            : state.inProgress,
      };
    }

    case types.HIRES_IN_PROGRESS.UPDATE_STATUS: {
      const index = state.inProgress.findIndex(
        value => action.payload == value.vendor_property_criteria.vendor_property_criteria_id,
      );

      if (index > -1) {
        return {
          ...state,
          inProgress: [
            ...state.inProgress.slice(0, index),
            { ...state.inProgress[index], status_ref: 'agreed' },
            ...state.inProgress.slice(index + 1),
          ],
        };
      } else {
        return state;
      }
    }

    case types.HIRES_PENDING.SUCCESS:
      return {
        ...state,
        pending: action.payload,
      };

    case types.HIRES_PENDING.REMOVE: {
      const index = state.pending.findIndex(
        value => action.payload == value.vendor_property_criteria.agent_agreement_id,
      );

      return {
        ...state,
        pending:
          index > -1
            ? [...state.pending.slice(0, index), ...state.pending.slice(index + 1)]
            : state.pending,
      };
    }

    case types.HIRES_COMPLETED.SUCCESS:
      return {
        ...state,
        completed: action.payload,
      };
    case types.HIRES_COMPLETED.ADD_ONE:
      return {
        ...state,
        completed: [
          {
            vendor_property_criteria: action.payload,
            customer: action.payload.customer,
          },
          ...state.completed,
        ],
      };

    case types.LISTING_PROPOSALS_DECLINED.SUCCESS:
      return {
        ...state,
        declined: action.payload,
      };
    default:
      return state;
  }
};
