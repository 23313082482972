import * as types from '../types';

const initialState = {
  data: [],
  error: false,
  pending: false,
  userDetailsUpdated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_DETAILS.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
        userDetailsUpdated: false,
      };
    }
    case types.GET_USER_DETAILS.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: false,
        pending: false,
      };
    }
    case types.GET_USER_DETAILS.ERROR: {
      return {
        ...state,
        data: action.payload,
        error: true,
        pending: false,
      };
    }
    case types.UPDATE_USER_DETAILS.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
      };
    }
    case types.UPDATE_USER_DETAILS.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: false,
        pending: false,
        userDetailsUpdated: true,
      };
    }
    case types.UPDATE_USER_DETAILS.ERROR: {
      return {
        ...state,
        data: action.payload,
        error: true,
        pending: false,
      };
    }
    case types.CLEAR_USER_ERRORS: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};
