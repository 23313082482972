const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const FETCH_MARKETED_PROPERTIES = asyncType('FETCH_MARKETED_PROPERTIES');
export const FETCHED_MARKETED_PROPERTY = asyncType('FETCHED_MARKETED_PROPERTY');
export const UPDATED_MARKETED_PROPERTY = asyncType('UPDATED_MARKETED_PROPERTY');
export const MARK_AS_COMPLETE = asyncType('MARK_AS_COMPLETE');
