export const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const SET_DESTINATION = 'SET_DESTINATION';
export const SET_MAP = 'SET_MAP';
export const SET_MAPS = 'SET_MAPS';
export const CHANGE_DESTINATION = 'CHANGE_DESTINATION';
export const ADD_DESTINATION = 'ADD_DESTINATION';
export const REMOVE_DESTINATION = 'REMOVE_DESTINATION';
export const SET_ROUTINES = 'SET_ROUTINES';
export const SELECT_ADDRESS = 'SELECT_ADDRESS';
