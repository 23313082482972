import * as types from '../types';
import api from '../../../../api';
import { setLoginAuthDetails } from './auth';
import { USER_PROFILE } from '../../../general/redux/types';

export const isUserVerified = (
  token,
  setSnackbar = null,
  isAccessToken = false,
) => async dispatch => {
  try {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    const token_response = !isAccessToken
      ? await api.post(`auth/refresh`)
      : {
          data: {
            access_token: token,
          },
        };

    const { access_token } = token_response.data;
    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;

    const response = await api.get(`me`);
    const payload = {
      isVerified: response.data.data.isEmailVerified,
      isVetted: response.data.data.is_vetted,
      isAgent: response.data.data.role.includes('advertisers'),
      ...response.data.data,
    };

    setSnackbar && setSnackbar(!payload.isVetted);
    dispatch({ type: types.IS_VERIFIED, payload });
    dispatch({ type: USER_PROFILE, payload: response.data.data });
  } catch (error) {
    console.log(error);
  }
};

export const signUpUser = (userData, marketing, url) => async dispatch => {
  try {
    dispatch({ type: types.REGISTERING_USER.PENDING, userData });

    const response = await api.post(`users`, userData);
    const { data } = response;
    const payload = {
      auth: {
        ...data,
        userData,
      },
    };

    api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;

    const notification_payload = {
      notification_preference: {
        top_matches: 1,
        flexible_matches: 1,
        engagement: 1,
        updates_alerts: 1,
        marketing: !marketing ? 1 : 0,
        unsubscribe: 0,
      },
    };
    const notification_response = await api.post(`customer/col/criteria`, notification_payload);
    dispatch({ type: types.REGISTERING_USER.SUCCESS, payload });
    dispatch(sendCustomerAgreement(url, data.access_token));
    dispatch(setLoginAuthDetails(data));
  } catch (error) {
    dispatch({
      type: types.REGISTERING_USER.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message:
          (error.response && error.response.data && error.response.data.message) || error.message,
      },
    });
  }
};

export const getCustomerAgreement = () => async dispatch => {
  try {
    const response = await api.get(`/customer/current/terms`);
    dispatch({ type: types.GET_CUSTOMER_AGREEMENT, payload: response.data.data.document });
  } catch (error) {
    console.log(error);
  }
};

const sendCustomerAgreement = (url, token) => async dispatch => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  const response = await api.post(`/customer/accept_terms`, { document_url: url });
};

export const signUpAgent = (userData, allInfo, image_data, url) => async dispatch => {
  try {
    dispatch({ type: types.REGISTERING_AGENT.PENDING, userData });

    const response = await api.post(`users/agent`, userData);
    const { data } = response;
    const payload = {
      auth: {
        ...data,
        userData,
      },
    };

    dispatch({ type: types.REGISTERING_AGENT.SUCCESS, payload });
    dispatch(setLoginAuthDetails(data));
    dispatch(sendAgreement(url, data.access_token));
    dispatch(sendAllData(allInfo, image_data, data.access_token));
  } catch (error) {
    const payload = {
      status: error.response && error.response.status ? error.response.status : error,
      error,
      message:
        (error.response && error.response.data && error.response.data.message) || error.message,
    };
    dispatch({
      type: types.REGISTERING_AGENT.ERROR,
      payload,
    });
  }
};

const sendAgreement = (url, token) => async dispatch => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  const response = await api.post(`/seller/portal/agent/accept_terms`, { document_url: url });
};

export const sendAllData = (all_info, image_data, token) => async dispatch => {
  try {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    const response = await api.post(`/seller/portal/agent/info/all_info`, all_info);
    const { data } = response;

    dispatch(postAgentImage(image_data, token));
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: types.REGISTERING_AGENT.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message:
          (error.response && error.response.data && error.response.data.message) ||
          error.response.statusText,
      },
    });
  }
};

export const postAgentImage = (image_data, token) => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('image', image_data);

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    const response = await api.post(`seller/portal/agent/profile_image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(isUserVerified(token));
  } catch (error) {
    dispatch({
      type: types.REGISTERING_AGENT.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message: 'Image not Saved',
      },
    });
  }
};

export const checkSecurityCode = checkDetails => async dispatch => {
  try {
    dispatch({ type: types.CHECK_SECURITY_CODE.PENDING, checkDetails });

    const requestDetails = {
      code: checkDetails.code,
    };

    const response = await api.post(`verification/email`, requestDetails, {
      headers: {
        Authorization: `Bearer ${checkDetails.token}`,
      },
    });
    const { data } = response;
    const payload = {
      ...data,
    };

    dispatch({ type: types.CHECK_SECURITY_CODE.SUCCESS, payload });
    // TODO: Check the right data is being passed
    if (Object.keys(checkDetails.signUpData).length) {
      dispatch(isUserVerified(checkDetails.signUpData.auth.refresh_token));
    }
  } catch (error) {
    dispatch({
      type: types.CHECK_SECURITY_CODE.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message:
          (error.response && error.response.data && error.response.data.message) || error.message,
      },
    });
  }
};

export const resendSecurityCode = checkDetails => async dispatch => {
  try {
    dispatch({ type: types.RESEND_SECURITY_CODE.PENDING, checkDetails });

    const response = await api.post(`verification/email/resend`, {
      headers: {
        Authorization: `Bearer ${checkDetails.token}`,
      },
    });
    const { data } = response;
    const payload = {
      ...data,
    };

    dispatch({ type: types.RESEND_SECURITY_CODE.SUCCESS, payload });
  } catch (error) {
    dispatch({
      type: types.RESEND_SECURITY_CODE.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message:
          (error.response && error.response.data && error.response.data.message) || error.message,
      },
    });
  }
};

export const clearSignUpErrors = () => dispatch => {
  dispatch({ type: types.CLEAR_SIGNUP_ERRORS });
};

export default signUpUser;
