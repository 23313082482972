import api from '../../../../api';
import { QuestionDb } from '../../../../utils';
import { poiMarkersColors } from '../../../../utils';
import { PoiPlaceCache } from '../../../../utils/poiPlacesDb';
import { GET_POI_MARKERS } from '../types';

export const getPoisMarkers = (poiName, poiKey, prevPlaces, action) => async dispatch => {
  try {
    if (action === 'UPDATE_STATE') {
      dispatch({ type: GET_POI_MARKERS.SUCCESS, payload: prevPlaces });
    } else {
      // dispatch({type: GET_POI_MARKERS.PENDING});
      const questionDb = new QuestionDb();
      let coordinates = questionDb.get('centerCoordinates');
      coordinates = `coordinate:${coordinates[0]},${coordinates[1]}`; // coordinate:lng/lat
      const areaOfPolygon = questionDb.get('areaOfPolygon');
      const radius = questionDb.get('radius') ?? 0;
      const wholeRadius =
        areaOfPolygon === undefined ? Number(radius) : Number(areaOfPolygon) + Number(radius);

      const url = `google/places/${poiName}/${coordinates}/${(wholeRadius + 2) * 1609}`;
      const { data } = await api.get(url);
      if (data.status === 'success' && data.places.length > 0) {
        // remove previous
        let markers = prevPlaces.filter(places => places.poiKey !== poiKey);
        // cache result
        await new PoiPlaceCache().put(poiKey, poiName, data.places);
        // update
        markers.push({
          poiName,
          poiKey,
          places: data.places,
          color: poiMarkersColors[Number(poiKey)],
        });
        dispatch({ type: GET_POI_MARKERS.SUCCESS, payload: markers });
      } else {
        dispatch({ type: GET_POI_MARKERS.ERROR });
      }
    }
  } catch (error) {
    console.log(error, 'error');
    dispatch({ type: GET_POI_MARKERS.ERROR });
  }
};
