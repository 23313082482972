import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = props => {
  const { children, path } = props;

  const { isAuthenticated } = useSelector(state => state.auth.auth);
  const { isSocialAuthenticated } = useSelector(state => state.auth);

  return (
    <>
      {isAuthenticated || isSocialAuthenticated ? (
        <Route path={path}>{children}</Route>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default ProtectedRoute;
