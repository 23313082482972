import { GET_VPCs, UPLOAD_AGENT_PROPERTY } from '../types';

export const addAgentPropertyReducer = (initialState = { vpcList: [] }, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_AGENT_PROPERTY.PENDING: {
      return {
        ...initialState,
        error: false,
        pending: true,
      };
    }
    case UPLOAD_AGENT_PROPERTY.SUCCESS: {
      return {
        ...initialState,
        newProperty: payload,
        error: false,
        pending: false,
        message: 'Property Added Successfully',
      };
    }
    case UPLOAD_AGENT_PROPERTY.ERROR: {
      return {
        ...initialState,
        newProperty: payload,
        error: true,
        pending: false,
        message: 'Unable to add new property',
      };
    }
    case GET_VPCs: {
      return {
        ...initialState,
        vpcList: payload,
      };
    }
    default:
      return initialState;
  }
};
