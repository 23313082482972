import showAgentProfile from './show_agent_profile';
import { combineReducers } from 'redux';
import { addAgentPropertyReducer } from './addNew';
import { pendingMarketedReducer } from './pending';
import { completedPropertyReducer } from './completed';
import { marketedPropertiesReducer } from './properties';

// export { showAgentProfile };
export default combineReducers({
  addNew: addAgentPropertyReducer,
  pending: pendingMarketedReducer,
  completed: completedPropertyReducer,
  marketed: marketedPropertiesReducer,
});
