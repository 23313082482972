/* eslint-disable import/no-cycle */
import * as types from '../types';
import api from '../../../../api';
import { isUserVerified } from './signUp';

export const authenticateUser = userData => async dispatch => {
  try {
    dispatch({ type: types.IS_AUTHENTICATING.PENDING, userData });
    const reqBody = {
      username: userData.email,
      password: userData.password,
    };

    const response = await api.post(`auth`, reqBody);
    const { data } = response;
    const payload = {
      auth: data,
    };

    dispatch({ type: types.IS_AUTHENTICATING.SUCCESS, payload });
    dispatch({ type: types.IS_AUTHENTICATED, payload });
    dispatch(isUserVerified(data.refresh_token));
  } catch (error) {
    dispatch({
      type: types.IS_AUTHENTICATING.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        message: error.message,
      },
    });
  }
};

export const authenticateAgent = userData => async dispatch => {
  try {
    dispatch({ type: types.IS_AUTHENTICATING.PENDING, userData });
    const reqBody = {
      username: userData.email,
      password: userData.password,
    };

    const response = await api.post(`auth/agent`, reqBody);
    const { data } = response;
    const payload = {
      auth: data,
    };

    dispatch({ type: types.IS_AUTHENTICATING.SUCCESS, payload });
    dispatch({ type: types.IS_AUTHENTICATED, payload });
    dispatch(isUserVerified(data.refresh_token));
    // window.location.href = '/agent-signup';
  } catch (error) {
    dispatch({
      type: types.IS_AUTHENTICATING.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        message: error.message,
      },
    });
  }
};

export const showLoginForm = bool => dispatch => {
  dispatch({ type: 'SHOW_LOGIN', payload: bool });
};

export const showSignUpForm = bool => dispatch => {
  dispatch({ type: 'SHOW_SIGNUP', payload: bool });
};

export const setAction = bool => dispatch => {
  dispatch({ type: 'SET_ACTION', payload: bool });
};

export const setLoginAuthDetails = userData => dispatch => {
  dispatch(isUserVerified(userData.refresh_token));
  dispatch({ type: types.IS_AUTHENTICATED, payload: { auth: userData } });
};

export const logoutUser = () => dispatch => {
  dispatch({ type: types.LOG_OUT });
  setTimeout(() => {
    window.location.href = '/';
  }, 50);
};

export const clearBackendErrors = () => dispatch => {
  dispatch({ type: types.CLEAR_ERRORS });
};

export default authenticateUser;
