/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as types from '../types';

const initialState = {
  summary: {
    data: {},
    error: false,
    pending: false,
  },
  info: { data: {}, error: false, pending: false },
  weather: { data: {}, error: false, pending: false },
};

export default produce((draft, action) => {
  switch (action.type) {
    case types.GET_PROPERTY_INFO.PENDING:
      draft.info.data = {};
      draft.info.error = false;
      draft.info.pending = true;
      break;

    case types.GET_PROPERTY_INFO.SUCESS:
      draft.info.data = action.payload;
      draft.info.error = false;
      draft.info.pending = false;
      break;

    case types.GET_PROPERTY_INFO.ERROR:
      draft.info.data = {};
      draft.info.error = true;
      draft.info.pending = false;
      break;

    case types.GET_PROPERTY_SUMMARY.PENDING:
      draft.summary.data = {};
      draft.summary.error = false;
      draft.summary.pending = true;
      break;

    case types.GET_PROPERTY_SUMMARY.SUCESS:
      draft.summary.data = action.payload;
      draft.summary.error = false;
      draft.summary.pending = false;
      break;

    case types.GET_PROPERTY_SUMMARY.ERROR:
      draft.summary.data = {};
      draft.summary.error = true;
      draft.summary.pending = false;
      break;

    case types.GET_PROPERTY_WEATHER.PENDING:
      draft.weather.data = {};
      draft.weather.error = false;
      draft.weather.pending = true;
      break;

    case types.GET_PROPERTY_WEATHER.SUCESS:
      draft.weather.data = action.payload;
      draft.weather.error = false;
      draft.weather.pending = false;
      break;

    case types.GET_PROPERTY_WEATHER.ERROR:
      draft.weather.data = {};
      draft.weather.error = true;
      draft.weather.pending = false;
      break;

    default:
      break;
  }
}, initialState);
