const pois = (
  defaultState = [
    {
      location: '',
      method: 'drive',
      reachable_in: '5mins',
      routine: false,
      key: '1',
    },
  ],
  action,
) => {
  if (action.type === 'ADD_POI') {
    return [...action.payload];
  }
  return defaultState;
};

export default pois;
