import { GET_JOBLISTING, POST_JOB_APPLICATION } from '../types';

export const jobListingReducer = (
  initialState = {
    jobs: [],
    options: [],
    isLoading: false,
    departments: {},
    departmentCount: 0,
    locations: [],
  },
  action,
) => {
  switch (action.type) {
    case GET_JOBLISTING.SUCCESS: {
      return {
        locations: action.payload.locations,
        jobs: action.payload.list,
        options: action.payload.options,
        departments: action.payload.departments,
        isLoading: false,
        departmentCount: action.payload.departmentCount,
      };
    }
    case GET_JOBLISTING.PENDING: {
      return {
        ...initialState,
        isLoading: action.payload,
      };
    }
    default:
      return initialState;
  }
};

export const postApplicationReducer = (
  initialState = { feedbackMessage: '', sending: false },
  action,
) => {
  switch (action.type) {
    case POST_JOB_APPLICATION.PENDING: {
      return {
        ...initialState,
        sending: action.payload,
        feedbackMessage: 'Sending Your Message...',
      };
    }
    case POST_JOB_APPLICATION.SUCCESS: {
      return {
        ...initialState,
        sending: action.payload.loading,
        feedbackMessage: action.payload.response,
      };
    }
    case POST_JOB_APPLICATION.ERROR: {
      return {
        ...initialState,
        sending: action.payload.loading,
        feedbackMessage: action.payload.response,
      };
    }
    default:
      return initialState;
  }
};
