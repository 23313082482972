import * as types from '../types';

export const webSocket = (state = {}, action) => {
  switch (action.type) {
    case types.INITIALISE_MAIN_WEBSOCKET:
      return action.payload;
    default:
      return state;
  }
};
