import * as types from '../types';
import api from '../../../../api';

export const getSocialRedirectLink = mode => async dispatch => {
  try {
    dispatch({ type: types.FETCH_SOCIAL_REDIRECT.PENDING, mode });

    const response = await api.get(`/auth/${mode}`);
    const { data } = response;
    const payload = {
      auth: data,
    };

    dispatch({ type: types.FETCH_SOCIAL_REDIRECT.SUCCESS, payload });
  } catch (error) {
    dispatch({
      type: types.FETCH_SOCIAL_REDIRECT.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        message: error.message,
      },
    });
  }
};

export const clearSocialBackendErrors = () => dispatch => {
  dispatch({ type: types.CLEAR_REDIRECT_ERRORS });
};

export const socialAuthenticate = (params, mode) => async dispatch => {
  try {
    dispatch({ type: types.SOCIAL_AUNTENTICATION.PENDING });

    const response = await api.get(
      `/auth/authorise/${mode}`,
      { params },
      { withCredentials: true },
    );

    const { data, status } = response;
    const payload = {
      auth: data,
    };

    dispatch({
      type: types.SOCIAL_AUNTENTICATION.SUCCESS,
      payload,
      isSocialAccountCreated: status === 201,
    });
  } catch (error) {
    dispatch({
      type: types.SOCIAL_AUNTENTICATION.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message:
          (error.response && error.response.data && error.response.data.message) || error.message,
      },
    });
  }
};

export const logoutSocialUser = () => dispatch => {
  dispatch({ type: types.LOGOUT_SOCIAL_USER });
};

export default getSocialRedirectLink;
