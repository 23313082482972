/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import {
  checkSecurityCode,
  resendSecurityCode,
  signUpAgent,
  signUpUser,
  googleSignUp,
} from './signup.actions';

const initialState = {
  data: {},
  userData: {},
  error: null,
  pending: false,
  isAccountCreated: false,
  check: {
    data: [],
    error: false,
    pending: false,
    isAccountVerified: false,
    isVetted: false,
    isAgent: false,
    image: null,
  },
  resend: {
    data: [],
    error: false,
    pending: false,
    isCodeResent: false,
  },
};
const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setIsVerified(state, action) {
      state.check = {
        ...state.check,
        isAccountVerified: action.payload.isVerified,
        isAgent: action.payload.isAgent,
        isVetted: action.payload.isVetted,
        ...action.payload,
      };
    },
    clearSignUpErrors(state) {
      state.error = false;
    },
    setCustomerAgreement(state, action) {
      state.agreement_url = action.payload;
    },
    setLogout() {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(signUpUser.pending, state => {
        state.error = false;
        state.pending = true;
        state.check = {
          data: [],
          error: false,
          pending: false,
          isAccountVerified: false,
        };
        state.resend = {
          data: [],
          error: null,
          pending: false,
          isCodeResent: false,
        };
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.data = action.payload;
        state.isAccountCreated = true;
        state.userData = action.payload;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.pending = false;
        state.error = {
          message:
            action.error.message === 'Request failed with status code 409'
              ? 'User with email address already exists'
              : action.error.message,
        };
      })
      .addCase(googleSignUp.pending, state => {
        state.error = false;
        state.pending = true;
        state.check = {
          data: [],
          error: false,
          pending: false,
          isAccountVerified: false,
        };
        state.resend = {
          data: [],
          error: null,
          pending: false,
          isCodeResent: false,
        };
      })
      .addCase(googleSignUp.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.data = action.payload;
        state.isAccountCreated = true;
        state.userData = action.payload;
      })
      .addCase(googleSignUp.rejected, (state, action) => {
        state.pending = false;
        state.error = {
          message:
            action.error.message === 'Request failed with status code 409'
              ? 'User with email address already exists'
              : action.error.message,
        };
      })
      .addCase(signUpAgent.pending, state => {
        state.error = null;
        state.pending = true;
        state.check = {
          data: [],
          error: false,
          pending: false,
          isAccountVerified: false,
        };
        state.resend = {
          data: [],
          error: false,
          pending: false,
          isCodeResent: false,
        };
      })
      .addCase(signUpAgent.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.data = action.payload;
        state.isAccountCreated = true;
        state.userData = action.payload;
      })
      .addCase(signUpAgent.rejected, (state, action) => {
        state.pending = false;
        state.error = { message: action.error.message };
      })
      .addCase(checkSecurityCode.pending, state => {
        state.check = {
          ...state.check,
          error: false,
          pending: true,
        };
      })
      .addCase(checkSecurityCode.fulfilled, (state, action) => {
        state.data = [];
        state.check = {
          ...state.check,
          data: action.payload,
          error: false,
          pending: false,
          isAccountVerified: true,
        };
      })
      .addCase(checkSecurityCode.rejected, (state, action) => {
        state.check = {
          ...state.check,
          data: action.payload,
          error: { message: action.error.message },
          pending: false,
        };
      })
      .addCase(resendSecurityCode.pending, state => {
        state.resend = {
          ...state.resend,
          error: false,
          pending: true,
        };
      })
      .addCase(resendSecurityCode.fulfilled, (state, action) => {
        state.resend = {
          ...state.resend,
          data: action.payload,
          error: false,
          pending: false,

          isCodeResent: true,
        };
      })
      .addCase(resendSecurityCode.rejected, (state, action) => {
        state.resend = {
          ...state.resend,
          data: action.payload,
          error: { message: action.error.message },
          pending: false,
        };
      });
  },
});

export const {
  setIsVerified,
  clearSignUpErrors,
  setCustomerAgreement,
  setLogout,
} = signupSlice.actions;

export default signupSlice.reducer;

// export default (state = initialState, action) => {
//     case types.GET_CUSTOMER_AGREEMENT: {
//       return {
//         ...state,
//         agreement_url: action.payload,
//       };
//     }
//     case types.LOG_OUT: {
//       return {
//         ...initialState,
//       };
//     }
//     default: {
//       return state;
//     }
//   }
// };
