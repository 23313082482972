import * as types from '../types';

const initialState = {
  data: [],
  error: false,
  pending: false,
  action: false,
  showLogin: false,
  showSignUp: false,
  isAuthenticated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.IS_AUTHENTICATING.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
      };
    }
    case types.IS_AUTHENTICATING.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: false,
        pending: false,
      };
    }
    case types.IS_AUTHENTICATING.ERROR: {
      return {
        ...state,
        data: action.payload,
        error: true,
        pending: false,
      };
    }
    case types.IS_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: true,
        data: action.payload,
      };
    }
    case types.LOG_OUT: {
      return {
        ...initialState,
      };
    }
    case types.CLEAR_ERRORS: {
      return {
        ...state,
        // data: [],
        error: false,
      };
    }
    case 'SHOW_LOGIN': {
      return {
        ...state,
        showLogin: action.payload,
      };
    }
    case 'SHOW_SIGNUP': {
      return {
        ...state,
        showSignUp: action.payload,
      };
    }
    case 'SET_ACTION': {
      return {
        ...state,
        action: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
