import _ from 'lodash';
import api from '../../../../api';

import * as types from '../types';
import { getRoom } from './room';

export const getMessages = (
  token,
  roomId,
  rooms,
  isAgent = false,
  page = 1,
  previousMessages = [],
  // update
) => async dispatch => {
  try {
    dispatch({ type: types.FETCHINGMESSAGES.PENDING });

    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    const response = await api.get(
      `chat/${isAgent ? 'agent' : 'customer'}_room_messages/${roomId}?page=${page}&limit=20`,
    );

    const { data } = response;

    const payload = {
      messages: [...data.data, ...previousMessages],
      page,
      end: data.data.length < 20,
    };
    dispatch({ type: types.FETCHINGMESSAGES.SUCCESS, payload });
    const roomIndex = rooms.findIndex(singleRoom => singleRoom.room_id === parseInt(roomId));
    const unread = rooms[roomIndex].unread_messages;
    // rooms[roomIndex].unread_messages = 0;
    // dispatch({type: types.NUMBEROFUNREADMESSAGES.UPDATE, payload: -unread})
  } catch (error) {
    dispatch({
      type: types.FETCHINGMESSAGES.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        message: error.message,
      },
    });
  }
};
