/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import GlobalStyle from './theme/globalStyles';
import './assets/css/index.css';
import App from './App';
import PageLoader from './components/Spinner/PageLoader';
import { store, persistor } from './redux';
import * as serviceWorker from './services/serviceWorker';
import { AuthProvider } from './modules/authV2/components/AuthProvider/AuthProvider';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<PageLoader />} persistor={persistor}>
      <SnackbarProvider maxSnack={3} preventDuplicate dense={false} hideIconVariant={false}>
        <GlobalStyle />
        <AuthProvider>
          <App />
        </AuthProvider>
      </SnackbarProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
