/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit';
import searchReducer from './search/search.slice';
import mapReducer from './map/map.slice';
import routineReducer from './routine/routine.slice';
import schoolReducer from './school/school.slice';
import poiReducer from './poi/poi.slice';

export default combineReducers({
  search: searchReducer,
  map: mapReducer,
  routine: routineReducer,
  school: schoolReducer,
  poi: poiReducer,
});
