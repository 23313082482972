export const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCESS: `${type}_SUCESS`,
});

export const GET_PROPERTIES = asyncType('GET_PROPERTIES');

export const GET_PROPERTY_INFO = asyncType('GET_PROPERTY_INFO');

export const GET_PROPERTY_SUMMARY = asyncType('GET_PROPERTY_SUMMARY');

export const GET_PROPERTY_WEATHER = asyncType('GET_PROPERTY_WEATHER');

export const GET_NEAR_BY_SOLD_PROPERTIES = asyncType('GET_NEAR_BY_SOLD_PROPERTIES');

export const GET_PRICE_HISTORY = asyncType('GET_PRICE_HISTORY');

export const GET_AVERAGE_SALE_HISTORY = asyncType('GET_AVERAGE_SALE_HISTORY');

export const GET_COST_OF_LIVING = asyncType('GET_COST_OF_LIVING');

export const FILTER_PROPERTIES = asyncType('FILTER_PROPERTIES');

export const GEO_DATA_LOADED = 'GEO_DATA_LOADED';

export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER';

export const ADD_SEARCH_FILTERS = 'ADD_SEARCH_FILTERS';

export const GET_QUESTION_REFERENCES = asyncType('GET_QUESTION_REFERENCES');
