import { idb } from './indexdb';

class PoiPlaceCache {
  constructor() {
    this.dbName = 'PoiPlace';
    this.version = 1;
    this.storeName = 'poiPlaces';
    this.indexName = 'poiKeyIndex';
  }

  async initilize() {
    const db = await idb(this.dbName, this.version);
    const transaction = db.transaction(this.storeName, 'readwrite');

    return { transaction, db };
  }

  async put(poiKey, poiName, places) {
    // does the same thing as add and update
    const init = await this.initilize();
    const r = await init.transaction
      .objectStore(this.storeName)
      .put({ poiKey, poiName, places }, poiKey);
    console.log(r);
    return true;
  }

  async get(poiKey) {
    const init = await this.initilize();
    const poiIndex = await init.transaction.store.index(this.indexName);
    const poiplaces = poiIndex.get(poiKey);

    return poiplaces;
  }

  async delete(poiKey) {
    const init = await this.initilize();
    const r = await init.transaction.objectStore(this.storeName).delete(poiKey);

    return true;
  }
}

export { PoiPlaceCache };
