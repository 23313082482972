import isObject from './is_object';

function cloneObject(obj) {
  if (Array.isArray(obj) || isObject(obj)) {
    return JSON.parse(JSON.stringify(obj));
  }

  throw 'Invalid object passed';
}

export default cloneObject;
