import { openDB } from 'idb';

export const idb = (dbName, version = 1) => {
  return openDB(dbName, version, {
    upgrade: (db, oldVersion, newVersion, transaction) => {
      switch (oldVersion) {
        case 0:
          fromV0toV1();
        // falls through
        default:
          console.log('unknown database version');
      }

      function fromV0toV1() {
        const poiStore = db.createObjectStore('poiPlaces');
        poiStore.createIndex('poiKeyIndex', 'poiKey');
      }
    },
  });
};
