/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api';
import setAuthHeader from '../../../../utils/set_auth_headers';
import {
  setNotificationUpdate,
  savingPreferences,
  setSavingPreferencesComplete,
} from './profile.slice';
import { isUserVerified } from '../../../auth/redux/actions';

export const updateNotificationPreferences = (data, token) => async dispatch => {
  try {
    dispatch(savingPreferences(true));
    const tokenResponse = await api.post(`auth/refresh`, undefined, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { access_token: accessToken } = tokenResponse.data;
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const response = await api.put(`/me/`, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (response.status === 200 || response.status === 201) {
      dispatch(setNotificationUpdate(response.data.data));
      dispatch(setSavingPreferencesComplete(true));
      dispatch(savingPreferences(false));
      dispatch(isUserVerified(accessToken, null, true));
    }
  } catch (error) {
    console.log(error);
    dispatch(savingPreferences(false));
  }
};

export const updateCustomerProfile = createAsyncThunk(
  'profile/update_customer_profile',
  async data => {
    await setAuthHeader();
    try {
      const response = await api.put(`me`, data);
      console.log(response);
      return 'Profile Updated Successfully';
    } catch (error) {
      throw new Error(error.message ?? 'Error Updating Profile');
    }
  },
);

export const deleteUserAccount = () => async () => {
  await setAuthHeader();
  try {
    await api.delete(`me`);
  } catch (error) {
    console.log(error);
  }
};
