import api from '../../../../api';
import * as types from '../types';
import { getMessages } from './messages';

export const createRoom = (token, rooms, propertyId, agentId) => async dispatch => {
  try {
    dispatch({ type: types.CREATINGROOM.PENDING });

    const reqBody = {
      propertyId,
      agentId,
    };

    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    const response = await api.post(`chat/rooms`, reqBody);
    const { data } = response.data;
    const payload = data;

    dispatch({ type: types.CREATINGROOM.SUCCESS, payload });
    const roomPayLoad = [data, ...rooms];
    if (!response.data.message.includes('exist')) {
      dispatch({ type: types.FETCHINGALLROOMS.SUCCESS, payload: roomPayLoad });
    }
  } catch (error) {
    dispatch({
      type: types.CREATINGROOM.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        message: error.message,
      },
    });
  }
};

export const setChatRoomPrompts = prompts => async dispatch => {
  dispatch({
    type: types.GET_CHAT_ROOM_PROMPTS.SUCCESS,
    payload: prompts,
  });
};

export const getChatRoomPrompts = ({ roomId, refreshToken }) => async dispatch => {
  try {
    const tokenResponse = await api.post(`auth/refresh`, undefined, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    const { access_token: accessToken } = tokenResponse.data;
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const res = await api.get(`/chats/chatrooms/${roomId}/prompts`);

    dispatch({
      type: types.GET_CHAT_ROOM_PROMPTS.SUCCESS,
      payload: res.data.prompts,
    });
  } catch (error) {
    dispatch({
      type: types.GET_CHAT_ROOM_PROMPTS.ERROR,
      payload: error.response?.data || 'Something went wrong',
    });
  }
};

export const deleteChatRoom = ({ roomId, rooms, refreshToken, history }) => async dispatch => {
  dispatch({
    type: types.DELETE_CHAT_ROOM.PENDING,
  });

  try {
    const tokenResponse = await api.post(`auth/refresh`, undefined, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    const { access_token: accessToken } = tokenResponse.data;
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const res = await api.delete(`/chats/chatroom/${roomId}`);

    dispatch({
      type: types.DELETE_CHAT_ROOM.SUCCESS,
      payload: { ...res.data, roomId },
    });

    if (rooms?.length) {
      dispatch({
        type: types.FETCHINGALLROOMS.SUCCESS,
        payload: rooms.filter(it => it.room_id !== roomId),
      });
    }

    history.push('/search');
  } catch (error) {
    dispatch({
      type: types.DELETE_CHAT_ROOM.ERROR,
      payload: error.response?.data || 'Something went wrong',
    });
  }
};

export const getRoom = (token, roomId, isAgent, rooms, id) => async dispatch => {
  const tokenResponse = await api.post(`auth/refresh`, undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { access_token: accessToken } = tokenResponse.data;
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  dispatch({
    type: types.FETCHINGMESSAGES.PENDING,
    payload: { messages: [], page: 0, end: false },
  });
  dispatch({ type: types.SENDMESSAGE, payload: [] });

  dispatch({
    type: types.FETCHINGROOM.PENDING,
    payload: {
      users: [],
      properties: { property_info: [], vendor_property_criteria_info: [] },
      files: [],
      typingData: [],
    },
  });

  const payload = { typingData: [], roomId };
  await api
    .get(`chat/get_chat_users/${roomId}`)
    .then(async response => {
      payload.users = response.data.data;

      return api.get(`chat/get_chat_properties/${roomId}`);
    })
    .then(async response => {
      payload.properties = response.data.data;

      return api.get(`chat/get_links/${roomId}`);
    })
    .then(async response => {
      payload.links = response.data.data;

      return api.get(`chat/get_documents/${roomId}`);
    })
    .then(async response => {
      payload.files = response.data.data;
      const camelCaseField = isAgent ? 'customerId' : 'agentId';
      const snakeCaseField = isAgent ? 'customer_id' : 'agent_id';
      if (!id) {
        const roomIndex = rooms.findIndex(singleRoom => singleRoom.room_id === parseInt(roomId));
        payload[camelCaseField] = rooms[roomIndex][snakeCaseField];
      } else {
        payload[camelCaseField] = id;
      }

      dispatch({ type: types.FETCHINGROOM.SUCCESS, payload });
    })
    .catch(error => {
      dispatch({
        type: types.FETCHINGROOM.ERROR,
        payload: {
          status: error.response && error.response.status ? error.response.status : error,
          message: error.message,
        },
      });
    });
  dispatch(getMessages(accessToken, roomId, rooms, isAgent));
};

export const getAllRooms = (
  token,
  rooms,
  urlPath,
  isAgent = false,
  fetchRoom = false,
) => async dispatch => {
  try {
    dispatch({ type: types.FETCHINGALLROOMS.PENDING });

    const tokenResponse = await api.post(`auth/refresh`, undefined, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { access_token: accessToken } = tokenResponse.data;
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const url = urlPath || `chat/${isAgent ? 'agent' : 'customer'}_rooms`;

    const response = await api.get(url);
    const { data } = response;
    const payload = data.data;
    const unreadMessages = payload.reduce((total, room) => total + room.unread_messages, 0);
    dispatch({ type: types.NUMBEROFUNREADMESSAGES.REPLACE, payload: unreadMessages });
    dispatch({ type: types.FETCHINGALLROOMS.SUCCESS, payload });

    if (fetchRoom && payload[0]) {
      const { room_id, customer_id, agent_id } = payload[0];
      const id = isAgent ? customer_id : agent_id;

      dispatch(getRoom(token, room_id, isAgent, rooms, id));
    }
  } catch (error) {
    dispatch({
      type: types.FETCHINGALLROOMS.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        message: error.message,
      },
    });
  }
};

export const sendSeen = (token, roomId, rooms) => dispatch => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  api.post(`chat/update_seen_timestamp`, { roomId }).then(res => {
    const roomIndex = rooms.findIndex(singleRoom => singleRoom.room_id === parseInt(roomId));
    if (roomIndex > -1 && rooms[roomIndex].unread_messages) {
      const unread = rooms[roomIndex].unread_messages;
      const payload = [
        ...rooms.slice(0, roomIndex),
        { ...rooms[roomIndex], unread_messages: 0 },
        ...rooms.slice(roomIndex + 1),
      ];
      rooms[roomIndex].unread_messages = 0;
      dispatch({ type: types.FETCHINGALLROOMS.SUCCESS, payload });
      dispatch({ type: types.NUMBEROFUNREADMESSAGES.UPDATE, payload: -unread });
    }
  });
};
