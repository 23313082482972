import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api';
import setAuthHeader from '../../../../utils/set_auth_headers';

export const getPropertyInformation = createAsyncThunk(
  'property/get_property_information',
  async ({ enqueueSnackbar, propertyId }) => {
    await setAuthHeader();
    try {
      const response = await api.get(
        `/property/${propertyId}/information`,
        // { headers: { Authorization: `Bearer ${cookie.access_token}` } },
      );
      const response2 = await api.get(`/property/${propertyId}/summary`);
      enqueueSnackbar('Property fetched successfully', {
        variant: 'info',
      });
      return { data: { ...response.data, ...response2.data } };
    } catch (error) {
      enqueueSnackbar(`There was an error when shortlisting this property`, {
        variant: 'error',
      });
      throw new Error(error.message);
    }
  },
);

export const getPropertyDetails = createAsyncThunk(
  'property/get_property_details',
  async ({ enqueueSnackbar, propertyId, category }) => {
    // await setAuthHeader();
    try {
      const response = await api.get(`/customer/place/${category}/${propertyId}`);
      enqueueSnackbar &&
        enqueueSnackbar('Property fetched successfully', {
          variant: 'info',
        });
      return { data: { ...response.data } };
    } catch (error) {
      enqueueSnackbar &&
        enqueueSnackbar(`There was an error when fetching this property`, {
          variant: 'error',
        });
      throw new Error(error.message);
    }
  },
);

export const getShortlistDetails = createAsyncThunk(
  'property/get_shortlist_details',
  async ({ category, propertyId }) => {
    await setAuthHeader();
    try {
      const response = await api.get(
        `/customer/current_customer/shortlist/${category}/${propertyId}`,
      );
      return { data: { ...response.data } };
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const getPropertySummary = async ({ propertyId }) => {
  try {
    const res = await api.get(`/property/${propertyId}/summary`);
    return { data: res.data, error: null };
  } catch (error) {
    //
    return { data: null, error: { message: 'Could not fetch' } };
  }
};
