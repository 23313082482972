export const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const RESET_PASSWORD = asyncType('RESET_PASSWORD');

export const CLEAR_PASSWORD_ERRORS = 'CLEAR_PASSWORD_ERRORS';

export const SET_USER_EMAIL = 'SET_USER_EMAIL';

export const SET_USER_PASSWORD = 'SET_USER_PASSWORD';

export const CHANGE_PASSWORD = asyncType('CHANGE_PASSWORD');

export const CLEAR_PASSWORD_DATA = 'CLEAR_PASSWORD_DATA';

export const VALIDATE_SECURITY_CODE = asyncType('VALIDATE_SECURITY_CODE');
