import * as types from '../types';
import api from '../../../../api';

export const resetPassword = ({ email }) => async dispatch => {
  try {
    dispatch({ type: types.RESET_PASSWORD.PENDING });

    const response = await api.post(`forgot-password`, { email });
    const { data } = response;
    const payload = {
      auth: data,
    };

    dispatch({ type: types.RESET_PASSWORD.SUCCESS, payload });
    dispatch({ type: types.SET_USER_EMAIL, payload: { email } });
  } catch (error) {
    dispatch({
      type: types.RESET_PASSWORD.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message:
          (error.response && error.response.data && error.response.data.message) || error.message,
      },
    });
  }
};

export const clearPasswordErrors = () => dispatch => {
  dispatch({ type: types.CLEAR_PASSWORD_ERRORS });
};

export const setUserPassword = password => dispatch => {
  dispatch({ type: types.SET_USER_PASSWORD, payload: { password } });
};

export const clearPasswordData = () => dispatch => {
  dispatch({ type: types.CLEAR_PASSWORD_DATA });
};

export const validateSecurityCode = userData => async dispatch => {
  try {
    dispatch({ type: types.VALIDATE_SECURITY_CODE.PENDING });

    const response = await api.post(`validate`, userData);
    const { data } = response;
    const payload = {
      data,
      userData,
    };

    dispatch({ type: types.VALIDATE_SECURITY_CODE.SUCCESS, payload });
  } catch (error) {
    dispatch({
      type: types.VALIDATE_SECURITY_CODE.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message:
          (error.response && error.response.data && error.response.data.message) || error.message,
      },
    });
  }
};

export const changePassword = userData => async dispatch => {
  try {
    dispatch({ type: types.CHANGE_PASSWORD.PENDING });

    const response = await api.post(`reset-password`, userData);
    const { data } = response;
    const payload = {
      auth: data,
    };

    dispatch({ type: types.CHANGE_PASSWORD.SUCCESS, payload });
  } catch (error) {
    dispatch({
      type: types.CHANGE_PASSWORD.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message:
          (error.response && error.response.data && error.response.data.message) || error.message,
      },
    });
  }
};

export default resetPassword;
