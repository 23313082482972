import { combineReducers } from 'redux';
import { allPropertiesReducer, setPropertyIndex } from './properties';
import { submitProposal } from './proposal';
import { webSocket } from './socket';

export default combineReducers({
  properties: allPropertiesReducer,
  submitProposal,
  mainSocket: webSocket,
  propertyIndexVp: setPropertyIndex,
});
