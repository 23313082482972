import { combineReducers } from 'redux';
import propertiesReducer from './properties';
import informationReducer from './information';
import marketReducer from './market';
import costOfLivingReducer from './costOfLiving';
import filtersReducer from './filters';
import questionsReducer from './questions';
import { poiMarkersReducers } from './poi_markers';
import shortlistReducers from './shortlist';
import routinesReducers from './routines';
import POIReducers from './POI';
import schoolReducers from './school';
import showAuth from './showAuth';

export default combineReducers({
  properties: propertiesReducer,
  propertyInfo: informationReducer,
  market: marketReducer,
  costOfLiving: costOfLivingReducer,
  filters: filtersReducer,
  questions: questionsReducer,
  poiMarkers: poiMarkersReducers,
  shortlist: shortlistReducers,
  routines: routinesReducers,
  school: schoolReducers,
  poi: POIReducers,
  showAuth,
  // ...Add more search related reducers.
});
