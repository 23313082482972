import * as types from '../types';
import { CHATROOMREFERENCE, EXPECTINGROOM, EXPECTINGROOMEMOVE } from '../types/socket';

export const webSocket = (state = {}, action) => {
  switch (action.type) {
    case types.INITIALISEWEBSOCKET:
      return action.payload;
    default:
      return state;
  }
};

export const chatRoomReference = (state = null, action) => {
  return action.type === CHATROOMREFERENCE ? action.payload : state;
};

export const expectingRoom = (state = [], action) => {
  switch (action.type) {
    case EXPECTINGROOM:
      return [...state, action.payload];
    case EXPECTINGROOMEMOVE: {
      const index = state.indexOf(action.payload);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    default:
      return state;
  }
};

export const typing = (state = { typing: false }, action) => {
  switch (action.type) {
    case types.TYPINGSTARTED:
      return {
        ...state,
        ...action.payload,
        typing: true,
      };
    case types.TYPINGSTOPPED:
      return {
        ...state,
        ...action.payload,
        typing: false,
      };
    default:
      return state;
  }
};

// export const typing = (state = { typing: false }, action) => {
//   switch (action.type) {
//     case types.TYPINGSTARTED:
//       return {
//         ...state,
//         ...action.payload,
//         typing: true,
//       };
//     case types.TYPINGSTOPPED:
//       return {
//         ...state,
//         ...action.payload,
//         typing: true,
//       };
//     default:
//       return state;
//   }
// };

export const message = (state = [], action) => {
  switch (action.type) {
    case types.SENDMESSAGE:
      return action.payload;
    default:
      return state;
  }
};
