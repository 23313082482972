export const homeTypeGroups = {
  detached: [
    'detached',
    'link_detached_house',
    'barn_conversion',
    'village_house',
    'country_house',
    'farm_house',
    'stone_house',
    'manor_house',
    'stately_home',
    'castle',
    'chalet',
    'cottage',
    'detached_bungalow',
    'log_cabin',
    'lodge',
    'villa',
    'detached_villa',
    'park_home',
    'mobile_home',
    'cave_house',
    'house_boat',
    'character_property',
  ],
  'semi-detached': [
    'semi-detached',
    'link_detached_house',
    'end_of_terrace',
    'semi-detached_villa',
    'village_house',
    'semi-detached_bungalow',
    'character_property',
  ],
  terraced: [
    'terraced',
    'end_of_terrace',
    'cluster_house',
    'mews_house',
    'village_house',
    'terraced_bungalow',
    'character_property',
  ],
  town_house: ['town_house', 'character_property'],
  bungalow: [
    'bungalow',
    'detached_bungalow',
    'cottage',
    'semi-detached_bungalow',
    'terraced_bungalow',
    'park_home',
    'sheltered_housing',
    'character_property',
  ],
  flat: [
    'flat',
    'apartment',
    'block_of_apartments',
    'mill',
    'maisonette',
    'serviced apartment',
    'ground floor_flat',
    'ground_floor_maisonette',
    'studio_flat',
    'duplex',
    'triplex',
    'penthouse',
    'character_property',
    'sheltered_housing',
  ],
  'private halls': [
    'private halls',
    'apartment',
    'block_of_apartments',
    'serviced apartment',
    'ground floor_flat',
  ],
  land: ['plot', 'land_residential', 'farm_land', 'off-plan', 'parking', 'garages'],
};

export const getHouseTypes = channel => {
  const rentHouseTypes = {
    detached: 1,
    'semi-detached': 2,
    terraced: 3,
    town_house: 4,
    bungalow: 5,
    flat: 6,
    'private halls': 19,
  };
  const buyHouseTypes = {
    detached: 1,
    'semi-detached': 2,
    terraced: 3,
    town_house: 4,
    bungalow: 5,
    flat: 6,
    land: 44,
  };

  return channel === 'buy' ? buyHouseTypes : rentHouseTypes;
};
