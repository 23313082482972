import { isObject, isArray } from 'lodash';
import * as types from '../types';
import api from '../../../../api';

export const setAuthHeader = async data => {
  const refreshToken = data.auth?.refresh_token ?? data.auth?.auth?.refresh_token;
  api.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;
  const request = await api.post(`/auth/refresh`);
  const accessToken = await request.data.access_token;
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const getCustomerCriteria = ({ data }) => async dispatch => {
  dispatch({ type: types.GET_CUSTOMER_CRITERIA.PENDING, payload: null });
  await setAuthHeader(data);
  const customerDetails = await api.get('/me/');
  if (customerDetails.data.data.isEmailVerified === true) {
    await api
      .get(`/customer/col/criteria`)
      .then(response => {
        dispatch({
          type: types.GET_CUSTOMER_CRITERIA.SUCCESS,
          payload: {
            user: customerDetails.data.data,
            pois: response.data.data.pois,
            customerCriteria: response.data.data.customerCriteria,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: types.GET_CUSTOMER_CRITERIA.ERROR,
          payload: { user: {}, pois: [], customerCriteria: false },
        });
      });
  } else {
    dispatch({
      type: types.GET_CUSTOMER_CRITERIA.SUCCESS,
      payload: {
        user: customerDetails.data.data,
        pois: [],
        customerCriteria: null,
      },
    });
  }
};

const profileArray = [
  'location',
  'moveInDate',
  'placeId',
  'radius',
  'careMostAbout',
  'price',
  'channel',
  'propertyForm',
  'propertyType',
  'parking',
  'propertyAmenities',
  'bathrooms',
  'bedrooms',
  'tenure',
  'areaOfPolygon',
  'bbox',
  'geojsonType',
  'centerCoordinates',
  'rentalTerm',
  'furnished',
  'pois',
  'pets',
  'notificationPreference',
];
const sortData = (criteria, type) => {
  const data = new Promise((resolve, reject) => {
    let criteriaData = {};
    const poiItems = criteria.pois ?? null;
    let poiItems2 = [];
    if (poiItems !== null) {
      if (poiItems.length > 0) {
        //
      }
    }

    if (poiItems !== null) {
      if (poiItems.length > 0) {
        if (type === 'post') {
          poiItems.forEach(item => {
            delete item.key;
            delete item.routine;
            delete item.prediction;
            item.country = '';
            item.county = '';
            item.district = '';
            item.latitude = 0;
            item.locality = '';
            item.longitude = 0;
            item.paon = '';
            item.postcode_district = '';
            item.postcode_sector = '';
            item.saon = '';
            item.street = '';
            item.town_city = '';
          });
          poiItems2 = poiItems;
        }
        if (type === 'update') {
          poiItems.forEach(item => {
            const x = {
              location: item?.poi_name,
              reachable_in: `${parseInt(item?.reachable_in, 10)}${item?.reachable_in_unit ?? ''}`,
              method: item?.method_of_transport ?? 'drive',
              country: '',
              county: '',
              district: '',
              latitude: 0,
              locality: '',
              longitude: 0,
              paon: '',
              postcode_district: '',
              postcode_sector: '',
              saon: '',
              street: '',
              town_city: '',
            };
            // if (item.poi_id !== undefined) {
            //   x.key = item.poi_id;
            // }
            if (x.location) {
              poiItems2 = poiItems2.concat(x);
            }
          });
        }
      }
    }
    criteriaData = {
      ...criteriaData,
      radius: criteria.radius || 0,
    };
    profileArray.forEach(item => {
      if (criteria[item]) {
        if (item === 'channel') {
          criteriaData = {
            ...criteriaData,
            channel: criteria.channel === 'buy' ? 'buy' : 'rent',
          };
        }
        if (item === 'location') {
          if (criteria.location.length > 0) {
            criteriaData = {
              ...criteriaData,
              location: criteria.location,
            };
          }
        }
        if (item === 'moveInDate') {
          if (criteria.moveInDate.length > 0) {
            criteriaData = {
              ...criteriaData,
              move_in_date: criteria.moveInDate,
            };
          } else if (isArray(criteria.moveInDate)) {
            criteriaData = {
              ...criteriaData,
              move_in_date: criteria.moveInDate,
            };
          }
        }
        if (item === 'price') {
          if (criteria.price.length > 0) {
            criteriaData = {
              ...criteriaData,
              price: criteria.price,
            };
          }
        }
        if (item === 'propertyForm') {
          if (criteria.propertyForm.length > 0 || isArray(criteria.propertyForm)) {
            criteriaData = {
              ...criteriaData,
              property_form: criteria.propertyForm,
            };
          }
        }
        if (item === 'propertyType') {
          if (criteria.propertyType.length > 0 || isArray(criteria.propertyType)) {
            criteriaData = {
              ...criteriaData,
              property_type: criteria.propertyType,
            };
          }
        }
        if (item === 'propertyAmenities') {
          if (criteria.propertyAmenities.length > 0 || isArray(criteria.propertyAmenities)) {
            criteriaData = {
              ...criteriaData,
              property_amenities: criteria.propertyAmenities,
            };
          }
        }
        if (item === 'bathrooms') {
          if (criteria.bathrooms.length > 0 || isArray(criteria.bathrooms)) {
            criteriaData = {
              ...criteriaData,
              bathrooms: criteria.bathrooms,
            };
          }
        }
        if (item === 'bedrooms') {
          if (criteria.bedrooms.length > 0 || isArray(criteria.bedrooms)) {
            criteriaData = {
              ...criteriaData,
              bedrooms: criteria.bedrooms,
            };
          }
        }
        if (item === 'parking') {
          if (criteria.parking.length > 0 || isArray(criteria.parking)) {
            criteriaData = {
              ...criteriaData,
              parking: criteria.parking,
            };
          }
        }
        if (item === 'rentalTerm') {
          if (criteria.rentalTerm.length > 0) {
            criteriaData = {
              ...criteriaData,
              rental_term: criteria.rentalTerm,
            };
          }
        }
        if (item === 'placeId') {
          if (criteria.placeId.length > 0) {
            criteriaData = {
              ...criteriaData,
              place_id: criteria.placeId,
            };
          }
        }
        if (item === 'tenure') {
          if (criteria.tenure.length > 0) {
            criteriaData = {
              ...criteriaData,
              tenure: criteria.tenure,
            };
          }
        }
        if (item === 'careMostAbout') {
          if (criteria.careMostAbout.length > 0) {
            criteriaData = {
              ...criteriaData,
              care_most_about: criteria.careMostAbout,
            };
          }
        }
        if (item === 'areaOfPolygon') {
          if (criteria.areaOfPolygon.toString().length > 0) {
            criteriaData = {
              ...criteriaData,
              area_of_polygon: parseFloat(criteria.areaOfPolygon),
            };
          }
        }
        if (item === 'bbox') {
          if (isObject(criteria.bbox)) {
            criteriaData = {
              ...criteriaData,
              bbox: criteria.bbox,
            };
          }
        }
        if (item === 'geojsonType') {
          if (criteria.geojsonType.length > 0) {
            criteriaData = {
              ...criteriaData,
              geojson_type: criteria.geojsonType,
            };
          }
        }
        if (item === 'centerCoordinates') {
          if (criteria.centerCoordinates.length > 0) {
            criteriaData = {
              ...criteriaData,
              center_coordinates: criteria.centerCoordinates,
            };
          }
        }
        if (item === 'rentalTerm') {
          if (criteria.rentalTerm.length > 0) {
            criteriaData = {
              ...criteriaData,
              rental_term: criteria.rentalTerm,
            };
          }
        }
        if (item === 'furnished') {
          if (criteria.furnished.length > 0) {
            criteriaData = {
              ...criteriaData,
              furnished: criteria.furnished,
            };
          }
        }
        if (item === 'pets') {
          if (criteria.pets.length > 0) {
            criteriaData = {
              ...criteriaData,
              pets: criteria.pets,
            };
          }
        }
        if (item === 'pois') {
          if (criteria.pois.length > 0) {
            criteriaData = {
              ...criteriaData,
              pois: poiItems2,
            };
          }
        }
      }
    });
    console.log(criteriaData, 'radius');
    resolve({ data: JSON.stringify(criteriaData) });
  });
  return data;
};

export const postCustomerCriteria = ({
  data,
  criteria,
  enqueueSnackbar,
  history,
}) => async dispatch => {
  let criteriaData = {};
  await sortData(criteria, 'post').then(result => {
    criteriaData = result.data;
  });
  dispatch({ type: types.POST_CUSTOMER_CRITERIA.PENDING, payload: null });
  await setAuthHeader(data);
  let criteriaValue = null;
  await api
    .put(`/customer/col/criteria`, JSON.parse(criteriaData))
    .then(response => {
      criteriaValue = response.data.data.customerCriteria;
      dispatch({
        type: types.POST_CUSTOMER_CRITERIA.SUCCESS,
        payload: {
          customerCriteria: response.data.data.customerCriteria,
          pois: response.data.data.pois,
        },
      });
      if (response.data.data.customerCriteria === null) {
        history.replace('homes/search');
      }
    })
    .catch(error => {
      dispatch({ type: types.POST_CUSTOMER_CRITERIA.ERROR });
      if (error.message === 'Request failed with status code 403') {
        enqueueSnackbar('Please verify your email to post your criteria', { variant: 'info' });
      }
      if (error.message === 'Request failed with status code 400') {
        enqueueSnackbar('Something is wrong with a field you submitted', { variant: 'info' });
      }
      setTimeout(() => {
        if (criteriaValue === null) {
          history.replace('/homes/search');
        }
      }, 3000);
    });
};
export const updateCustomerCriteria = ({ data, criteria, enqueueSnackbar }) => async dispatch => {
  let criteriaData = {};
  await sortData(criteria, 'update').then(result => {
    criteriaData = result.data;
  });
  dispatch({ type: types.UPDATE_CUSTOMER_CRITERIA.PENDING, payload: null });
  await setAuthHeader(data);
  api
    .put(`/customer/col/criteria`, JSON.parse(criteriaData))
    .then(response => {
      dispatch({
        type: types.UPDATE_CUSTOMER_CRITERIA.SUCCESS,
        payload: {
          customerCriteria: response.data.data.customerCriteria,
          pois: response.data.data.pois,
        },
      });
    })
    .catch(response => {
      dispatch({ type: types.UPDATE_CUSTOMER_CRITERIA.ERROR });
      if (response.message === 'Request failed with status code 403') {
        enqueueSnackbar('Please verify your email to post your criteria', { variant: 'info' });
      } else {
        enqueueSnackbar('Something went wrong with your criteria update', { variant: 'info' });
      }
    });
};
