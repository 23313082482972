import urlRegex from 'url-regex';
import { FETCHINGMESSAGES, FETCHINGROOM, SENDMESSAGE } from '../redux/types';

export const search = (term, arr, isAgent) => {
  const key = term.toLowerCase();
  let data = [...arr];
  if (term) {
    data = data.filter(unit => {
      return (
        String(unit.last_message)
          .toLowerCase()
          .includes(key) ||
        unit.date.includes(key) ||
        String(unit[`${isAgent ? 'customer' : 'agent'}_id`]).includes(key) ||
        unit[`${isAgent ? 'customer' : 'agent'}_name`].toLowerCase().includes(key) ||
        unit.create_date.includes(key) ||
        String(unit.room_id).includes(key)
      );
    });
  }

  return data;
};

export const getFileExtention = filename => filename.split('.').pop();

export const formatSize = size => {
  let d = Math.floor(Math.log(size) / Math.log(1024));
  return 0 == size
    ? '0 Bytes'
    : parseFloat((size / Math.pow(1024, d)).toFixed(Math.max(0, 1))) +
        ' ' +
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
};

export const getFileName = filename =>
  filename
    .split('.')
    .slice(0, -1)
    .join('.');

export const getDomain = link => {
  let hostname;
  if (link.indexOf('//') > -1) {
    hostname = link.split('/')[2];
  } else {
    hostname = link.split('/')[0];
  }

  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];

  return hostname;
};

export const formatTime = date => {
  const dateObj = new Date(date);
  const dateArray = dateObj.toLocaleTimeString('it-IT').split(':');
  return `${dateArray[0]}:${dateArray[1]}`;
};

export const formatDate = data => {
  const dt = new Date(data),
    date = dt.getDate(),
    diffDays = new Date().getDate() - date,
    diffMonths = new Date().getMonth() - dt.getMonth(),
    diffYears = new Date().getFullYear() - dt.getFullYear();

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return formatTime(data);
  } else if (diffYears === 0 && diffDays === 1) {
    return 'Yesterday';
  } else {
    return dt.toLocaleDateString().split(',')[0];
  }
};

export const updatefiles = (roomData, url, name, type, size) => {
  const fileObj = {
    documents: 0,
    images: 1,
    audios: 2,
    videos: 3,
    others: 4,
  };

  roomData.files[0].all_files_count += 1;
  roomData.files[0].file_types[fileObj[`${type}s`]][`${type}s`].files.push({
    create_date: Date.now(),
    media_url: url,
    room_media_name: name,
    room_media_size: size,
  });
  roomData.files[0].file_types[fileObj[`${type}s`]][`${type}s`][`${type}s_count`] += 1;
  roomData.files[0].file_types[fileObj[`${type}s`]][`${type}s`].total_size += size;
};

export const urlifyAndReturnLinks = (text, list) => {
  let links = new Set();

  let emailRegex = new RegExp(
    /[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/,
    'gi',
  );

  return [
    links,
    text
      .replace(emailRegex, function(url) {
        return '<a href="mailto:' + url + '" target="_blank">' + url + '</a>';
      })
      .replace(urlRegex(), function(url) {
        links.add(url);
        return '<a href="//' + url + '"  target="_blank">' + url + '</a>';
      }),
  ];
};
export const clearChatState = () => dispatch => {
  dispatch({
    type: FETCHINGMESSAGES.PENDING,
    payload: { messages: [], page: 0, end: false },
  });
  dispatch({ type: SENDMESSAGE, payload: [] });

  dispatch({
    type: FETCHINGROOM.PENDING,
    payload: {
      users: [],
      properties: { property_info: [], vendor_property_criteria_info: [] },
      files: [],
      typingData: [],
    },
  });
};

export const getVpcAddress = address => {
  const { street, locality, borough_id, postcode_district } = address;
  return `${street}, ${locality}${borough_id ? ', ' + borough_id : ''}, ${postcode_district}`;
};

export const processMetaMessage = (message, processedUsers) => {
  let string = '';
  message.split(' ').map(w => {
    /(^{{)[A-Za-z0-9-]+(}}$)/.test(w)
      ? w.substring(2, w.length - 2).endsWith('s')
        ? processedUsers[w.substring(2, w.length - 3)].isMe
          ? (string = string.concat(' your'))
          : (string = string.concat(
              ` ${processedUsers[w.substring(2, w.length - 3)].firstName} ${
                processedUsers[w.substring(2, w.length - 3)].lastName
              }'s`,
            ))
        : processedUsers[w.substring(2, w.length - 2)].isMe
        ? (string = string.concat(string.length ? ' you' : 'You'))
        : (string = string.concat(
            ` ${processedUsers[w.substring(2, w.length - 2)].firstName} ${
              processedUsers[w.substring(2, w.length - 2)].lastName
            }`,
          ))
      : (string = string.concat(` ${w}`));
    return true;
  });
  return string;
};
