import ReactGA from 'react-ga4';
import { GA_MEASUREMENT_ID } from '../../Constants/env';

// ReactGA.initialize(GA_MEASUREMENT_ID);

export const initializeAnalytics = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
};
export const reportPageView = routePath => {
  ReactGA.send({ hitType: 'pageview', page: routePath });
};

export const reportGAAction = ({ category, action }) => {
  ReactGA.event({
    category,
    action,
  });
};
