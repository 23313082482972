const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
  REMOVE: `${type}_REMOVE`,
  ADD_ONE: `${type}_ADD_ONE`,
});

export const LISTING_PROPOSALS = {
  ...asyncType('LISTING_PROPOSALS'),
  MOVE_TO_PENDING: 'LISTING_PROPOSALS_MOVE_TO_PENDING',
};
export const HIRES_IN_PROGRESS = {
  ...asyncType('HIRES_IN_PROGRESS'),
  UPDATE_STATUS: 'HIRES_IN_PROGRESS.UPDATE_STATUS',
};
export const HIRES_PENDING = asyncType('HIRES_PENDING');
export const HIRES_COMPLETED = asyncType('HIRED_COMPLETED');
export const LISTING_PROPOSALS_ARCHIVED = asyncType('LISTING_PROgit POSALS_ARCHIVED');
export const LISTING_PROPOSALS_DECLINED = asyncType('LISTING_PROPOSALS_DECLINED');
export const LISTING_PROPOSALS_REQUESTED = asyncType('LISTING_PROPOSALS_REQUESTED');
export const LISTING_PROPOSALS_SHORTLISTED = asyncType('LISTING_PROPOSALS_SHORTLISTED');
