const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const ADD_PROPERTY = asyncType('ADD_PROPERTY');
export const DELETE_PROPERTY = asyncType('DELETE_PROPERTY');
export const GET_PROPERTIES = asyncType('GET_PROPERTIES');
export const UPDATE_PROPERTY_DB = asyncType('UPDATE_PROPERTY_DB');
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const CLOSE_SNACK_BAR = 'CLOSE_SNACK_BAR';
export const UPDATING_PROPERTY = 'UPDATING_PROPERTY';
