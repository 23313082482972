const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
  REMOVE: `${type}_REMOVE`,
  ADD_ONE: `${type}_ADD_ONE`,
});

export const GET_ALL_PROPOSALS = asyncType('GET_ALL_PROPOSALS');
export const GET_SHORTLISTED_PROPOSALS = asyncType('GET_SHORTLISTED_PROPOSALS');
export const GET_ARCHIVED_PROPOSALS = asyncType('GET_ARCHIVED_PROPOSALS');
export const GET_SENT_HIRE_REQUESTS = {
  ...asyncType('GET_SENT_HIRE_REQUESTS'),
  MOVE_TO_IN_PROGRESS: 'GET_SENT_HIRE_REQUESTS.MOVE_TO_IN_PROGRESS',
};
export const GET_IN_PROGRESS_PROPOSALS = {
  ...asyncType('GET_IN_PROGRESS_PROPOSALS'),
  UPDATE_STATUS: ' GET_IN_PROGRESS_PROPOSALS.UPDATE_STATUS',
};
export const GET_PREVIOUSLY_HIRED_AGENTS = asyncType('GET_PREVIOUSLY_HIRED_AGENTS');
export const GET_ALL_DECLINED_PROPOSALS = asyncType('GET_ALL_DECLINED_PROPOSALS');
