import _ from 'lodash';
import {
  GET_ALL_PROPOSALS,
  GET_ARCHIVED_PROPOSALS,
  GET_IN_PROGRESS_PROPOSALS,
  GET_SENT_HIRE_REQUESTS,
  GET_SHORTLISTED_PROPOSALS,
  GET_PREVIOUSLY_HIRED_AGENTS,
  GET_ALL_DECLINED_PROPOSALS,
} from '../types';

export const proposals = (
  state = {
    sent: null,
    shortlisted: null,
    messaged: null,
    archived: null,
    sentHires: null,
    inProgress: null,
    declined: null,
    previouslyHired: null,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_PROPOSALS.PENDING:
      return {
        ...state,
      };
    case GET_ALL_PROPOSALS.SUCCESS:
      return {
        ...state,
        sent: payload,
      };

    case GET_ALL_PROPOSALS.REMOVE: {
      const stateCopy = _.cloneDeep(state);
      stateCopy.sent[payload[0]].agents.splice(payload[1], 1);

      return {
        ...stateCopy,
      };
    }
    case GET_ALL_PROPOSALS.ADD_ONE:
      const newState = _.cloneDeep(state.sent);
      newState[payload.index].agents.unshift(payload.agent);
      return {
        ...state,
        sent: newState,
      };

    case GET_SHORTLISTED_PROPOSALS.PENDING:
      return {
        ...state,
      };
    case GET_SHORTLISTED_PROPOSALS.SUCCESS:
      return {
        ...state,
        shortlisted: payload,
      };
    case GET_SHORTLISTED_PROPOSALS.ADD_ONE: {
      const newState = _.cloneDeep(state.shortlisted);
      newState[action.payload.index].agents.unshift(action.payload.agent);
      return {
        ...state,
        shortlisted: newState,
      };
    }
    case GET_SHORTLISTED_PROPOSALS.REMOVE: {
      const stateCopy = _.cloneDeep(state.shortlisted);
      stateCopy[payload[0]].agents.splice(payload[1], 1);

      return {
        ...state,
        shortlisted: stateCopy,
      };
    }

    case GET_ARCHIVED_PROPOSALS.SUCCESS:
      return {
        ...state,
        archived: payload,
      };
    case GET_ARCHIVED_PROPOSALS.ADD_ONE: {
      const newState = _.cloneDeep(state.archived);
      newState[action.payload.index].agents.unshift(action.payload.agent);
      return {
        ...state,
        archived: newState,
      };
    }
    case GET_ARCHIVED_PROPOSALS.REMOVE: {
      const stateCopy = _.cloneDeep(state.archived);
      stateCopy[payload[0]].agents.splice(payload[1], 1);

      return {
        ...state,
        archived: stateCopy,
      };
    }

    case GET_IN_PROGRESS_PROPOSALS.SUCCESS: {
      return {
        ...state,
        inProgress: payload,
      };
    }

    case GET_IN_PROGRESS_PROPOSALS.REMOVE: {
      const stateCopy = _.cloneDeep(state.inProgress);
      stateCopy[payload[0]].agents.splice(payload[1], 1);

      return {
        ...state,
        inProgress: stateCopy,
      };
    }

    case GET_IN_PROGRESS_PROPOSALS.UPDATE_STATUS: {
      const index = state.inProgress[action.payload[0]].agents.findIndex(
        agent => agent.agent_id == action.payload[1],
      );

      if (index > -1) {
        const stateCopy = _.cloneDeep(state.inProgress);

        stateCopy[action.payload[0]].agents[index].status_ref = 'complete';

        return {
          ...state,
          inProgress: stateCopy,
        };
      } else {
        return state;
      }
    }

    case GET_SENT_HIRE_REQUESTS.SUCCESS: {
      return {
        ...state,
        sentHires: payload,
      };
    }

    case GET_SENT_HIRE_REQUESTS.ADD_ONE: {
      const newState = _.cloneDeep(state.sentHires);
      newState[action.payload.index].agents.unshift(action.payload.agent);
      return {
        ...state,
        sentHires: newState,
      };
    }

    case GET_SENT_HIRE_REQUESTS.MOVE_TO_IN_PROGRESS: {
      const index = state.sentHires[action.payload[0]].agents.findIndex(
        agent => agent.agent_id == action.payload[1],
      );

      if (index > -1) {
        const stateCopy = _.cloneDeep(state.sentHires);
        const agent = stateCopy[payload[0]].agents.splice(index, 1);

        const newState = _.cloneDeep(state.inProgress);
        newState[action.payload[0]].agents.unshift({ ...agent[0], status_ref: 'AGENT_AGREED' });

        return {
          ...state,
          sentHires: stateCopy,
          inProgress: newState,
        };
      } else {
        return state;
      }
    }

    case GET_PREVIOUSLY_HIRED_AGENTS.SUCCESS: {
      return {
        ...state,
        previouslyHired: payload,
      };
    }

    case GET_PREVIOUSLY_HIRED_AGENTS.ADD_ONE: {
      const newState = _.cloneDeep(state.previouslyHired);
      newState[action.payload.index].agents.unshift({ ...action.payload.agent, feedback: {} });
      return {
        ...state,
        previouslyHired: newState,
      };
    }

    case GET_ALL_DECLINED_PROPOSALS.SUCCESS: {
      return {
        ...state,
        declined: payload,
      };
    }

    default:
      return state;
  }
};
