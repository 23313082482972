import { ApiEndpoints } from '../../../../Constants';

import * as types from '../types';

import { io } from 'socket.io-client';
import { updatefiles } from '../../utils';
import _ from 'lodash';
import { NUMBEROFUNREADMESSAGES } from '../types/messages';
import api from '../../../../api';

export const closeWebSocket = socket => {
  if (socket.connected) {
    socket.disconnect();
  }

  return {
    type: types.CLOSEWEBSOCKET,
  };
};

export const initialiseWebSocket = (token, webSocket) => async dispatch => {
  dispatch(closeWebSocket(webSocket));

  try {
    const socket = io(`${ApiEndpoints.io_endpoint}?token=${token}`, { forceNew: true });

    dispatch({ type: types.INITIALISEWEBSOCKET, payload: socket });
  } catch (e) {
    console.log(e);
  }
};

export const sendMessage = (
  socket,
  roomId,
  message,
  author,
  id,
  messageType,
  propertyId,
  messageLinks,
  messages = [],
  portal = false,
  isAgent,
) => {
  socket.emit(`send-message`, {
    author,
    roomId,
    message,
    [isAgent ? 'customerId' : 'agentId']: id,
    [portal ? 'vendorPropertyCriteriaId' : 'propertyId']: propertyId,
    messageType,
    messageLinks,
  });

  return {
    type: types.SENDMESSAGE,
    payload: [
      ...messages,
      {
        message,
        notSentYet: true,
        message_type: messageType,
        author,
        create_date: Date.now(),
      },
    ],
  };
};

export const messageResponse = (messages, message, room, chatOpen) => {
  const formattedLinks = message.data.message_links.map(link => ({
    create_date: message.data.create_date,
    link,
  }));
  room.links = room.links.concat(formattedLinks);
  const payload = [...messages];
  if (!chatOpen && !payload.length) {
    message.data.unread = true;
  }
  message.data = _.mapKeys(message.data, (v, k) => _.snakeCase(k));

  const indexOfMessage = payload.findIndex(msg =>
    message.data.message_type === 'text' || message.data.message_type === 'meta'
      ? msg.notSentYet === true &&
        msg.message === message.data.message &&
        msg.author === message.data.author
      : msg.notSentYet === true &&
        msg.file_name === message.data.file_name &&
        msg.file_size === message.data.file_size &&
        msg.author === message.data.author,
  );
  if (indexOfMessage > -1) {
    if (message.status.toLowerCase() === 'error') {
      payload[indexOfMessage].error = true;
    } else {
      payload[indexOfMessage].notSentYet = false;
      payload[indexOfMessage].create_date = message.data.create_date;
      payload[indexOfMessage].message = message.data.message;
      if (message.data.message_type !== 'text' && message.data.message_type !== 'meta')
        payload[indexOfMessage].uploading = false;
    }
  } else payload.push(message.data);
  if (message.data.message_type !== 'text' && message.data.message_type !== 'meta')
    updatefiles(
      room,
      message.data.message,
      message.data.file_name,
      message.data.message_type,
      message.data.file_size,
    );

  return {
    type: types.SENDMESSAGE,
    payload,
  };
};

export const updateRoom = (
  roomData,
  rooms,
  openRoomId,
  chatOpen,
  increment = true,
) => async dispatch => {
  const payload = [...rooms];

  const index = payload.findIndex(room => {
    return roomData.room_id === room.room_id;
  });

  if (index > -1) {
    payload[index].last_message =
      roomData.message_type === 'text' || roomData.message_type === 'meta' ? roomData.message : '';
    payload[index].last_message_type = roomData.message_type;
    payload[index].date = roomData.create_date;
    const newRoom = payload.splice(index, 1);
    if (!(chatOpen && roomData.room_id == openRoomId) && increment) {
      newRoom[0].unread_messages++;
      dispatch({ type: NUMBEROFUNREADMESSAGES.UPDATE, payload: 1 });
    }
    payload.unshift(newRoom[0]);
  }

  dispatch({
    type: types.FETCHINGALLROOMS.SUCCESS,
    payload,
  });
};

export const sendTyping = (socket, typing, data) => {
  socket.emit(`${typing ? 'typing' : 'stopped-typing'}`, data);
};

export const typingResponse = (data, rooms, currentRoom, typing) => dispatch => {
  const roomsArr = [...rooms];
  const openRoom = { ...currentRoom };

  const index = roomsArr.findIndex(rm => rm.room_id == data.roomId);
  const exists = (dataparameter, item) =>
    dataparameter ? dataparameter.findIndex(unit => unit.userId == item.userId) : -1;

  if (openRoom.roomId == data.roomId) {
    const index2 = exists(openRoom.typingData, data);
    if (index2 > -1) {
      const typingData = openRoom.typingData.splice(index2, 1);
      if (typing) {
        openRoom.typingData.unshift(typingData[0]);
      }
    } else if (typing) {
      openRoom.typingData.unshift(data);
    }
  }

  if (index > -1) {
    const index2 = exists(roomsArr[index].typingData, data);
    if (index2 > -1) {
      const typingData = roomsArr[index].typingData.splice(index2, 1);
      if (typing) {
        roomsArr[index].typingData.unshift(typingData[0]);
      }
    } else if (typing) {
      roomsArr[index].typingData
        ? roomsArr[index].typingData.unshift(data)
        : (roomsArr[index].typingData = [data]);
    }
  }
  dispatch({ type: types.FETCHINGALLROOMS.SUCCESS, payload: roomsArr });
  dispatch({ type: types.FETCHINGROOM.SUCCESS, payload: openRoom });
};
