import {
  REMOVE_POI_DATA,
  GET_POI_DATA,
  SET_POLYLINES,
  SET_TIME,
  SET_TRAVEL_MODE,
  SET_SEARCH_VALUE,
  SET_MAPS_POI,
  SET_MAP_POI,
  SET_POI,
  SET_POI_MARKERS,
  SET_POI_ACTIVE_MARKERS,
} from '../types';

const intialState = {
  map: null,
  maps: null,
  loading: false,
  error: false,
  errorMessage: '',
  poiMarkers: [],
  poiActiveMarkers: [],
  polylines: [],
  pois: [],
  formData: {
    travelMode: 'drive',
    searchValue: '',
    time: '10',
  },
};

const POIReducers = (state = intialState, action) => {
  switch (action.type) {
    case SET_MAP_POI:
      return {
        ...state,
        map: action.payload,
      };
    case SET_MAPS_POI:
      return {
        ...state,
        maps: action.payload,
      };
    case SET_POI_MARKERS:
      return {
        ...state,
        poiMarkers: action.payload,
      };
    case SET_POI_ACTIVE_MARKERS:
      return {
        ...state,
        poiActiveMarkers: action.payload,
      };
    case GET_POI_DATA.PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_POI_DATA.SUCCESS:
      return {
        ...state,
        pois: action.payload,
        error: false,
        loading: false,
        markersLoaded: true,
      };
    case SET_POI:
      return {
        ...state,
        pois: action.payload,
      };
    case SET_POLYLINES:
      return {
        ...state,
        polylines: action.payload,
      };
    case GET_POI_DATA.ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        ...(action.payload.pois && { pois: action.payload.pois }),
        loading: false,
      };
    case SET_TIME:
      return {
        ...state,
        formData: {
          ...state.formData,
          time: action.payload,
        },
      };
    case SET_TRAVEL_MODE:
      return {
        ...state,
        formData: {
          ...state.formData,
          travelMode: action.payload,
        },
      };
    case SET_SEARCH_VALUE:
      return {
        ...state,
        formData: {
          ...state.formData,
          searchValue: action.payload,
        },
      };
    case REMOVE_POI_DATA:
      return {
        ...state,
        pois: action.payload,
      };
    default:
      return state;
  }
};

export default POIReducers;
