import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/auth.slice';
import signupReducer from './signup/signup.slice';
import socialAuthReducer from './socialAuth/socialAuth.slice';
import passwordReducer from './password/password.slice';

export default combineReducers({
  auth: authReducer,
  signup: signupReducer,
  socialAuth: socialAuthReducer,
  password: passwordReducer,
});
