import { combineReducers } from 'redux';
import { profileReducer, isUpdated } from './profile';
import { jobListingReducer, postApplicationReducer } from './career';

export default combineReducers({
  profile: profileReducer,
  isUpdated: isUpdated,
  jobListing: jobListingReducer,
  postApplication: postApplicationReducer,
});
