/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as types from '../types';

const initialState = {
  data: {},
};

export default produce((draft, action) => {
  switch (action.type) {
    case types.ADD_SEARCH_FILTERS:
      draft.data = action.payload;
      break;

    case types.UPDATE_SEARCH_FILTER:
      const { key, value } = action.payload;
      draft.data[key] = value;
      break;

    default:
      break;
  }
}, initialState);
