import { GET_COMPLETED_PROPRTIES } from '../types';

export const completedPropertyReducer = (initialState = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COMPLETED_PROPRTIES.PENDING: {
      return {
        ...initialState,
        error: false,
        pending: true,
      };
    }
    case GET_COMPLETED_PROPRTIES.SUCCESS: {
      return {
        ...initialState,
        properties: payload,
        error: false,
        pending: false,
      };
    }
    case GET_COMPLETED_PROPRTIES.ERROR: {
      return {
        ...initialState,
        error: true,
        pending: false,
        message: 'Unable to get completed properties',
      };
    }
    default:
      return initialState;
  }
};
