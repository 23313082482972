/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLogout } from './socialAuth.slice';
import api from '../../../../api';

export const socialAuthenticate = createAsyncThunk(
  'socialAuth/social_authenticate',
  async userData => {
    const { params, mode } = userData;
    try {
      const response = await api.get(
        `/auth/authorise/${mode}`,
        { params },
        { withCredentials: true },
      );
      if (!response) throw new Error('Could not fetch');
      return { auth: response.data };
    } catch (error) {
      return {
        status: error.response && error.response.status ? error.response.status : error,
        message: error.message,
      };
    }
  },
);

export const getSocialRedirectLink = createAsyncThunk(
  'socialAuth/get_social_redirect_link',
  async mode => {
    try {
      const response = await api.get(`/auth/${mode}`);
      return { auth: response.data };
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const logoutSocialUser = () => dispatch => {
  dispatch(setLogout());
};
