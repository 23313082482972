/* eslint-disable import/no-cycle */
/* eslint-disable import/named */
import { createSlice } from '@reduxjs/toolkit';
import { updateCustomerProfile } from './profile.actions';

const initialState = {
  profile: {},
  savingPreferences: false,
  savingPrefsComplete: false,
  sending: false,
  allInfo: null,
  notification: null,
  notificationUpdate: null,
  isUpdated: false,
  message: null,
};
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    savingPreferences(state, { payload }) {
      state.savingPreferences = payload;
    },
    setSavingPreferencesComplete(state, { payload }) {
      state.savingPrefsComplete = payload;
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
    setNotificationFetch(state, action) {
      state.notification = action.payload;
    },
    setNotificationUpdate(state, action) {
      state.notificationUpdate = action.payload;
      state.notificationUpdate.saved = true;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(updateCustomerProfile.pending, state => {
      state.error = null;
      state.isUpdated = false;
    });
    builder.addCase(updateCustomerProfile.fulfilled, (state, action) => {
      state.error = null;
      state.isUpdated = true;
      state.message = action.payload;
    });
    builder.addCase(updateCustomerProfile.rejected, (state, action) => {
      state.isUpdated = true;
      state.message = action.payload;
      state.error = { message: action.error.message };
    });
  },
});

export const {
  setProfile,
  setNotificationFetch,
  setNotificationUpdate,
  setSavingPreferencesComplete,
  savingPreferences,
  setMessage,
} = profileSlice.actions;

export default profileSlice.reducer;
