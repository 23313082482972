export const HTTP_STATUS = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
});

export const AUTHENTICATION_STATUS = Object.freeze({
  UNKNOWN: 'UNKNOWN',
  VERIFYING: 'VERIFYING',
  AUTHENTICATED: 'AUTHENTICATED',
  UN_AUTHENTICATED: 'UN_AUTHENTICATED',
});
