import * as types from '../types';
import getDirections from '../../../../services/get_directions';

export const setMap = map => dispatch => {
  dispatch({ type: types.SET_MAP, payload: map });
};

export const setMaps = maps => dispatch => {
  dispatch({ type: types.SET_MAPS, payload: maps });
};

export const updateDestination = ({
  center,
  destination,
  travelMode,
  destinations,
  enqueueSnackbar,
}) => async dispatch => {
  const direction = await getDirections({
    origin: center,
    destination: destination.currentMarker,
    travelMethod: travelMode,
    destinationType: 'coordinate',
    contents: [],
    errorHandler: enqueueSnackbar,
  });
  const { distance = 0, duration = 0 } = direction;
  const payload = await [
    ...destinations.map(dest =>
      dest.id === destination.id
        ? {
            ...dest,
            distance,
            duration,
            travelMode,
          }
        : dest,
    ),
  ];
  dispatch({ type: types.SET_DESTINATION, payload });
};

export const changeDestination = ({
  marker,
  center,
  destinations,
  enqueueSnackbar,
}) => async dispatch => {
  const { destinationIndex } = marker;
  const direction = await getDirections({
    origin: destinationIndex === 0 ? center : destinations[destinationIndex - 1].currentMarker,
    destination: marker,
    travelMethod: destinations[destinationIndex].travelMode,
    destinationType: 'coordinate',
    errorHandler: enqueueSnackbar,
  });
  const { distance = 0, duration = 0 } = direction;
  const payload = [
    ...destinations.map(dest =>
      dest.id === marker.destinationId
        ? {
            ...dest,
            currentMarker: marker,
            distance,
            duration,
          }
        : dest,
    ),
  ];
  dispatch({ type: types.SET_DESTINATION, payload });
};

export const addDestinations = ({ dest, destinations }) => dispatch => {
  const payload = [...destinations, dest];
  dispatch({ type: types.SET_DESTINATION, payload });
};

export const removeDestination = ({ destination, destinations }) => dispatch => {
  if (destinations.length === 1) return;
  const payload = [
    ...destinations
      .filter(dest => dest.id !== destination.id)
      .map((dest, index) => ({
        ...dest,
        id: `item-${index}`,
        value: `item-${index}`,
      })),
  ];
  dispatch({ type: types.SET_DESTINATION, payload });
};

export const drawRoutes = ({ distance, duration, polylinesArray }) => dispatch => {
  dispatch({
    type: types.SET_ROUTINES,
    payload: { distance, duration, polylines: polylinesArray },
  });
};

export const selectAddress = (
  destination,
  destinations,
  address,
  destinationIndex,
  markers,
  distance,
  duration,
) => async dispatch => {
  const payload = await [
    ...destinations.map((dest, index) =>
      index === destinationIndex
        ? {
            ...dest,
            currentMarker: markers[0],
            markers,
            distance,
            duration,
            address: {
              ...address,
              address: address.name,
            },
          }
        : dest,
    ),
  ];
  dispatch({ type: types.SET_DESTINATION, payload });
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const dragEnd = ({ result, destinations }) => dispatch => {
  if (!result.destination) {
    return;
  }

  const items = reorder(destinations, result.source.index, result.destination.index);
  dispatch({
    type: types.SET_DESTINATION,
    payload: items,
  });
};

export const onMapApiLoaded = (loadedMap, loadedMaps) => {
  setMap(loadedMap);
  setMaps(loadedMaps);
};
