export const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const CREATE_PROFILE = asyncType('CREATE_PROFILE');
export const UPDATE_PROFILE = asyncType('UPDATE_PROFILE');
export const FETCH_PROFILE = asyncType('FETCH_PROFILE');
export const USER_PROFILE_UPDATE = asyncType('USER_PROFILE_UPDATE');
export const AGENT_ALL_INFO = 'AGENT_ALL_INFO';
export const USER_PROFILE = 'USER_PROFILE';
export const NOTIFICATION_FETCH = 'NOTIFICATION_FETCH';
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';
