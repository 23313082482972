/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
import { combineReducers } from 'redux';

import {
  searchTerm,
  esSearchReducer,
  esItemSelected,
  isSuggestionSelected,
} from '../../components/SearchBox/reducers/search.rd.js';
import { selectChannel } from '../../components/SearchBox/reducers/channel.rd.js';
import toggleHamburger from '../../components/hamburger/reducers/toggle_hamburger.rd.js';
import isSearching from '../../components/SearchBox/reducers/searching.rd.js';
import searchCursor from '../../components/SearchBox/reducers/cursor.rd.js';
import agent from '../../modules/agents/redux/reducers';
import question_step from '../../modules/search/components/Questions/navigation_buttons/reducers/step_direction.rd';
import agent_question_step from '../../modules/agents/components/Questions/navigation_buttons/reducers/step_direction.rd';
import agent_question_step2 from '../../modules/general/components/Questions/navigation_buttons/reducers/step_direction.rd';
import property_address from '../../components/Address/redux/reducers/index.js';
import agent_feedback_step from '../../modules/agents/components/Feedback/navigation_buttons/reducers/step_direction.rd';
import vendor_feedback_step from '../../modules/vendor/components/Feedback/navigation_buttons/reducers/step_direction.rd';
import radiusSliderValue from '../../modules/search/components/Questions/Radius/reducers/radius_slide.rd.js';
import pois from '../../modules/searchV2/components/Maps/POI/reducers/pois.rd.js';
import routines from '../../modules/search/components/Questions/Routine/reducers/routine.rd.js';
import areaRadiusSlideValue from '../../modules/search/components/Questions/AreaAmenities/reducers/radius_slide.rd.js';
import routine_switch from '../../modules/search/components/Questions/Routine/reducers/routine_switch.rd.js';
import user_amenities from '../../modules/search/components/Questions/AreaAmenities/reducers/user_amenities.rd.js';
import load_map from '../../components/Map/reducers/load_map.rd.js';
import load_geojson from '../../components/Map/reducers/load_geojson.rd.js';
import toggle_map_view from '../../modules/search/redux/reducers/toggle_map_view';
import search from '../../modules/search/redux/reducers';
import profile from '../../modules/general/redux/reducers';
import auth from '../../modules/auth/redux/reducers';
import shared from '../../modules/shared/redux/reducers';
import selectReducers from '../../modules/general/components/Select/reducers/selectreducer.rd.js';
import tabReducers from '../../modules/general/components/Tab/reducers';
import chat from '../../modules/chat/redux/reducers';
import listings from '../../modules/listings/redux/reducers';
import marketed from '../../modules/marketed/redux/reducers';
import vendor from '../../modules/vendor/redux/reducers';
// import customerCriteria from '../slices/customerCriteria';
import homesReducers, { matchesReducer } from '../../modules/homes/redux/reducers';
import customerCriteria from '../slices/customerCriteria';
import searchV2 from '../../modules/searchV2/slices/index.js';
import authV2 from '../../modules/authV2/slices/index.js';
import propertyV2 from '../../modules/propertiesV2/slices/index.js';
import shortlistV2 from '../../modules/shortlistV2/slices/index.js';
import profileV2 from '../../modules/profileV2/slices/index.js';
// use glob package to get all the reducers
// reducers file format **/*.rd.js

const rootReducer = combineReducers({
  landingPageSearchTerm: searchTerm,
  es_suggestions: esSearchReducer,
  esItemSelected,
  isSuggestionSelected,
  toggleHamburger,
  isSearching,
  searchCursor,
  question_step,
  agent_question_step,
  agent_question_step2,
  property_address,
  agent_feedback_step,
  vendor_feedback_step,
  radiusSliderValue,
  pois,
  routines,
  areaRadiusSlideValue,
  routine_switch,
  user_amenities,
  load_map,
  load_geojson,
  toggle_map_view,
  agent,
  search,
  searchV2,
  authV2,
  propertyV2,
  shortlistV2,
  profileV2,
  profile,
  shared,
  auth,
  career: profile,
  select: selectReducers,
  matches: matchesReducer,
  tab: tabReducers,
  selectChannel,
  chat,
  marketed,
  vendor,
  homes: homesReducers,
  customerCriteria,
  listings,
});

export default rootReducer;
