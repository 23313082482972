import { GET_PENDING_PROPRTIES } from '../types';

export const pendingMarketedReducer = (initialState = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PENDING_PROPRTIES.PENDING: {
      return {
        ...initialState,
        error: false,
        pending: true,
      };
    }
    case GET_PENDING_PROPRTIES.SUCCESS: {
      return {
        ...initialState,
        properties: payload,
        error: false,
        pending: false,
      };
    }
    case GET_PENDING_PROPRTIES.ERROR: {
      return {
        ...initialState,
        error: true,
        pending: false,
        message: 'Unable to get properties',
      };
    }
    default:
      return initialState;
  }
};
