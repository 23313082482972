import * as types from '../types';

const initialState = {
  loading: false,
  customerCriteria: null,
  user: {},
  pois: [],
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_CRITERIA.PENDING: {
      return { ...state, loading: true, loaded: false };
    }
    case types.GET_CUSTOMER_CRITERIA.SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        customerCriteria: action.payload.customerCriteria,
        pois: action.payload.pois,
        loaded: true,
      };
    }
    case types.GET_CUSTOMER_CRITERIA.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case types.POST_CUSTOMER_CRITERIA.PENDING: {
      return { ...state, loading: true };
    }
    case types.POST_CUSTOMER_CRITERIA.SUCCESS: {
      return {
        ...state,
        loading: false,
        customerCriteria: action.payload.customerCriteria,
        pois: action.payload.pois,
      };
    }
    case types.POST_CUSTOMER_CRITERIA.ERROR: {
      return { ...state, loading: true };
    }
    case types.UPDATE_CUSTOMER_CRITERIA.PENDING: {
      return { ...state, loading: true };
    }
    case types.UPDATE_CUSTOMER_CRITERIA.SUCCESS: {
      return {
        ...state,
        loading: false,
        customerCriteria: action.payload.customerCriteria,
        pois: action.payload.pois,
      };
    }
    default: {
      return { ...state };
    }
  }
};
