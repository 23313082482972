import {
  SET_TIME_SCHOOL,
  SET_MAPS_SCHOOL,
  SET_MAP_SCHOOL,
  SET_TRAVEL_MODE_SCHOOL,
  SET_SCHOOL_DATA,
} from '../types';

const intialState = {
  map: null,
  maps: null,
  loading: false,
  error: false,
  errorMessage: '',
  time: 20,
  travelMode: 'DRIVING',
  schools: [],
  closestSchools: [],
};

const POIReducers = (state = intialState, action) => {
  switch (action.type) {
    case SET_MAP_SCHOOL:
      return {
        ...state,
        map: action.payload,
      };
    case SET_MAPS_SCHOOL:
      return {
        ...state,
        maps: action.payload,
      };
    case SET_TIME_SCHOOL:
      return {
        ...state,
        time: action.payload,
      };
    case SET_TRAVEL_MODE_SCHOOL:
      return {
        ...state,
        travelMode: action.payload,
      };
    case SET_SCHOOL_DATA.SUCCESS:
      return {
        ...state,
        schools: action.payload.schools,
        closestSchools: action.payload.closestSchools,
      };
    case SET_SCHOOL_DATA.ERROR:
      return {
        ...state,
        schools: action.payload.schools,
        closestSchools: action.payload.closestSchools,
      };
    default:
      return state;
  }
};

export default POIReducers;
