const initialState = {
  error: false,
  pending: false,
  errorObject: {},
};

const reducer = (actionType, defaultData = {}) => (
  state = { ...initialState, data: defaultData },
  action,
) => {
  switch (action.type) {
    case actionType.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
        data: action.payload || state.data,
      };
    }
    case actionType.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: false,
        pending: false,
      };
    }
    case actionType.ERROR: {
      return {
        ...state,
        errorObject: action.payload,
        error: true,
        pending: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
