import { createSlice } from '@reduxjs/toolkit';
import { getSchoolData } from './school.actions';

const NAMESPACE = 'school';

const initialState = {
  map: null,
  maps: null,
  activeSchoolProperty: null,
  loading: false,
  error: false,
  errorMessage: '',
  time: 20,
  travelMode: 'DRIVING',
  schools: [],
  closestSchools: null,
};

const schoolSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setActiveSchoolProperty(state, action) {
      state.activeSchoolProperty = action.payload;
    },
    setMapSchool(state, action) {
      state.map = action.payload;
    },
    setMapsSchool(state, action) {
      state.maps = action.payload;
    },
    setTimeSchool(state, action) {
      state.time = action.payload;
    },
    setTravelModeSchool(state, action) {
      state.travelMode = action.payload;
    },
    setClosestSchool(state, action) {
      state.closestSchools = action.payload;
    },
    setSchools(state, action) {
      state.schools = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSchoolData.pending, state => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getSchoolData.fulfilled, (state, action) => {
        state.schools = action.payload.schools;
        state.closestSchools = action.payload.closestSchools;
      })
      .addCase(getSchoolData.rejected, state => {
        state.schools = [];
        state.closestSchools = null;
      });
  },
});

export const {
  setActiveSchoolProperty,
  setTimeSchool,
  setTravelModeSchool,
  setMapSchool,
  setMapsSchool,
  setClosestSchool,
  setSchools,
} = schoolSlice.actions;

export default schoolSlice.reducer;
