export const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const SET_TIME_SCHOOL = 'SET_TIME_SCHOOL';
export const SET_MAP_SCHOOL = 'SET_MAP_SCHOOL';
export const SET_MAPS_SCHOOL = 'SET_MAPS_SCHOOL';
export const SET_TRAVEL_MODE_SCHOOL = 'SET_TRAVEL_MODE_SCHOOL';
export const SET_SCHOOL_DATA = asyncType('SET_SCHOOL_DATA');
