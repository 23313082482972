/**
 * Set a new state when request is been made with the search term
 */
const isSearching = (defaultState = false, action) => {
  if (action.type === 'SEARCHING') {
    return action.payload;
  }
  return defaultState;
};

export default isSearching;
