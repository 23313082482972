/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as types from '../types';

const initialState = {
  averageSaleHistory: {
    data: [],
    error: false,
    pending: false,
  },
  propertyPrices: {
    data: [],
    error: false,
    pending: false,
  },
  nearBySoldProperties: {
    data: [],
    error: false,
    pending: false,
  },
  priceHistory: {
    data: {},
    error: false,
    pending: false,
  },
};

export default produce((draft, action) => {
  switch (action.type) {
    case types.GET_NEAR_BY_SOLD_PROPERTIES.PENDING:
      draft.nearBySoldProperties.data = [];
      draft.nearBySoldProperties.error = false;
      draft.nearBySoldProperties.pending = true;
      break;
    case types.GET_NEAR_BY_SOLD_PROPERTIES.SUCESS:
      draft.nearBySoldProperties.data = action.payload;
      draft.nearBySoldProperties.error = false;
      draft.nearBySoldProperties.pending = false;
      break;

    case types.GET_NEAR_BY_SOLD_PROPERTIES.ERROR:
      draft.nearBySoldProperties.data = [];
      draft.nearBySoldProperties.error = true;
      draft.nearBySoldProperties.pending = false;
      break;

    // Property average Sale History
    case types.GET_PRICE_HISTORY.PENDING:
      draft.priceHistory.data = {};
      draft.priceHistory.error = false;
      draft.priceHistory.pending = true;
      break;
    case types.GET_PRICE_HISTORY.SUCESS:
      draft.priceHistory.data = action.payload;
      draft.priceHistory.error = false;
      draft.priceHistory.pending = false;
      break;

    case types.GET_PRICE_HISTORY.ERROR:
      draft.priceHistory.data = {};
      draft.priceHistory.error = true;
      draft.priceHistory.pending = false;
      break;

    // Average Sale History
    case types.GET_AVERAGE_SALE_HISTORY.PENDING:
      draft.averageSaleHistory.data = {};
      draft.averageSaleHistory.error = false;
      draft.averageSaleHistory.pending = true;
      break;
    case types.GET_AVERAGE_SALE_HISTORY.SUCESS:
      draft.averageSaleHistory.data = action.payload;
      draft.averageSaleHistory.error = false;
      draft.averageSaleHistory.pending = false;
      break;

    case types.GET_AVERAGE_SALE_HISTORY.ERROR:
      draft.averageSaleHistory.data = {};
      draft.averageSaleHistory.error = true;
      draft.averageSaleHistory.pending = false;
      break;
    default:
      break;
  }
}, initialState);
