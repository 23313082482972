/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import localforage from 'localforage';
import { persistStore, persistReducer } from 'redux-persist';

import reducers from './reducers';

const persistConfig = {
  key: 'root',
  storage: localforage,
  blacklist: [],
  whitelist: ['auth'],
};

const persistedReducers = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducers,
  devTools: process.env.REACT_APP_ENV !== 'production',
  middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;
