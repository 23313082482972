// This instance of the api defined was causing 422 error on auth refresh;

/* import axios from 'axios';
import { ApiEndpoints } from '../Constants';
// TODO
// [@ukor <github.com/ukor>] - ask the dev that implemented authentication about the token storage
// stop using redux-persit for storing auth details
// token and userId should be persited on the localStorage
// onlu userId should be store in the JWT - this way the BE can verify that the token belongs to the user
const _token = 'lorem'; // window.localStorage.getItem('_t')
const _uid = window.localStorage.getItem('_u');
// device ID can be use for customer criteria and inplace for filterId
const _deviceId = window.localStorage.getItem('_d');
const AxiosInstance = axios.create({
  baseURL: ApiEndpoints.api_endpoint,
  headers: {
    'Content-Type': 'application/json',
    // user token
    'x-uid': `${_token}`,
    // api gateway authentication
    Authorization: `Bearer ${_token}`,
  },
});
// todo [@ukor <github.com/ukor>]
// use Axios interceptor to intercept the request and responsee
// I commented this out because the BE does not have a standardize response
// AxiosInstance.interceptors.response.use(
//   response => {
//     console.log(response, 'response');
//     return response;
//   },
//   error => {
//     if (error.response) {
//       const { status } = error.response;
//       if (status === 500) {
//         return Promise.reject(new Error('Internal server error'));
//       }
//       return error.response;
//     }
//     return Promise.reject(error);
//   },
// );
export default AxiosInstance;
 */

import axios from 'axios';
import { ApiEndpoints } from '../Constants';

/**
 * @depricated in favour of `src/Constants/index` - ('./api_enpoint')
 * Will be remove in subsiquent version
 *
 * depricated by @ukor <github.com/ukor>
 */
export const endpoints = (() => {
  const prodEndpoints = {
    // provides endpoint to fetch geojsons
    // changes made to the geojson endpoint should be effected
    // on the src/views/searchData/components/map.jsx file
    geojsonEndpoint: 'https://staging.plaices.ai/polygon/',
    // provide access to the backend logic
    // api: 'https://plaices.ai/api/',
    api: 'https://plaicesbackendapi.azurewebsites.net/api/',

    blob_url: 'https://plaicesbackendstorage.blob.core.windows.net/s3-bucket-data',
    // aiEngineURL: 'https://hrmlf3b4prkdyzstl5qbwebona0vyfjq.lambda-url.eu-west-2.on.aws',
    aiEngineURL: 'https://plaicesai-service.azurewebsites.net'
  };
  const devEndpoints = {
    // provides endpoint to fetch geojsons
    geojsonEndpoint: 'https://staging.plaices.ai/polygon/',
    // provide access to the backend logic
    api: 'https://staging.plaices.ai/api/',

    blob_url: 'https://plaicesbackendstorage.blob.core.windows.net/s3-bucket-data',
    // aiEngineURL: 'https://hrmlf3b4prkdyzstl5qbwebona0vyfjq.lambda-url.eu-west-2.on.aws',
    aiEngineURL: 'https://plaicesai-service.azurewebsites.net'
  };
  const localEndpoints = {
    // provides endpoint to fetch geojsons
    geojsonEndpoint: 'http://localhost:5000/polygon/',
    // provide access to the backend logic
    api: 'http://localhost:5000/api/',

    blob_url: 'https://plaicesbackendstorage.blob.core.windows.net/s3-bucket-data',
    // aiEngineURL: 'https://hrmlf3b4prkdyzstl5qbwebona0vyfjq.lambda-url.eu-west-2.on.aws',
    aiEngineURL: 'https://plaicesai-service.azurewebsites.net'
  };
  const env = process.env.REACT_APP_ENV;
  if (env === 'production') return prodEndpoints;
  if (env === 'development') return devEndpoints;
  return localEndpoints;
})();

const token = 'lorem';

axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

export default axios.create({
  baseURL: ApiEndpoints.api_endpoint,
});
