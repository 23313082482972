import { combineReducers } from 'redux';
import shortlistReducers from './shortlist';
import matchesReducer from './matches';
import customerCriteriaReducers from './customer_criteria';

export default combineReducers({
  shortlist: shortlistReducers,
  criteria: customerCriteriaReducers,
});
export { matchesReducer };
