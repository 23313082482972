const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const FETCHINGMESSAGES = asyncType('FETCHINGMESSAGES');
export const NUMBEROFUNREADMESSAGES = {
  UPDATE: 'NUMBEROFUNREADMESSAGESUPDATE',
  REPLACE: 'NUMBEROFUNREADMESSAGESREPLACE',
};
export const CHATOPEN = 'CHATOPEN';
