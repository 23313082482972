export const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const REGISTERING_USER = asyncType('REGISTERING_USER');

export const REGISTERING_AGENT = asyncType('REGISTERING_AGENT');

export const SEND_ALL_DATA = asyncType('SEND_ALL_DATA');

export const GET_CUSTOMER_AGREEMENT = 'GET_CUSTOMER_AGREEMENT';

export const UPDATE_USER_DETAILS = asyncType('UPDATE_USER_DETAILS');

export const GET_USER_DETAILS = asyncType('GET_USER_DETAILS');

export const CLEAR_SIGNUP_ERRORS = 'CLEAR_SIGNUP_ERRORS';

export const RESEND_SECURITY_CODE = asyncType('RESEND_SECURITY_CODE');

export const CHECK_SECURITY_CODE = asyncType('CHECK_SECURITY_CODE');
