import _ from 'lodash';
import {
  GET_PROFILE,
  GET_AGENTS,
  GET_AGREEMENTS,
  GET_SAVED_AGENTS,
  TOGGLE_SAVE_AGENT,
  GET_INVITED_AGENTS,
  GET_PROPOSAL,
  SET_CURRENT_AGENT,
} from '../types';

export const getAgentProposal = (state = { data: {} }, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROPOSAL.SUCCESS:
      return {
        ...state,
        data: payload,
      };

    default:
      return state;
  }
};

export const currentAgent = (defaultState = {}, action) => {
  if (action.type == SET_CURRENT_AGENT) {
    return action.payload;
  }
  return defaultState;
};

export const showAgentProfile = (
  defaultState = {
    state: false,
    menuPage: '',
    doubleButton: false,
    button1: '',
    button2: '',
    thumbDown: false,
    likeUpActive: false,
    likeDownActive: false,
    isNew: false,
    isNewText: 'New',
    noThumbs: false,
  },
  action,
) => {
  if (action.type === 'SHOW_AGENT_PROFILE') {
    return action.payload;
  }
  return defaultState;
};

export const showAgentProposal = (
  defaultState = {
    state: false,
    menuPage: '',
    doubleButton: false,
    isNew: false,
  },
  action,
) => {
  if (action.type === 'SHOW_AGENT_PROPOSAL') {
    return action.payload;
  }
  return defaultState;
};

const initialState = {
  data: {
    work_experiences: [],
    transactions: {},
    shortlist: {},
    ratings: {},
    language_preferences: [],
    branch: {},
    agent: {},
  },
  agentList: [],
};

export const agentProfile = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROFILE.PENDING:
      return {
        ...state,
      };
    case GET_PROFILE.SUCCESS:
      return {
        ...state,
        data: payload,
      };
    case GET_PROFILE.ERROR:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export const agentLists = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AGENTS.PENDING:
      return {
        ...state,
      };
    case GET_AGENTS.SUCCESS:
      return {
        ...state,
        allAgents: payload,
      };

    case GET_AGENTS.REMOVE: {
      const stateCopy = _.cloneDeep(state);
      stateCopy.allAgents[payload[0]].agent.splice(payload[1], 1);

      return {
        ...stateCopy,
      };
    }

    case GET_AGENTS.ADD_ONE: {
      const stateCopy = _.cloneDeep(state);

      const index = stateCopy.allAgents.findIndex(
        value =>
          value.vendor_property_criteria.vendor_property_criteria_id ==
          payload.property.vendor_property_criteria_id,
      );

      if (index == -1) {
        stateCopy.allAgents.unshift({
          agent: payload.agent,
          vendor_property_criteria: payload.property,
        });
      } else {
        stateCopy.allAgents[index].agent.unshift(payload.agent);
      }

      return {
        ...stateCopy,
      };
    }

    case GET_AGENTS.ERROR:
      return {
        ...state,
      };
    case GET_AGREEMENTS.PENDING:
      return {
        ...state,
      };
    case GET_AGREEMENTS.SUCCESS:
      return {
        ...state,
        agent_agreement: payload.content,
        url: payload.url,
      };
    case GET_AGREEMENTS.ERROR:
      return {
        ...state,
      };
    case GET_SAVED_AGENTS.PENDING:
      return {
        ...state,
      };
    case GET_SAVED_AGENTS.SUCCESS:
      return {
        ...state,
        savedAgents: {
          data: payload,
          error: false,
        },
      };

    case GET_SAVED_AGENTS.REMOVE: {
      const stateCopy = _.cloneDeep(state);
      stateCopy.savedAgents.data[payload[0]].agent.splice(payload[1], 1);

      return {
        ...stateCopy,
      };
    }
    case GET_SAVED_AGENTS.ADD_ONE: {
      const stateCopy = _.cloneDeep(state);

      const index = stateCopy.savedAgents.data.findIndex(
        value =>
          value.vendor_property_criteria.vendor_property_criteria_id ==
          payload.property.vendor_property_criteria_id,
      );

      if (index == -1) {
        stateCopy.savedAgents.data.unshift({
          agent: payload.agent,
          vendor_property_criteria: payload.property,
        });
      } else {
        stateCopy.savedAgents.data[index].agent.unshift(payload.agent);
      }

      return {
        ...stateCopy,
      };
    }
    case GET_SAVED_AGENTS.ERROR:
      return {
        ...state,
        savedAgents: {
          error: true,
          message: payload.message,
          data: [],
        },
      };

    case GET_INVITED_AGENTS.PENDING:
      return {
        ...state,
      };
    case GET_INVITED_AGENTS.SUCCESS:
      return {
        ...state,
        invitedAgents: {
          data: payload,
          error: false,
        },
      };
    case GET_INVITED_AGENTS.ADD_ONE: {
      const stateCopy = _.cloneDeep(state);
      const index = stateCopy.invitedAgents.data.findIndex(
        value =>
          value.vendor_property_criteria.vendor_property_criteria_id ==
          payload.property.vendor_property_criteria_id,
      );
      if (index == -1) {
        stateCopy.invitedAgents.data.unshift({
          agents: payload.agent,
          vendor_property_criteria: payload.property,
        });
      } else {
        stateCopy.invitedAgents.data[index].agents.unshift(payload.agent);
      }

      return {
        ...stateCopy,
      };
    }
    case GET_INVITED_AGENTS.ERROR:
      return {
        ...state,
        invitedAgents: {
          error: true,
          message: payload.message,
          data: [],
        },
      };

    case TOGGLE_SAVE_AGENT.PENDING:
      return {
        ...state,
        savingAgent: {
          pending: true,
        },
      };
    case TOGGLE_SAVE_AGENT.SUCCESS:
      return {
        ...state,
        savingAgent: {
          pending: false,
          success: true,
          message: 'Agent saved successfully',
        },
      };
    case TOGGLE_SAVE_AGENT.ERROR:
      return {
        ...state,
        savingAgent: {
          pending: false,
          success: false,
          error: true,
          message: 'An error occoured, please try again',
        },
      };
    default:
      return {
        ...state,
      };
  }
};
