import { createSlice } from '@reduxjs/toolkit';

const NAMESPACE = 'map';

const initialState = {
  center: { lat: 55, lng: -7 },
  zoom: 10,
  toggleMap: false,
  toggleListView: false,
  bounds: null,
  activePropertyId: null,
  activeProperty: null,
  activeMapData: { homes: [] },
  // mapState = "routine" | "poi" | "school" | null
  mapState: null,
};

const mapSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setCenter(state, action) {
      state.center = action.payload;
    },
    setBounds(state, action) {
      state.bounds = action.payload;
    },
    setActiveProperty(state, action) {
      state.activeProperty = action.payload;
    },
    setActivePropertyId(state, action) {
      state.activePropertyId = action.payload;
    },
    setZoom(state, action) {
      state.zoom = action.payload;
    },
    setToggleMap(state, action) {
      state.toggleMap = action.payload;
    },
    setToggleListView(state, action) {
      state.toggleListView = action.payload;
    },
    setActiveMapData(state, action) {
      state.activeMapData = { ...action.payload };
    },
    setMapState(state, action) {
      state.mapState = action.payload;
    },
  },
});

export const {
  setCenter,
  setToggleMap,
  setToggleListView,
  setActiveMapData,
  setMapState,
  setZoom,
  setBounds,
  setActiveProperty,
  setActivePropertyId,
} = mapSlice.actions;

export default mapSlice.reducer;
