import {
  USER_PROFILE,
  AGENT_ALL_INFO,
  USER_PROFILE_UPDATE,
  CREATE_PROFILE,
  UPDATE_PROFILE,
  FETCH_PROFILE,
  NOTIFICATION_FETCH,
  NOTIFICATION_UPDATE,
} from '../types';

const state = {
  sending: false,
  profile: {},
};

export const profileReducer = (initialState = state, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_PROFILE: {
      return {
        ...initialState,
        profile: payload,
      };
    }
    case USER_PROFILE_UPDATE.PENDING: {
      return {
        ...initialState,
        isUpdated: false,
        error: false,
      };
    }
    case USER_PROFILE_UPDATE.SUCCESS: {
      return {
        ...initialState,
        isUpdated: true,
        message: payload,
        error: false,
      };
    }
    case USER_PROFILE_UPDATE.ERROR: {
      return {
        ...initialState,
        isUpdated: true,
        message: payload,
        error: true,
      };
    }
    case AGENT_ALL_INFO: {
      return {
        ...initialState,
        allInfo: payload,
      };
    }
    case NOTIFICATION_FETCH: {
      return {
        ...initialState,
        notification: payload,
      };
    }
    case NOTIFICATION_UPDATE: {
      return {
        ...initialState,
        notificationUpdate: payload,
      };
    }
    case CREATE_PROFILE.PENDING: {
      return {
        ...initialState,
        sending: payload,
      };
    }
    case CREATE_PROFILE.SUCCESS: {
      return {
        ...initialState,
        sending: payload.loading,
        profile: payload.data,
      };
    }
    case CREATE_PROFILE.ERROR: {
      return {
        ...initialState,
        sending: payload.loading,
      };
    }
    case UPDATE_PROFILE.SUCCESS: {
      return {
        ...initialState,
        profile: payload,
      };
    }
    case FETCH_PROFILE.PENDING: {
      return { ...initialState };
    }
    case FETCH_PROFILE.SUCCESS: {
      return { ...initialState, profile: payload.data };
    }
    case FETCH_PROFILE.ERROR: {
      return { ...initialState };
    }
    default:
      return initialState;
  }
};

export const isUpdated = (defaultState = false, action) => {
  if (action.type === 'UPDATING_PROFILE') {
    return action.payload;
  }
  return defaultState;
};
