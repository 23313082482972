const images = {
  logo: require('../assets/images/blue_hk.png'),
  spinner: require('../assets/images/spinner.svg'),
  hk_images: require('../assets/images/hk_image.png'),
  house: require('../assets/images/house2.jpg'),
  heart: require('../assets/images/heart.png'),
  thumbUp: require('../assets/images/thumb-up.png'),
  thumbDown: require('../assets/images/thumb-down.png'),
};

export { images };
