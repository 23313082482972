/* eslint-disable camelcase */
import Cookies from 'js-cookie';
import React from 'react';
import { useDispatch } from 'react-redux';
import PageLoader from '../../../../components/Spinner/PageLoader';
import { isUserVerified } from '../../slices/signup/signup.actions';

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  const checkAuthState = async () => {
    const cookie = Cookies.get(process.env.REACT_APP_AUTH_COOKIE); // => 'value'
    // if cookie is present, check if it is valid
    if (cookie) {
      const { refresh_token } = JSON.parse(cookie);
      await dispatch(isUserVerified(refresh_token));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    checkAuthState();
  }, []);

  return <>{loading ? <PageLoader /> : children}</>;
};
