/**
 * @description Caches answers to filtering questions locally in Session storage
 * and remotely (Backend Db)
 *
 * @method put, get, delete, clear, initializeRemoteCache, updateRemoteCache, getRemoteCache, clearRemoteCache
 */
class AgentDb {
  constructor() {
    this.localStoragekey = 'hk_agent';
  }

  put(key, value) {
    if (typeof key !== 'string') {
      throw 'Invalid type passed as key, only string are accepted as key';
    }

    let answers = window.sessionStorage.getItem(this.localStoragekey);

    answers = answers === null ? {} : JSON.parse(answers);
    answers[key] = value;
    // store.dispatch(addSearchFilters(answers));
    window.sessionStorage.setItem(
      this.localStoragekey,
      JSON.stringify({
        ...answers,
      }),
    );
  }

  get(questionKey) {
    let answers = window.sessionStorage.getItem(this.localStoragekey);
    if (answers === null) return null;

    answers = JSON.parse(answers);
    return answers[questionKey];
  }

  putAll(answers) {
    const obj = {};
    for (let key in answers) {
      if (answers.hasOwnProperty(key) && !['', undefined, null].includes(answers[key])) {
        obj[key] = answers[key];
      }
    }
    window.sessionStorage.setItem(this.localStoragekey, JSON.stringify(obj));
    // store.dispatch(addSearchFilters(obj));
  }

  /**
   *
   * Get all answers
   */

  getAll() {
    let answers = window.sessionStorage.getItem(this.localStoragekey);
    if (answers === null) return null;

    answers = JSON.parse(answers);
    return answers;
  }
  /**
   * Remove the value of a key from the database
   *
   * @param questionKey: key of the Value to remove
   * @returns bool
   */
  delete(questionKey) {
    let answers = window.sessionStorage.getItem(this.localStoragekey);
    if (answers === null) return false;

    answers = JSON.parse(answers);
    delete answers[questionKey];

    // trigger filtering
    // store.dispatch(addSearchFilters(answers));

    window.sessionStorage.setItem(
      this.localStoragekey,
      JSON.stringify({
        ...answers,
      }),
    );

    return true;
  }

  /**
   * Remove the entire question DB - cache
   */
  clear = () => window.sessionStorage.removeItem(this.localStoragekey);
}

export default AgentDb;
