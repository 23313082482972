import * as types from '../types';

import reducer from './root';

export const getRoom = reducer(types.FETCHINGROOM, {
  users: [],
  properties: { property_info: [], vendor_property_criteria_info: [] },
  files: [],
  typingData: [],
  links: [],
});
export const createRoom = reducer(types.CREATINGROOM);
export const getAllRooms = reducer(types.FETCHINGALLROOMS, []);
export const getChatRoomPrompts = reducer(types.GET_CHAT_ROOM_PROMPTS, []);
export const deleteChatRoom = reducer(types.DELETE_CHAT_ROOM, {});
