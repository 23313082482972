import { SAVE_VENDOR_FEEDBACK, POST_VENDOR_FEEDBACK } from '../types';

export const allFeedbackReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SAVE_VENDOR_FEEDBACK:
      return {
        ...state,
        vendorFeedback: payload,
      };
    case POST_VENDOR_FEEDBACK.PENDING:
      return {
        ...state,
        pending: true,
        error: false,
      };
    case POST_VENDOR_FEEDBACK.SUCCESS:
      return {
        ...state,
        pending: false,
        error: false,
        message: payload,
      };
    case POST_VENDOR_FEEDBACK.ERROR:
      return {
        ...state,
        message: payload,
        pending: false,
        error: true,
      };
    default:
      return state;
  }
};
