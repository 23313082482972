export const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const SET_BUCKETS = 'SET_BUCKETS';
export const SET_PROFILE_LISTENER = 'SET_PROFILE_LISTENER';
export const SET_SHOW_MATCHES_LIST = ' SET_SHOW_MATCHES_LIST';

export const SET_CURRENT_BUCKET_DETAILS = 'SET_CURRENT_BUCKET_DETAILS';
export const SET_MATCHES_CURRENT_DATA = asyncType('SET_MATCHES_CURRENT_DATA');
