import * as types from '../types';

const travelModes = {
  DRIVING: 'DRIVING',
  WALKING: 'WALKING',
  BICYCLING: 'BICYCLING',
};

const travelModesArray = Object.values(travelModes);

const initialState = {
  maps: null,
  map: null,
  destinations: [
    {
      id: `item-${0}`,
      value: `item-${0}`,
      travelMode: travelModesArray[0],
      color: ['#00A6FB', '#0A95E5', '#0171A7', '#035A84', '#3ddbc8'][0],
    },
  ],
  polylines: [],
  totalDuration: 0,
  totalDistance: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ROUTINES:
      return {
        ...state,
        polylines: action.payload.polylines,
        totalDuration: action.payload.duration,
        totalDistance: action.payload.distance,
      };
    case types.SET_MAP: {
      return {
        ...state,
        map: action.payload,
      };
    }
    case types.SET_MAPS: {
      return {
        ...state,
        maps: action.payload,
      };
    }
    case types.SET_DESTINATION: {
      return {
        ...state,
        destinations: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
