import * as types from '../types';

const initialState = {
  loading: false,
  value: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SHORTLIST_FOR_PROPERTIES.PENDING: {
      return { ...state, loading: true };
    }
    case types.SET_SHORTLIST_FOR_PROPERTIES.SUCCESS: {
      return {
        ...state,
        loading: false,
        propertyId: action.payload.propertyId,
        value: action.payload.value,
      };
    }
    case types.SET_SHORTLIST_FOR_PROPERTIES.ERROR: {
      return { ...state, loading: false, propertyId: action.payload.propertyId };
    }
    default: {
      return { ...state };
    }
  }
};
