/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { getSocialRedirectLink, socialAuthenticate } from './socialAuth.actions';

const initialState = {
  data: [],
  error: false,
  pending: false,
  isSocialAuthenticated: false,
  isSocialAccountCreated: false,
  redirectLinkFetched: false,
  mode: '',
};

const socialAuthSlice = createSlice({
  name: 'socialAuth',
  initialState,
  reducers: {
    clearSocialBackendErrors(state) {
      state.error = false;
      state.data = [];
      state.redirectLinkFetched = true;
    },

    setLogout() {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSocialRedirectLink.pending, state => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getSocialRedirectLink.fulfilled, (state, action) => {
        state.data = action.payload;
        state.mode = 'facebook';
        state.error = null;
        state.pending = false;
        state.redirectLinkFetched = true;
      })
      .addCase(getSocialRedirectLink.rejected, (state, action) => {
        state.data = action.payload;
        state.error = { message: action.error.message };
        state.pending = false;
      })
      .addCase(socialAuthenticate.pending, state => {
        state.pending = true;
        state.error = null;
      })
      .addCase(socialAuthenticate.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.data = action.payload;
        state.isSocialAccountCreated = action.isSocialAccountCreated;
        state.isSocialAuthenticated = true;
      })
      .addCase(socialAuthenticate.rejected, (state, action) => {
        state.pending = false;
        state.error = { message: action.error.message };
        state.data = action.payload;
      });
  },
});

export const { clearSocialBackendErrors, setLogout } = socialAuthSlice.actions;

export default socialAuthSlice.reducer;
