/**
 * Verifies if the search term looks like a postcode
 * @param {*} search_term - Item to be searched
 */
function looksLikePostcode(searchTerm){
    const searchTermSize = searchTerm.length();
    if (searchTermSize === 2) {
        const p = parseInt(searchTerm[-1]);
        return isNaN(p)
    }else {
        const p = parseInt(searchTerm[2]);
        return isNaN(p);
    }
}

export default looksLikePostcode
