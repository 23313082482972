import { proposals } from './proposal';
import { combineReducers } from 'redux';
import { preferencesReducer } from './signup';
import { propertyReducer, allPropertiesReducer } from './property';
import {
  showAgentProfile,
  showAgentProposal,
  agentProfile,
  agentLists,
  getAgentProposal,
  currentAgent,
} from './agent';
import showAgentProfileMessageModal from './show_agent_profile_message_modal';
import { allFeedbackReducer } from './feedback';

export default combineReducers({
  proposals,
  getAgentProposal,
  currentAgent,
  feedback: allFeedbackReducer,
  profile: agentProfile,
  agents: agentLists,
  property: propertyReducer,
  properties: allPropertiesReducer,
  preferences: preferencesReducer,
  showAgentProfile: showAgentProfile,
  showAgentProposal: showAgentProposal,
  showAgentProfileMessageModal: showAgentProfileMessageModal,
});
