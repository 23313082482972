/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import api from '../../../../api';
import { isUserVerified } from '../signup/signup.actions';
import { setIsAuthenticated, setLogout } from './auth.slice';
import { setLogout as setLogoutSignup } from '../signup/signup.slice';
import { setLogout as setLogoutSocialAuth } from '../socialAuth/socialAuth.slice';

export const authenticateUser = createAsyncThunk(
  'auth/user-auth',
  async (userData, { dispatch }) => {
    const reqBody = {
      username: userData.email,
      password: userData.password,
    };
    try {
      const response = await api.post('auth', reqBody);
      if (!response) throw new Error('Could not fetch');
      const chatRoomsRes = await api.get(`/chats/chatrooms/`, {
        headers: { Authorization: `Bearer ${response.data.access_token}` },
      });
      if (!chatRoomsRes) throw new Error('Could not fetch');

      dispatch(isUserVerified(response.data.refresh_token));
      return { auth: { ...response.data, rooms: chatRoomsRes.data } };
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const authenticateAgent = createAsyncThunk(
  'auth/agent-auth',
  async (userData, { dispatch }) => {
    const reqBody = {
      username: userData.email,
      password: userData.password,
    };
    try {
      const response = await api.post('auth/agent', reqBody);
      if (!response) throw new Error('Could not fetch');
      dispatch(isUserVerified(response.data.refresh_token));
      return { auth: response.data };
    } catch (error) {
      throw new Error({
        status: error.response && error.response.status ? error.response.status : error,
        message: error.message,
      });
    }
  },
);

export const setLoginAuthDetails = userData => dispatch => {
  dispatch(isUserVerified(userData.refresh_token));
  dispatch(setIsAuthenticated({ auth: userData }));
};

export const logoutUser = () => dispatch => {
  dispatch(setLogout());
  dispatch(setLogoutSignup());
  dispatch(setLogoutSocialAuth());
  Cookies.remove(process.env.REACT_APP_AUTH_COOKIE);
  // setTimeout(() => {
  //   window.location.href = '/';
  // }, 50);
};

export default authenticateUser;
