import { GET_PREFERENCES } from '../types';

export const preferencesReducer = (initialState = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PREFERENCES.PENDING: {
      return {
        ...initialState,
        pending: true,
      };
    }
    case GET_PREFERENCES.SUCCESS: {
      return {
        ...initialState,
        languages: payload.languages,
        agencies: payload.agencies,
        roles: payload.roles,
        pending: false,
        error: false,
      };
    }
    case GET_PREFERENCES.ERROR: {
      return {
        ...initialState,
        pending: false,
        error: true,
      };
    }
    case 'STASH_IMAGE': {
      return {
        ...initialState,
        image: payload,
      };
    }
    default:
      return initialState;
  }
};
