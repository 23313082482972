import { SUBMIT_PROPOSAL } from '../types';

export const submitProposal = (initialState = { status: 'SETTLED' }, action) => {
  switch (action.type) {
    case SUBMIT_PROPOSAL.SETTLED: {
      return {
        ...initialState,
        status: 'SETTLED',
      };
    }
    case SUBMIT_PROPOSAL.PENDING: {
      return {
        ...initialState,
        status: 'PENDING',
      };
    }
    case SUBMIT_PROPOSAL.SUCCESS: {
      return {
        ...initialState,
        status: 'SUCCESS',
      };
    }
    case SUBMIT_PROPOSAL.ERROR: {
      return {
        ...initialState,
        status: 'ERROR',
      };
    }
    default:
      return initialState;
  }
};
