export const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const IS_AUTHENTICATING = asyncType('IS_AUTHENTICATING');

export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';

export const LOG_OUT = 'LOG_OUT';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const IS_VERIFIED = 'IS_VERIFIED';
