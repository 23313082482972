const selectReducers = (
  initialState = { isOpen: false, location: null, department: null },
  action,
) => {
  switch (action.type) {
    case 'SELECT_TOGGLE':
      return {
        ...initialState,
        isOpen: !initialState.isOpen,
      };
    case 'SET_LOCATION':
      return {
        ...initialState,
        location: action.payload,
      };
    case 'SET_DEPARTMENT':
      return {
        ...initialState,
        department: action.payload,
      };
    default:
      return initialState;
  }
};

export default selectReducers;
