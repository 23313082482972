/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api';
import { setUserEmail } from './password.slice';

export const resetPassword = createAsyncThunk(
  'password/reset_password',
  async (email, { dispatch }) => {
    try {
      const response = await api.post(`forgot-password`, { email });
      const { data } = response;
      const payload = {
        auth: data,
      };
      dispatch(setUserEmail({ email }));
      return payload;
    } catch (error) {
      throw new Error(error.payload);
    }
  },
);

export const validateSecurityCode = createAsyncThunk(
  'password/validate_security_code',
  async userData => {
    try {
      const response = await api.post(`validate`, userData);
      const { data } = response;
      const payload = {
        data,
        userData,
      };

      return payload;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const changePassword = createAsyncThunk('password/change_password', async userData => {
  try {
    const response = await api.post(`reset-password`, userData);
    const { data } = response;
    const payload = { auth: data };

    return payload;
  } catch (error) {
    throw new Error(error.message);
  }
});

export default resetPassword;
