import { combineReducers } from 'redux';
import authReducer from './auth';
import socialAuthReducer from './socialAuth';
import signUpReducer from './signUp';
import userReducer from './user';
import passwordReducer from './password';

export default combineReducers({
  auth: authReducer,
  socialAuth: socialAuthReducer,
  signUp: signUpReducer,
  user: userReducer,
  password: passwordReducer,
  // ...Add more auth related reducers.
});
