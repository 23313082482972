/* eslint-disable no-unused-expressions */
import React, { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { closeWebSocket, initialiseWebSocket } from '../../modules/shared/redux/actions/socket';
import ProtectedRoute from './routes/ProtectedRoute';
import InitializeChatApp from '../../modules/chat/pages/LoadChat';
import { initializeAnalytics, reportPageView } from '../../utils/helpers/analytics';

const Search = lazy(() => import('../../modules/searchV2/pages/Home'));
const QueryMap = lazy(() => import('../../modules/searchV2/pages/QueryMap'));
const Login = lazy(() => import('../../modules/authV2/pages/Login/Login'));
const Signup = lazy(() => import('../../modules/authV2/pages/Signup/Signup'));
const ChangePassword = lazy(() =>
  import('../../modules/authV2/pages/ChangePassword/ChangePassword'),
);
const ResetPassword = lazy(() => import('../../modules/authV2/pages/ResetPassword/ResetPassword'));

const ProfileV2 = lazy(() => import('../../modules/profileV2/pages/Profile'));
const VenueProperty = lazy(() => import('../../modules/propertiesV2/pages/Property/Venue'));
const CareHomeProperty = lazy(() => import('../../modules/propertiesV2/pages/Property/CareHomes'));
const StudentAccommodationProperty = lazy(() =>
  import('../../modules/propertiesV2/pages/Property/StudentAccommodation'),
);
// const AgentLogin = lazy(() => import('../../modules/authV2/pages/AgentLogin/AgentLogin'));
// const Question = lazy(() => import('../../modules/search/pages/Question'));
const Question = lazy(() => import('../../modules/search/pages/Question'));
const NewQuestions = lazy(() => import('../../modules/search/pages/NewQuestions'));
const MapPage = lazy(() => import('../../modules/search/pages/Map'));
const GetInTouch = lazy(() => import('../../modules/general/pages/GetInTouch'));
const FeedbackCard = lazy(() => import('../../modules/general/pages/GetInTouch/FeedbackCard'));
const AboutUs = lazy(() => import('../../modules/general/pages/AboutUs/AboutUs'));
const PrivacyPolicy = lazy(() => import('../../modules/general/pages/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('../../modules/general/pages/TermsOfUse'));
const Categories = lazy(() => import('../../modules/general/pages/Categories'));
const Category = lazy(() => import('../../modules/general/pages/Category'));
const Homes = lazy(() => import('../../modules/homes/pages'));
const Marketed = lazy(() => import('../../modules/marketed/pages'));
const Listings = lazy(() => import('../../modules/listings/pages'));
const Agents = lazy(() => import('../../modules/agents/pages'));
const Chat = lazy(() => import('../../modules/chat/pages'));
const AgentRestrict = lazy(() => import('../../modules/agents/pages/AgentRestrict'));
const VendorFeedBack = lazy(() => import('../../modules/vendor/pages/VendorFeedBack'));
const AgentFeedback = lazy(() => import('../../modules/agents/pages/Feedback'));
const AgentSignUp = lazy(() => import('../../modules/agents/pages/Question'));
const ProposalFormRent = lazy(() => import('../../components/ProposalFormRent/ProposalFormRent'));
const ProposalFormSale = lazy(() => import('../../components/ProposalFormSale/ProposalFormSale'));

const PageLinks = () => {
  const auth = useSelector(state => state.auth.auth.data.auth);
  const socket = useSelector(state => state.shared.mainSocket);
  const dispatch = useDispatch();
  const token = auth ? auth.access_token : '';

  const location = useLocation();

  useEffect(() => {
    if (!window.gaInitialized) {
      initializeAnalytics();
      window.gaInitialized = true;
    }
    reportPageView(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (socket.on) {
      socket.removeAllListeners();
      socket.on('user-connected', data => {
        console.log(data);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (token) {
      dispatch(initialiseWebSocket(token, socket));
    }
    return () => {
      dispatch(closeWebSocket(socket));
    };
  }, [token, dispatch, socket]);

  return (
    <>
      <InitializeChatApp />
      <Switch>
        <Route exact path="/">
          <Search />
        </Route>
        <Route exact path="/auth/user/login">
          <Login />
        </Route>
        <Route exact path="/auth/user/signup">
          <Signup />
        </Route>
        <Route exact path="/places/venue/:propertyId">
          <VenueProperty />
        </Route>
        <Route exact path="/places/care_homes/:propertyId">
          <CareHomeProperty />
        </Route>
        <Route exact path="/places/student_accommodation/:propertyId">
          <StudentAccommodationProperty />
        </Route>
        <Route exact path="/auth/change-password">
          <ChangePassword />
        </Route>
        <Route exact path="/auth/forgot-password">
          <ResetPassword />
        </Route>
        <Route exact path="/search">
          <QueryMap />
        </Route>

        <Route exact path="/profile">
          <ProfileV2 />
        </Route>
        <Route exact path="/search/:channel">
          <Question />
        </Route>
        <Route path="/search/:channel/:propertyId">
          <Question />
        </Route>
        <Route path="/homes/search" exact>
          <NewQuestions />
        </Route>
        <ProtectedRoute path="/map">
          <MapPage />
        </ProtectedRoute>
        <Route path="/marketed">
          <Marketed />
        </Route>
        <Route path="/listings">
          <Listings />
        </Route>
        <Route path="/agents">
          <Agents />
        </Route>
        <Route path="/get-in-touch">
          <GetInTouch />
        </Route>
        <Route path="/feedback">
          <FeedbackCard />
        </Route>
        <Route path="/about-us">
          <AboutUs />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms-of-use">
          <TermsOfUse />
        </Route>
        <Route path="/categories" exact>
          <Categories />
        </Route>
        <Route path="/categories/:category" exact>
          <Category />
        </Route>
        <Route path="/homes/:type" exact>
          <Homes />
        </Route>
        <Route exact path="/chat">
          <Chat />
        </Route>
        <Route exact path="/vendor-feedback">
          <VendorFeedBack />
        </Route>
        <Route exact path="/agent-feedback">
          <AgentFeedback />
        </Route>
        <Route exact path="/agent-restrict">
          <AgentRestrict />
        </Route>
        <Route exact path="/agent-signup">
          <AgentSignUp />
        </Route>
        <Route exact path="/proposal-form-rent">
          <ProposalFormRent />
        </Route>
        <Route exact path="/proposal-form-sale">
          <ProposalFormSale />
        </Route>
        <Route exact path="/matches/listings">
          <div />
        </Route>
      </Switch>
    </>
  );
};

export default PageLinks;
