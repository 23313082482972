// export const defaultState = {
//   state: false,
//   menuPage: '',
//   doubleButton: false,
//   button1: '',
//   button2: '',
//   thumbDown: false,
//   likeActive: false,
// };

const showAgentProfileMessageModal = (defaultState = false, action) => {
  if (action.type === 'SHOW_AGENT_PROFILE_MESSAGE_MODAL') {
    return action.payload;
  }
  return defaultState;
};

export default showAgentProfileMessageModal;
