export const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const GET_POI_DATA = asyncType('GET_POI_DATA');
export const SET_TIME = 'SET_TIME';
export const SET_TRAVEL_MODE = 'SET_TRAVEL_MODE';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const REMOVE_POI_DATA = 'REMOVE_POI_DATA';
export const SET_POI = 'SET_POI';
export const SET_MAP_POI = 'SET_MAP_POI ';
export const SET_MAPS_POI = 'SET_MAPS_POI ';
export const SET_POI_MARKERS = ' SET_POI_MARKERS';
export const SET_POI_ACTIVE_MARKERS = 'SET_POI_ACTIVE_MARKERS';
export const SET_POLYLINES = 'SET_POLYLINES';
