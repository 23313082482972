/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { authenticateUser } from './auth.actions';

const initialState = {
  data: [],
  error: null,
  pending: false,
  action: false,
  showLogin: false,
  showSignUp: false,
  isAuthenticated: false,
  isRestricted: false,
  lastAuthPath: null,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    showLoginForm(state, action) {
      state.showLogin = action.payload;
    },
    showSignupForm(state, action) {
      state.showSignUp = action.payload;
    },
    clearBackendErrors(state) {
      state.error = null;
    },
    setAction(state, action) {
      state.action = action.payload;
    },
    setIsAuthenticated(state) {
      state.isAuthenticated = true;
    },
    setLogout() {
      return { ...initialState };
    },
    setIsRestricted(state, action) {
      state.isRestricted = action.payload;
    },
    setLastAuthPath(state, action) {
      state.lastAuthPath = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(authenticateUser.pending, state => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(authenticateUser.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      state.data = { ...state.data, ...action.payload };
    });
    builder.addCase(authenticateUser.rejected, (state, action) => {
      state.pending = false;
      state.error = { message: action.error.message };
    });
  },
});

export const {
  showLoginForm,
  showSignupForm,
  clearBackendErrors,
  setIsAuthenticated,
  setAction,
  setLogout,
  setIsRestricted,
  setLastAuthPath,
} = authSlice.actions;

export default authSlice.reducer;
