/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import api from '../../../../api';
// eslint-disable-next-line import/no-cycle
import { addShortlist } from './shortlist.slice';

const setAuthHeader = async () => {
  const cookies = Cookies.get(process.env.REACT_APP_AUTH_COOKIE);
  const { refresh_token } = JSON.parse(cookies);

  api.defaults.headers.common.Authorization = `Bearer ${refresh_token}`;
  const request = await api.post(`/auth/refresh`);
  const accessToken = await request.data.access_token;
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  return accessToken;
};

export const getShortlistedProperties = createAsyncThunk(
  'shortlist/get_shortlisted_properties',
  async () => {
    const cookie = JSON.parse(Cookies.get(process.env.REACT_APP_AUTH_COOKIE)); // => 'value'
    try {
      const response = await api.get(`/customer/current_customer/shortlist`, {
        headers: { Authorization: `Bearer ${cookie.access_token}` },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const refetchShortlistedProperties = createAsyncThunk(
  'shortlist/refetch_shortlisted_properties',
  async () => {
    const cookie = JSON.parse(Cookies.get(process.env.REACT_APP_AUTH_COOKIE)); // => 'value'
    try {
      const response = await api.get(`/customer/current_customer/shortlist`, {
        headers: { Authorization: `Bearer ${cookie.access_token}` },
      });
      console.log('LOG 2', response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const updateShortlistedProperties = createAsyncThunk(
  'shortlist/update_property_shortlist',
  async ({ data }, { dispatch }) => {
    await setAuthHeader();
    try {
      const response = await api.put(`/customer/current_customer/shortlist/${data.id}`, {
        rate: data.rate,
        property_category: data.category,
      });
      toast.info(`Moved to ${data.rate}`, {
        position: 'bottom-left',
        icon: false,
        theme: 'dark',
        style: {
          border: '1px solid #fff',
        },
      });
      console.log('LOG', response.data);
      dispatch(refetchShortlistedProperties());
      return { value: response.data.rate, propertyId: data.id, data: response.data };
    } catch (error) {
      toast.error(`There was an error when updating shortlisted property`, {
        icon: false,
        position: 'bottom-left',
      });
      throw new Error(error.message);
    }
  },
);

export const shortlistProperty = createAsyncThunk(
  'shortlist/set_property_shortlist',
  async ({ data }, { dispatch }) => {
    await setAuthHeader();
    try {
      const response = await api.post(`/customer/current_customer/shortlist/${data.id}`, {
        rate: data.rate,
        property_category: data.category,
      });
      await dispatch(addShortlist(response.data));
      toast.info(`Saved to ${data.rate}`, {
        position: 'bottom-left',
        icon: false,
        theme: 'dark',
        style: {
          border: '1px solid #fff',
        },
      });
      return { value: response.data.rate, propertyId: data.id };
    } catch (error) {
      toast.error(`There was an error when shortlisting this property`, {
        position: 'bottom-left',
        icon: false,
        theme: 'dark',
      });
      throw new Error(error.message);
    }
  },
);

export const removeShortlistedProperty = createAsyncThunk(
  'shortlist/remove_property_shortlist',
  async ({ data }, { dispatch }) => {
    await setAuthHeader();
    try {
      const response = await api.delete(
        `/customer/current_customer/shortlist/${data.category}/${data.id}`,
      );
      toast.info(`Removed from ${data.rate}`, {
        position: 'bottom-left',
        icon: false,
        theme: 'dark',
        style: {
          border: '1px solid #fff',
        },
      });
      return { value: response.data.rate, propertyId: data.id };
    } catch (error) {
      toast.error(`There was an error, please try again`, {
        position: 'bottom-left',
        icon: false,
        theme: 'dark',
      });
      throw new Error(error.message);
    }
  },
);
