/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import api from '../../../../api';
import { USER_PROFILE } from '../../../general/redux/types';
import { setCustomerAgreement, setIsVerified } from './signup.slice';
import { setLoginAuthDetails } from '../auth/auth.actions';
import { setIsAuthenticated } from '../auth/auth.slice';
import { setProfile } from '../../../profileV2/slices/profile/profile.slice';

export const isUserVerified = (token, setSnackbar) => async dispatch => {
  try {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    const tokenResponse = await api.post(`auth/refresh`);

    const { access_token } = tokenResponse.data;
    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;

    const response = await api.get(`me`, { headers: { Authorization: `Bearer ${access_token}` } });
    const payload = {
      isVerified: response.data.data.isEmailVerified,
      isVetted: response.data.data.is_vetted,
      isAgent: response.data.data.role.includes('advertisers'),
      ...response.data.data,
    };

    if (setSnackbar) setSnackbar(!payload.isVetted);
    Cookies.set(
      process.env.REACT_APP_AUTH_COOKIE,
      JSON.stringify({ access_token, refresh_token: token }),
      {
        sameSite: 'strict',
      },
    );
    dispatch(setIsVerified(payload));
    dispatch(setIsAuthenticated({ auth: tokenResponse.data }));
    dispatch({ type: USER_PROFILE, payload: response.data.data });
    dispatch(setProfile(response.data.data));
    // set userAccess and refresh token to a cookie
  } catch (error) {
    console.log(error);
    Cookies.remove(process.env.REACT_APP_AUTH_COOKIE);
  }
};

const sendCustomerAgreement = (url, token) => async () => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  //   const response = await api.post(`/customer/accept_terms`, { document_url: url });
};

export const signUpUser = createAsyncThunk(
  'signup/signup_user',
  async ({ userData, url }, { dispatch }) => {
    try {
      const response = await api.post(`users`, userData);
      if (!response) throw new Error('Could not fetch');
      const { data } = response;
      const payload = { auth: { ...data, userData } };
      api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
      dispatch(sendCustomerAgreement(url, data.access_token));
      dispatch(setLoginAuthDetails(data));
      return payload;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const googleSignUp = createAsyncThunk(
  'signup/google_signup',
  async ({ query, userData }, { dispatch }) => {
    try {
      const response = await api.get(`auth/authorise/google${query}`);
      if (!response) throw new Error('Could not fetch');
      const { data } = response;
      const payload = { auth: { ...data, userData } };
      api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
      dispatch(setLoginAuthDetails(data));
      return payload;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const getCustomerAgreement = () => async dispatch => {
  try {
    const response = await api.get(`/customer/current/terms`);
    dispatch(setCustomerAgreement(response.data.data.document));
  } catch (error) {
    //
  }
};

export const signUpAgent = createAsyncThunk(
  'signup/signup_agent',
  async (userData, { dispatch }) => {
    try {
      const response = await api.post(`users/agent`, userData);
      const { data } = response;
      const payload = {
        auth: {
          ...data,
          userData,
        },
      };
      dispatch(setLoginAuthDetails(data));
      //   dispatch(sendAgreement(url, data.access_token));
      //   dispatch(sendAllData(allInfo, image_data, data.access_token));
      return payload;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const checkSecurityCode = createAsyncThunk(
  'signup/check_security_code',
  async (checkDetails, { dispatch }) => {
    try {
      const requestDetails = { code: checkDetails.code };
      const response = await api.post(`verification/email`, requestDetails, {
        headers: {
          Authorization: `Bearer ${checkDetails.token}`,
        },
      });
      const payload = { ...response.data };

      if (Object.keys(checkDetails.signUpData).length) {
        dispatch(isUserVerified(checkDetails.signUpData.auth.refresh_token));
      }
      return payload;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const resendSecurityCode = createAsyncThunk(
  'signup/resend_security_code',
  async checkDetails => {
    try {
      const response = await api.post(`verification/email/resend`, {
        headers: {
          Authorization: `Bearer ${checkDetails.token}`,
        },
      });
      const payload = { ...response.data };
      return payload;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);
