const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
  REMOVE: `${type}_REMOVE`,
  ADD_ONE: `${type}_ADD_ONE`,
});

export const SET_CURRENT_AGENT = 'SET_CURRENT_AGENT';
export const GET_PROFILE = asyncType('GET_PROFILE');
export const GET_AGENTS = asyncType('GET_AGENTS');
export const GET_AGREEMENTS = asyncType('GET_AGREEMENTS');
export const GET_PROPOSAL = asyncType('GET_PROPOSAL');
export const TOGGLE_SAVE_AGENT = asyncType('TOGGLE_SAVE_AGENT');
export const GET_SAVED_AGENTS = asyncType('GET_SAVED_AGENTS');
export const GET_INVITED_AGENTS = asyncType('GET_INVITED_AGENTS');
