/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routing from './approot/Routing';
import PageLoader from './components/Spinner/PageLoader';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Router>
        <Routing />
      </Router>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar draggable={false} />
    </Suspense>
  );
};

export default App;
