import * as types from '../types';
import api from '../../../../api';

const setAuthHeader = async data => {
  const refreshToken = data.auth?.refresh_token;
  api.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;
  const request = await api.post(`/auth/refresh`);
  const acccessToken = await request.data.access_token;
  api.defaults.headers.common.Authorization = `Bearer ${acccessToken}`;
};

export const getPropertyShortlist = ({ authData, propertyId }) => async dispatch => {
  dispatch({ type: types.SET_SHORTLIST_FOR_PROPERTIES.PENDING });
  await setAuthHeader(authData);
  await api
    .get(`/customer/current_customer/shortlist/${propertyId}`)
    .then(response => {
      dispatch({
        type: types.SET_SHORTLIST_FOR_PROPERTIES.SUCCESS,
        payload: { value: response.data.rate, propertyId },
      });
    })
    .catch(error => {
      dispatch({ type: types.SET_SHORTLIST_FOR_PROPERTIES.ERROR, payload: { propertyId } });
    });
};
export const updatePropertyShortlist = ({
  authData,
  data,
  propertyId,
  enqueueSnackbar,
}) => async dispatch => {
  dispatch({ type: types.SET_SHORTLIST_FOR_PROPERTIES.PENDING });
  await setAuthHeader(authData);
  await api
    .put(`/customer/current_customer/shortlist/${propertyId}`, { rate: data })
    .then(response => {
      dispatch({
        type: types.SET_SHORTLIST_FOR_PROPERTIES.SUCCESS,
        payload: { value: response.data.rate, propertyId },
      });
      enqueueSnackbar(response.data.message, {
        variant: 'info',
      });
    })
    .catch(() => {
      dispatch({ type: types.SET_SHORTLIST_FOR_PROPERTIES.ERROR, payload: { propertyId } });
      enqueueSnackbar(`There was an error when shortlisting this property`, {
        variant: 'info',
      });
    });
};
export const setPropertyShortlist = ({
  authData,
  data,
  propertyId,
  enqueueSnackbar,
}) => async dispatch => {
  dispatch({ type: types.SET_SHORTLIST_FOR_PROPERTIES.PENDING });
  await setAuthHeader(authData);
  await api
    .post(`/customer/current_customer/shortlist/${propertyId}`, { rate: data })
    .then(response => {
      dispatch({
        type: types.SET_SHORTLIST_FOR_PROPERTIES.SUCCESS,
        payload: { value: response.data.rate, propertyId },
      });
      enqueueSnackbar(response.data.message, {
        variant: 'info',
      });
    })
    .catch(() => {
      dispatch({ type: types.SET_SHORTLIST_FOR_PROPERTIES.ERROR, payload: { propertyId } });
      enqueueSnackbar(`There was an error when shortlisting this property`, {
        variant: 'info',
      });
    });
};
export const setPropertyShortlistUnMount = ({ propertyId }) => dispatch => {
  dispatch({
    type: types.SET_SHORTLIST_FOR_PROPERTIES.SUCCESS,
    payload: { value: null, propertyId },
  });
};
