/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { handlePropertySearch } from './search.actions';

const NAMESPACE = 'search';

const initialState = {
  propertySearch: '',
  error: null,
  pending: false,
  viewType: 'list',
  chats: {},
  activeId: null,
};

const searchSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setPropertySearch(state, action) {
      state.propertySearch = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    setViewType(state, action) {
      state.viewType = action.payload;
    },
    setActiveId(state, action) {
      state.activeId = action.payload;
    },
    setNewChat(state, action) {
      state.chats[action.payload.id] = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(handlePropertySearch.pending, state => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(handlePropertySearch.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      if (!action.payload.hasId) {
        state.chats[action.payload.id] = action.payload.res;
      } else {
        state.chats[action.payload.id].results.push(action.payload.res);
      }
      state.propertySearch = '';
    });
    builder.addCase(handlePropertySearch.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error;
    });
  },
});

export const {
  setPropertySearch,
  setNewChat,
  clearError,
  setViewType,
  setActiveId,
} = searchSlice.actions;

export default searchSlice.reducer;
