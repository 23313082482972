import { createSlice } from '@reduxjs/toolkit';
import * as propertiesJson from '../../utils/properties.json';
import { getPropertyInformation, getPropertyDetails, getShortlistDetails } from './property.action';

const initialState = {
  properties: [],
  activeProperty: null,
  propertyShortlistData: null,
  pending: false,
  error: null,
  hoveredProperty: null,
};

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setActiveActiveProperty(state, action) {
      state.activeProperty = action.payload;
    },
    setProperties(state, action) {
      state.properties = action.payload;
    },
    setHoveredProperty(state, action) {
      state.hoveredProperty = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPropertyDetails.pending, state => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getPropertyDetails.fulfilled, (state, action) => {
        state.activeProperty = action.payload.data;
        state.pending = false;
        state.error = null;
      })
      .addCase(getPropertyDetails.rejected, (state, action) => {
        state.error = action.error;
        state.pending = false;
      })
      .addCase(getShortlistDetails.pending, state => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getShortlistDetails.fulfilled, (state, action) => {
        state.propertyShortlistData = action.payload.data;
        state.pending = false;
        state.error = null;
      })
      .addCase(getShortlistDetails.rejected, (state, action) => {
        state.error = action.error;
        state.pending = false;
      })
      .addCase(getPropertyInformation.pending, state => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getPropertyInformation.fulfilled, (state, action) => {
        state.activeProperty = action.payload.data;
        state.pending = false;
        state.error = null;
      })
      .addCase(getPropertyInformation.rejected, (state, action) => {
        state.error = action.error;
        state.pending = false;
      });
  },
});

export const {
  setToggleMap,
  setHoveredProperty,
  setProperties,
  setActiveMapData,
} = propertySlice.actions;

export default propertySlice.reducer;
