import { createSlice } from '@reduxjs/toolkit';

const NAMESPACE = 'routine';

const travelModes = {
  DRIVING: 'DRIVING',
  WALKING: 'WALKING',
  BICYCLING: 'BICYCLING',
};

const travelModesArray = Object.values(travelModes);

const initialState = {
  activeRoutineProperty: null,
  destinations: [
    {
      id: 0,
      value: `item-${0}`,
      travelMode: travelModesArray[0],
      color: '#00A6FB',
    },
  ],
  polylines: [],
  totalDuration: 0,
  totalDistance: 0,
};

const routineSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setRoutines(state, action) {
      state.polylines = action.payload.polylines;
      state.totalDuration = action.payload.duration;
      state.totalDistance = action.payload.distance;
    },
    setDestination(state, action) {
      state.destinations = action.payload;
    },
    addDestination(state, action) {
      state.destinations.push(action.payload);
    },
    updateDestination(state, action) {
      state.destinations[action.payload.id] = action.payload;
    },
    updateDestinationTravelMode(state, action) {
      state.destinations[action.payload.id] = {
        ...state.destinations[action.payload.id],
        travelMode: action.payload.travelMode,
      };
    },
    setActiveRoutineProperty(state, action) {
      state.activeRoutineProperty = action.payload;
    },
  },
});

export const {
  setDestination,
  setRoutines,
  addDestination,
  setActiveRoutineProperty,
  updateDestination,
  updateDestinationTravelMode,
} = routineSlice.actions;

export default routineSlice.reducer;
