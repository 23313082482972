/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as types from '../types';

const initialState = {
  references: {
    homeAmenities: [],
    form: [],
    parking: [],
    type: [],
    error: false,
    pending: false,
  },
};

export default produce((draft, action) => {
  switch (action.type) {
    case types.GET_QUESTION_REFERENCES.PENDING:
      draft.references.homeAmenities = [];
      draft.references.form = [];
      draft.references.parking = [];
      draft.references.type = [];
      draft.references.error = false;
      draft.references.pending = true;
      break;
    case types.GET_QUESTION_REFERENCES.SUCESS:
      draft.references.homeAmenities = action.payload.homeAmenities;
      draft.references.form = action.payload.form;
      draft.references.parking = action.payload.parking;
      draft.references.type = action.payload.type;
      draft.references.error = false;
      draft.references.pending = false;
      break;

    case types.GET_QUESTION_REFERENCES.ERROR:
      draft.references.homeAmenities = [];
      draft.references.form = [];
      draft.references.parking = [];
      draft.references.type = [];
      draft.references.error = true;
      draft.references.pending = false;
      break;
    default:
      break;
  }
}, initialState);
