import { combineReducers } from 'redux';
import { addUser, removeUser } from './users';
import { removeProperty } from './properties';
import { getRoom, getAllRooms, createRoom, getChatRoomPrompts, deleteChatRoom } from './room';
import { webSocket, message, typing, chatRoomReference, expectingRoom } from './socket';
import { getMessages, chatOpen, numberOfUnreadMessages } from './messages';

export default combineReducers({
  typing,
  message,
  getRoom,
  addUser,
  chatOpen,
  webSocket,
  createRoom,
  expectingRoom,
  removeUser,
  getMessages,
  getAllRooms,
  deleteChatRoom,
  getChatRoomPrompts,
  removeProperty,
  chatRoomReference,
  numberOfUnreadMessages,
});
