/* eslint-disable camelcase */
import Cookies from 'js-cookie';
import api from '../api';

const setAuthHeader = async () => {
  const cookies = Cookies.get(process.env.REACT_APP_AUTH_COOKIE);
  const { refresh_token } = JSON.parse(cookies);

  api.defaults.headers.common.Authorization = `Bearer ${refresh_token}`;
  const request = await api.post(`/auth/refresh`);
  const accessToken = request.data.access_token;
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  Cookies.set(
    process.env.REACT_APP_AUTH_COOKIE,
    JSON.stringify({ access_token: accessToken, refresh_token }),
    {
      sameSite: 'strict',
    },
  );
  return accessToken;
};
export default setAuthHeader;
