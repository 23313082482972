import * as types from '../types';
import api from '../../../../api';

export const getPropertyFilters = filters => {
  const coordinates =
    filters.geojsonType === 'polygon' ? filters.centerCoordinates : filters.coordinates;

  let polygonArea = filters.areaOfPolygon;
  polygonArea = !polygonArea ? 0 : parseFloat(polygonArea);

  let { radius } = filters;
  if (filters.geojsonType !== 'point' && polygonArea > 0 && radius > 0) {
    radius += polygonArea;
  } else if (parseInt(filters.radius, 10) === 0 && filters.geojsonType === 'point') {
    radius = 1.5;
  }
  const commonFilters = {
    areaOfPolygon:
      filters.geojsonType === 'polygon' && [0, '0', undefined, null].includes(radius)
        ? polygonArea
        : 0,
    locationShape: filters.geojsonType === 'polygon' ? 'polygon' : 'circle',
    radius,
    lat: coordinates ? coordinates[1] : null,
    lng: coordinates ? coordinates[0] : null,
    bedroomMin: (filters.bedrooms && filters.bedrooms[0]) || 0,
    bedroomMax: filters.bedrooms && filters.bedrooms[1],
    bathrooms:
      filters.bathrooms && filters.bathrooms.length > 0 && JSON.stringify(filters.bathrooms),
    tenureType: filters.tenure,
    moveInDate: filters.moveInDate && filters.moveInDate.join('-'),
    proForm:
      filters.propertyForm &&
      filters.propertyForm.length > 0 &&
      JSON.stringify(filters.propertyForm),
    proType:
      filters.propertyType &&
      filters.propertyType.length > 0 &&
      JSON.stringify(filters.propertyType),
    parking: filters.parking && filters.parking.length > 0 && JSON.stringify(filters.parking),
    pois: filters.pois && JSON.stringify(filters.pois),
    // propertyAmenities use to be homeAmenities
    propertyAmenities:
      filters.propertyAmenities &&
      filters.propertyAmenities.length > 0 &&
      JSON.stringify(filters.propertyAmenities),
    // pois: filters.pois && JSON.stringify(filters.pois),
  };

  if (filters.geojsonType === 'polygon') {
    commonFilters['placeID'] = filters.placeId;
  }
  const queryFilters =
    filters.channel === 'buy'
      ? {
          ...commonFilters,

          proChannel: 'sale',
          salePriceMin: filters.price && filters.price[0],
          salePriceMax: filters.price && filters.price[1],
          bedroomMin: (filters.bedrooms && filters.bedrooms[0]) || 0,
        }
      : {
          ...commonFilters,
          proChannel: 'rental',
          rentPriceMin: filters.price && filters.price[0],
          rentPriceMax: filters.price && filters.price[1],
          furnished: filters.furnished,
          havePets: filters.pets,
          rentalTermMin: filters.rentalTerm && filters.rentalTerm[0],
          rentalTermMax: filters.rentalTerm && filters.rentalTerm[1],
          bedroomMin: filters.bedrooms && (filters.bedrooms[0] || 0),
        };

  const filtersQueryString = Object.keys(queryFilters).reduce(
    (cleanFilters, filterKey) =>
      queryFilters[filterKey]
        ? { ...cleanFilters, [filterKey]: queryFilters[filterKey] }
        : cleanFilters,
    {},
  );
  if (filtersQueryString.bedroomMin) {
    return filtersQueryString;
  }
  return { ...filtersQueryString, bedroomMin: 0 };
};
export const getProperties = (id, filters) => async dispatch => {
  try {
    const filtersQueryString = getPropertyFilters(filters);
    dispatch({ type: types.GET_PROPERTIES.PENDING });
    const response = await api.get('search-property-live', {
      params: { ...filtersQueryString },
    });
    dispatch({ type: types.GET_PROPERTIES.SUCESS, payload: response.data.properties });
  } catch (error) {
    dispatch({ type: types.GET_PROPERTIES.ERROR });
  }
};

export const loadGeoData = status => dispatch => {
  dispatch({ type: types.GEO_DATA_LOADED, payload: status });
};

export const getPropertyInfo = propertyId => async dispatch => {
  try {
    dispatch({ type: types.GET_PROPERTY_INFO.PENDING });
    const response = await api.get(`property/${propertyId}/information`);
    dispatch({ type: types.GET_PROPERTY_INFO.SUCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.GET_PROPERTY_INFO.ERROR });
  }
};

export const getPropertyWeather = propertyId => async dispatch => {
  try {
    dispatch({ type: types.GET_PROPERTY_WEATHER.PENDING });
    const response = await api.get(`property/weather/${propertyId}/average`);
    const { data } = response;
    const temperature = data.month.map((month, index) => ({
      id: data.weather_id[index],
      month,
      temperature: data.average_temperature[index],
    }));
    const payload = { boroughId: data.borough_id, address: data.address, temperature };
    dispatch({ type: types.GET_PROPERTY_WEATHER.SUCESS, payload });
  } catch (error) {
    dispatch({ type: types.GET_PROPERTIES.ERROR });
  }
};

export const getPropertyDetails = propertyId => async dispatch => {
  try {
    dispatch({ type: types.GET_PROPERTY_SUMMARY.PENDING });
    const response = await api.get(`property/${propertyId}/media-data`);
    dispatch({ type: types.GET_PROPERTY_SUMMARY.SUCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.GET_PROPERTY_SUMMARY.ERROR });
  }
};

export const getNearBySoldProperties = (propertyId, year) => async dispatch => {
  try {
    dispatch({ type: types.GET_NEAR_BY_SOLD_PROPERTIES.PENDING });
    const response = await api.get(`property/${propertyId}/market-information-areaprop`, {
      params: { year },
    });
    dispatch({ type: types.GET_NEAR_BY_SOLD_PROPERTIES.SUCESS, payload: response.data.properties });
  } catch (error) {
    dispatch({ type: types.GET_NEAR_BY_SOLD_PROPERTIES.ERROR });
  }
};

export const getPriceHistory = propertyId => async dispatch => {
  try {
    dispatch({ type: types.GET_PRICE_HISTORY.PENDING });
    const response = await api.get(`property/${propertyId}/market-information-history`);
    dispatch({ type: types.GET_PRICE_HISTORY.SUCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.GET_PRICE_HISTORY.ERROR });
  }
};

export const getAverageSaleHistory = (propertyId, years) => async dispatch => {
  try {
    dispatch({ type: types.GET_AVERAGE_SALE_HISTORY.PENDING });
    const response = await api.get(`property/${propertyId}/market-information-average`, {
      params: {
        years,
      },
    });
    dispatch({ type: types.GET_AVERAGE_SALE_HISTORY.SUCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.GET_AVERAGE_SALE_HISTORY.ERROR });
  }
};

export const getCostOfLivingDetails = propertyId => async dispatch => {
  try {
    dispatch({ type: types.GET_COST_OF_LIVING.PENDING });
    const response = await api.get(`property/col/${propertyId}/defaults`);
    dispatch({ type: types.GET_COST_OF_LIVING.SUCESS, payload: response.data.metrics });
  } catch (error) {
    dispatch({ type: types.GET_COST_OF_LIVING.ERROR });
  }
};

export const getCachedProperties = cachePropertiesId => async dispatch => {
  try {
    dispatch({ type: types.GET_PROPERTIES.PENDING });
    // return to default state
    const { data } = await api.get(`poi/cached-properties?cacheId=${cachePropertiesId}`);
    dispatch({ type: types.GET_PROPERTIES.SUCESS, payload: data.payload });
  } catch (error) {
    dispatch({ type: types.GET_PROPERTIES.ERROR });
  }
};
