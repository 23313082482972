/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { removePoiData, setPoi, setSearchValuePoi } from './poi.slice';

import { QuestionDb, poiMarkersColors } from '../../../../utils';
import { PoiPlaceCache } from '../../../../utils/poiPlacesDb';
import { isNumberOrNumericString } from '../../../../utils/is_number_or_numeric_string';
// import { GET_POI_MARKERS } from '../types';

const baseColors = ['#00A6FB', '#2888b8', '#0171A7', '#13435f', '#E07179'];

export const getPoisMarkers = createAsyncThunk(
  'poi/get_pois_markers',
  async ({ poiName, poiKey, prevPlaces, action }) => {
    try {
      if (action === 'UPDATE_STATE') {
        return prevPlaces;
      }
      const questionDb = new QuestionDb();
      let coordinates = questionDb.get('centerCoordinates');
      coordinates = `coordinate:${coordinates[0]},${coordinates[1]}`; // coordinate:lng/lat
      const areaOfPolygon = questionDb.get('areaOfPolygon');
      const radius = questionDb.get('radius') ?? 0;
      const wholeRadius =
        areaOfPolygon === undefined ? Number(radius) : Number(areaOfPolygon) + Number(radius);

      const url = `google/places/${poiName}/${coordinates}/${(wholeRadius + 2) * 1609}`;
      const { data } = await api.get(url);
      if (data.status === 'success' && data.places.length > 0) {
        const markers = prevPlaces.filter(places => places.poiKey !== poiKey);
        await new PoiPlaceCache().put(poiKey, poiName, data.places);
        markers.push({
          poiName,
          poiKey,
          places: data.places,
          color: poiMarkersColors[Number(poiKey)],
        });
        return markers;
      }
      return [];
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

const fetchUniqueItemInArray = (arr, comparison) => {
  const obj = {};
  arr.forEach(item => {
    obj[item.name] = item;
  });
  const newPois = [];
  if (comparison) {
    comparison.forEach(item => {
      Object.values(obj).forEach(point => {
        if ((item.location || item.poi_name)?.includes(point.name)) {
          newPois.push(point);
        }
      });
    });
  }
  return comparison ? newPois : Object.values(obj);
};

export const getPoiData = createAsyncThunk(
  'poi/get_poi_data',
  async (
    { searchValue, time, timeUnit, travelMode, center, enqueueSnackbar, itemId },
    { dispatch, getState },
  ) => {
    const {
      searchV2: {
        poi: { pois },
      },
      pois: pastPois,
    } = getState();
    let poisList = [...pois];
    try {
      const { lat, lng } = center;
      const found = poisList.find(
        element =>
          element.name === searchValue &&
          element.time === time &&
          element.travelMode === travelMode,
      );
      const tr = travelMode.toLowerCase();
      const t = isNumberOrNumericString(time) ? `${time}${timeUnit || 'mins'}` : time;
      const url = `poi/direction?origin=coordinate:${lng || 0},${lat ||
        0}&destination=place:${searchValue}&transitMethod=${tr}&travelTime=${t}`;

      if (searchValue.length < 3) {
        dispatch(setSearchValuePoi(''));
        toast.error('Enter a valid search value');
        throw new Error('Enter a valid search value');
      } else if (found) {
        dispatch(setSearchValuePoi(''));
        // toast.error('You have already searched for this');
        throw new Error('You have already searched for this');
      } else {
        const response = await api.get(url);
        let poi_id;
        if (poisList.length > 0) poi_id = itemId ?? poisList.length;
        else poi_id = 0;

        if (response.data.status !== 'success') {
          poisList = pois.filter(item => item.name !== searchValue);
          const [, currentTime, unit] =
            typeof time === 'string' ? time.split(/(\d+)/) : ['', time, timeUnit || 'mins'];
          const isHour = unit === 'hour' || currentTime >= 60;
          const hourTime = unit === 'hour' ? currentTime : currentTime / 60;
          const errorTime = isHour
            ? `${hourTime} ${hourTime > 1 ? 'hours' : 'hour'}`
            : `${currentTime} minutes`;
          enqueueSnackbar(
            `No amenities called ${searchValue} is within ${errorTime} of ${travelMode}`,
            {
              variant: 'info',
            },
          );
        } else {
          const poiValue = {
            id: `item-${poi_id}`,
            value: `item-${poi_id}`,
            name: `${searchValue}`,
            travelMode,
            time,
            color: [baseColors[poi_id % baseColors.length]],
            data: response.data.payload,
          };
          poisList = fetchUniqueItemInArray([...poisList, poiValue], pastPois);
          dispatch(setSearchValuePoi(''));
          return poisList;
        }
      }
      return poisList;
    } catch (err) {
      throw new Error('An error occured with your search');
    }
  },
);

export const removePOIData = ({ pois, poi }) => dispatch => {
  let poisList = [];
  if (pois.length === 0) return;
  const payload = [
    ...pois
      .filter(dest => dest.name !== poi.name)
      .map((dest, index) => ({
        ...dest,
        id: `item-${index}`,
        value: `item-${index}`,
      })),
  ];
  poisList = fetchUniqueItemInArray(payload);
  dispatch(removePoiData(poisList));
};

export const updatePOI = ({ duration, name }, pois) => dispatch => {
  const stalePOI = pois
    .filter(poi => poi.name === name)
    .map(poi => {
      return { ...poi, data: { ...poi.data, route: { ...poi.data.route, duration } } };
    });
  const activePOI = pois.filter(poi => poi.name !== name);
  const newPoiList = [...stalePOI];
  newPoiList[0].data.route.duration = duration;
  const newPOI = activePOI.concat(newPoiList);
  dispatch(setPoi(newPOI));
};
