import React from 'react';
import styled from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';

const SpinnerBox = styled.div`
  display: flex !important;
  justify-content: center;
  flex-direction: 'row';
  align-items: center;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  background: #000000;
  z-index: 9999;
  & > div {
    display: flex;
  }
`;

const PageLoader = ({ size, margin }) => {
  return (
    <SpinnerBox>
      <PulseLoader size={size ?? 20} margin={margin ?? 6} color="#E07179" />
    </SpinnerBox>
  );
};

export default PageLoader;
