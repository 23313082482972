/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import * as types from '../types';

export const addSearchFilters = filters => dispatch => {
  dispatch({ type: types.ADD_SEARCH_FILTERS, payload: filters });
};

export const updateSearchFilter = payload => dispatch => {
  dispatch({ type: types.UPDATE_SEARCH_FILTER, payload: payload });
};
