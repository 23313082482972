// npm run prod
const production = Object.freeze({
  // provides endpoint to fetch geojsons
  // changes made to the geojson endpoint should be effected
  // on the src/views/searchData/components/map.jsx file
  geojson_endpoint: 'https://staging.plaices.ai/polygon/',
  // provide access to elastic search instance on the jenkins server
  es_endpoint: 'https://staging.plaices.ai/api/',
  // provide access to the backend logic
  // api_endpoint: 'api-prod/api/',
  // api_endpoint: 'https://plaices.ai/api/',
  api_endpoint: 'https://plaicesbackendapi.azurewebsites.net/api/',
  // provide access to the global web socket
  main_io_endpoint: 'wss://plaices.ai//',
  // provide access to the chat web socket
  io_endpoint: 'wss://plaices.ai//chat',
});

// for frontend devlopers
const development = Object.freeze({
  // provides endpoint to fetch geojsons
  geojson_endpoint: 'https://staging.plaices.ai/polygon/',
  // provide access to elastic search instance on the jenkins server
  es_endpoint: 'https://staging.plaices.ai/api/',
  // provide access to the backend logic
  //   api_endpoint: 'https://plaices.ai/api/',
  api_endpoint: 'http://localhost:5000/api/',
  // provide access to the global web socket
  main_io_endpoint: 'wss://localhost:5000/',
  // provide access to the chat web socket
  io_endpoint: 'wss://plaices.ai/chat',
});

// alias for development
// npm run dev:fe
const fe = development;

// for fullstack developers, who are making changes on FE and BE
// npm run dev:fs
const fs = Object.freeze({
  geojson_endpoint: 'http://localhost:5000/polygon/',
  es_endpoint: 'http://localhost:5000/api/',
  api_endpoint: 'http://localhost:5000/api/',
  main_io_endpoint: 'ws://localhost:5000/',
  io_endpoint: 'ws://localhost:5000/chat',
});

export { production, development, fs };
