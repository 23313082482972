/* eslint-disable import/prefer-default-export */
import * as types from '../types';
import api from '../../../../api';

export const getQuestionReferences = () => async dispatch => {
  try {
    dispatch({ type: types.GET_QUESTION_REFERENCES.PENDING });
    const response = await api.get('questions-references');
    const { data } = response;
    const payload = {
      homeAmenities: data.homeAmenitiesRef,
      form: data.propertyFormRef,
      parking: data.propertyParkingRef,
      type: data.propertyTypeRef,
    };
    dispatch({ type: types.GET_QUESTION_REFERENCES.SUCESS, payload });
  } catch (error) {
    dispatch({ type: types.GET_QUESTION_REFERENCES.ERROR });
  }
};
