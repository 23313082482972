export const channelType = Object.freeze({
    CHANGE_CHANNEL: 'CHANGE_CHANNEL',
})

export const setChannel = (state) => {
    return {
        type: channelType.CHANGE_CHANNEL,
        payload: state,
    }
}
