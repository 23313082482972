/**
 * @description Caches answers to filtering questions locally in Session storage
 * and remotely (Backend Db)
 *
 * @method put, get, delete, clear, initializeRemoteCache, updateRemoteCache, getRemoteCache, clearRemoteCache
 */
import { isEmpty } from 'lodash';

import api from '../api';

import { store } from '../redux';
import { addSearchFilters } from '../modules/search/redux/actions';

class QuestionDb {
  constructor() {
    this.localStoragekey = 'hk_answers';
  }

  put(key, value) {
    if (typeof key !== 'string') {
      throw 'Invalid type passed as key, only string are accepted as key';
    }

    let answers = window.sessionStorage.getItem(this.localStoragekey);

    answers = answers === null ? {} : JSON.parse(answers);
    answers[key] = value;
    store.dispatch(addSearchFilters(answers));
    window.sessionStorage.setItem(
      this.localStoragekey,
      JSON.stringify({
        ...answers,
      }),
    );
  }

  get(questionKey) {
    let answers = window.sessionStorage.getItem(this.localStoragekey);
    if (answers === null) return null;

    answers = JSON.parse(answers);
    return answers[questionKey];
  }

  putAll(answers) {
    const obj = {};
    for (let key in answers) {
      if (answers.hasOwnProperty(key) && !['', undefined, null].includes(answers[key])) {
        obj[key] = answers[key];
      }
    }
    window.sessionStorage.setItem(this.localStoragekey, JSON.stringify(obj));
  }

  /**
   *
   * Get all answers
   */

  getAll() {
    let answers = window.sessionStorage.getItem(this.localStoragekey);
    if (answers === null) return null;

    answers = JSON.parse(answers);
    return answers;
  }
  /**
   * Remove the value of a key from the database
   *
   * @param questionKey: key of the Value to remove
   * @returns bool
   */
  delete(questionKey) {
    let answers = window.sessionStorage.getItem(this.localStoragekey);
    if (answers === null) return false;

    answers = JSON.parse(answers);
    delete answers[questionKey];

    // trigger filtering
    store.dispatch(addSearchFilters(answers));

    window.sessionStorage.setItem(
      this.localStoragekey,
      JSON.stringify({
        ...answers,
      }),
    );

    return true;
  }

  /**
   * Remove the entire question DB - cache
   */
  clear = () => window.sessionStorage.removeItem(this.localStoragekey);

  /**
   * Makes request to API to initialize caching
   * @returns (int) filterID
   */
  async initializeRemoteCache(placeId, channel, localCached, userId) {
    const defaults = {};
    for (let key in localCached) {
      defaults[key] = localCached[key];
      if (key === 'coordinates') {
        defaults['centerCoordinates'] = localCached[key];
        delete defaults['coordinates'];
      }
    }
    const reqPayload = { placeId, channel, ...defaults };
    if (userId) {
      reqPayload['userId'] = userId;
    }
    const { data } = await api.post('filter/answer/', reqPayload);
    if (data.status.toLowerCase() === 'success') {
      return data.data.filterId;
    }
    return null;
  }
  async updateRemoteCache(filterId, question, answer, userId) {
    const reqPayload = {};
    reqPayload[question] = answer;
    if (userId) {
      reqPayload['userId'] = userId;
    }
    await api.put(`filter/answer/${filterId}`, reqPayload);
  }

  /**
   * Replicate a cache filter when a URL is shared with another user
   * @param {string} filterId
   * @returns Object mapping the cached to it corresponding values
   */
  async replicateRemoteCache(filterId) {
    const { data } = await api.post(`filter/answer/${filterId}`);
    if (data.status.toLowerCase() === 'success') {
      const response = data.data;
      const result = {};
      // filter out null and empty response
      for (let key in response) {
        if (response[key] !== null || (Array.isArray(response[key]) && !isEmpty(response[key]))) {
          result[key] = response[key];
        }
        if (key === 'centerCoordinates' && response.geojsonType === 'point') {
          result['coordinates'] = response[key];
          delete result['centerCoordinates'];
        }
      }
      return result;
    }

    return {};
  }

  async getRemoteCache(filterId) {
    const { data } = await api.get(`filter/answer/${filterId}`);
    if (data.status.toLowerCase() === 'success') {
      const response = data.data;
      const result = {};
      // filter out null and empty response
      for (let key in response) {
        if (response[key] !== null || (Array.isArray(response[key]) && !isEmpty(response[key]))) {
          result[key] = response[key];
        }
        if (key === 'centerCoordinates' && response.geojsonType === 'point') {
          result['coordinates'] = response[key];
          delete result['centerCoordinates'];
        }
      }
      return result;
    }
    return {};
  }

  clearRemoteCache() {}
}

export default QuestionDb;
