import axios from 'axios';
import * as types from '../types';
import { ApiEndpoints } from '../../../../Constants';

const baseColors = ['#00A6FB', '#0A95E5', '#0171A7', '#035A84', '#3ddbc8'];

export const setMapPOI = map => dispatch => {
  dispatch({ type: types.SET_MAP_POI, payload: map });
};

export const setMapsPOI = maps => dispatch => {
  dispatch({ type: types.SET_MAPS_POI, payload: maps });
};

export const updatePOI = ({ duration, name }, pois) => dispatch => {
  const stalePOI = pois.filter(poi => poi.name === name);
  const activePOI = pois.filter(poi => poi.name !== name);
  stalePOI[0].data.route.duration = duration;
  const newPOI = activePOI.concat(stalePOI);
  dispatch({ type: types.SET_POI, payload: newPOI });
};

export const setPolylines = polylines => dispatch => {
  dispatch({ type: types.SET_POLYLINES, payload: polylines });
};

export const setPOIMarkers = maps => dispatch => {
  dispatch({ type: types.SET_POI_MARKERS, payload: maps });
};

export const setPOIActiveMarkers = maps => dispatch => {
  dispatch({ type: types.SET_POI_ACTIVE_MARKERS, payload: maps });
};

var poisList = [];
const fetchUniqueItemInArray = (arr, comparison) => {
  let obj = {};
  arr.forEach(item => {
    obj[item.name] = item;
  });
  var newPois = [];
  if (comparison) {
    comparison.forEach(item => {
      Object.values(obj).forEach(point => {
        if ((item.location || item.poi_name)?.includes(point.name)) {
          newPois.push(point);
        }
      });
    });
  }
  return comparison ? newPois : Object.values(obj);
};

export const removePOIData = ({ pois, poi }) => dispatch => {
  if (pois.length === 0) return;
  const payload = [
    ...pois
      .filter(dest => dest.name !== (poi.poi_name || poi.name))
      .map((dest, index) => ({
        ...dest,
        id: `item-${index}`,
        value: `item-${index}`,
      })),
  ];
  poisList = fetchUniqueItemInArray(payload);
  dispatch({ type: types.REMOVE_POI_DATA, payload });
};

export const getPOIData = ({
  searchValue,
  time,
  timeUnit,
  travelMode,
  center,
  pois,
  enqueueSnackbar,
  noSnackbar,
  pastPois,
}) => dispatch => {
  dispatch({ type: types.GET_POI_DATA.PENDING });
  const { lat, lng } = center;
  const found = pois.find(element => {
    return (
      element.name === searchValue && element.time === time && element.travelMode === travelMode
    );
  });
  const tr = travelMode.toLowerCase();
  const t = `${time}${timeUnit || 'mins'}`;

  const url = `${ApiEndpoints.api_endpoint}poi/direction?origin=coordinate:${lng || 0},${lat ||
    0}&destination=place:${searchValue}`.concat(`&transitMethod=${tr}&travelTime=${t}`);
  const config = { method: 'get', url, headers: {} };
  if (searchValue.length < 3) {
    dispatch({
      type: types.GET_POI_DATA.ERROR,
      payload: { errorMessage: 'Enter a valid search value', error: true },
    });
    dispatch({ type: types.SET_SEARCH_VALUE, payload: '' });
    enqueueSnackbar(`Enter a valid search value`, {
      variant: 'info',
    });
    setTimeout(
      () =>
        dispatch({ type: types.GET_POI_DATA.ERROR, payload: { errorMessage: '', error: false } }),
      3000,
    );
  } else if (found !== undefined) {
    dispatch({
      type: types.GET_POI_DATA.ERROR,
      payload: { errorMessage: 'You have already searched for this', error: true },
    });
    dispatch({ type: types.SET_SEARCH_VALUE, payload: '' });
    !noSnackbar &&
      enqueueSnackbar(`You have already searched for this`, {
        variant: 'info',
      });
    setTimeout(
      () =>
        dispatch({ type: types.GET_POI_DATA.ERROR, payload: { errorMessage: '', error: false } }),
      3000,
    );
  } else {
    axios(config)
      .then(response => {
        let poi_id;
        if (poisList.length > 0) {
          poi_id = poisList.length;
          const unique = [...new Set(poisList.map(item => parseInt(item.id.split('-')[1])))].sort(
            (a, b) => a - b,
          );
          const getSkippedId = arr => {
            const N = arr.length;
            let b = new Uint8Array(arr[N - 1] + 1);
            for (let i = 0; i < N; i++) {
              b[arr[i]] = 1;
            }
            for (let i = arr[0]; i <= arr[N - 1]; i++) {
              if (b[i] == 0) {
                return i;
              }
            }
          };
          unique.forEach(item => {
            if (poi_id == item || poi_id > 5) {
              const newId = getSkippedId(unique);
              poi_id = newId || poi_id + 1;
            }
          });
        } else {
          poi_id = 0;
        }
        if (response.data.status === 'success') {
          const poiValue = {
            id: `item-${poi_id}`,
            value: `item-${poi_id}`,
            name: `${searchValue}`,
            travelMode,
            time,
            color: baseColors[poi_id] || 'green',
            data: response.data.payload,
          };
          poisList = fetchUniqueItemInArray([...poisList, poiValue], pastPois);
          const payload = poisList;
          dispatch({
            type: types.GET_POI_DATA.SUCCESS,
            payload,
          });
          dispatch({ type: types.SET_SEARCH_VALUE, payload: '' });
        } else {
          poisList = poisList.filter(item => item.name !== searchValue);
          dispatch({
            type: types.GET_POI_DATA.ERROR,
            payload: { errorMessage: response.data.description, error: true, pois: poisList },
          });
          const [dummy, currentTime, unit] =
            typeof time == 'string' ? time.split(/(\d+)/) : ['', time, timeUnit || 'mins'];
          const isHour = unit == 'hour' || currentTime >= 60;
          const hourTime = unit == 'hour' ? currentTime : currentTime / 60;
          const errorTime = isHour
            ? `${hourTime} ${hourTime > 1 ? 'hours' : 'hour'}`
            : `${currentTime} minutes`;
          enqueueSnackbar(
            `No amenities called ${searchValue} is within ${errorTime} of ${travelMode}`,
            {
              variant: 'info',
            },
          );
          setTimeout(
            () =>
              dispatch({
                type: types.GET_POI_DATA.ERROR,
                payload: { errorMessage: '', error: false },
              }),
            3000,
          );
        }
      })
      .catch(response => {
        console.log('ERROR', response);
        dispatch({
          type: types.GET_POI_DATA.ERROR,
          payload: { errorMessage: 'An error occured with your search', error: true },
        });
        enqueueSnackbar('An error occured with your search', {
          variant: 'info',
        });
        setTimeout(
          () =>
            dispatch({
              type: types.GET_POI_DATA.ERROR,
              payload: { errorMessage: '', error: false },
            }),
          3000,
        );
      });
  }
};
export const setTime = time => dispatch => {
  dispatch({ type: types.SET_TIME, payload: time });
};

export const setTravelMode = travelMode => dispatch => {
  dispatch({ type: types.SET_TRAVEL_MODE, payload: travelMode });
};
export const setSearchValue = value => dispatch => {
  dispatch({ type: types.SET_SEARCH_VALUE, payload: value });
};
