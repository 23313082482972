import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAllRooms, getRoom, sendSeen } from '../redux/actions/room';
import {
  closeWebSocket,
  initialiseWebSocket,
  messageResponse,
  typingResponse,
  updateRoom,
} from '../redux/actions/socket';
import { FETCHINGALLROOMS, NUMBEROFUNREADMESSAGES } from '../redux/types';
import { CHATROOMREFERENCE, EXPECTINGROOMEMOVE } from '../redux/types/socket';
import { clearChatState } from '../utils';

const InitializeChatApp = () => {
  const { auth } = useSelector(state => state.auth.auth.data);

  const queryParams = new URLSearchParams(window.location.search);
  const urlId = queryParams.get('id');
  const { isAgent } = useSelector(state => state.auth.signUp.check);
  const { data: rooms } = useSelector(state => state.chat.getAllRooms);
  const { data: room } = useSelector(state => state.chat.getRoom);
  const chatOpen = useSelector(state => state.chat.chatOpen);
  const messages = useSelector(state => state.chat.message);
  const socket = useSelector(state => state.chat.webSocket);
  const roomReference = useSelector(state => state.chat.chatRoomReference);
  const expectingRoom = useSelector(state => state.chat.expectingRoom);
  const { userId } = useSelector(state => state.auth.signUp.check);

  const dispatch = useDispatch();
  const history = useHistory();

  const haveRoom = !!rooms[0];

  const refresh_token = auth ? auth.refresh_token : undefined;

  const token = auth
    ? auth.access_token
      ? auth.access_token
      : auth.auth
      ? auth.auth.access_token
      : ''
    : '';

  useEffect(() => {
    if (token) {
      dispatch(initialiseWebSocket(token, socket));
    }

    return () => {
      dispatch(closeWebSocket(socket));
    };
  }, [token]);

  useEffect(() => {
    if (token) {
      dispatch(getAllRooms(refresh_token, rooms, isAgent, !urlId));
    }
  }, [isAgent, token]);

  useEffect(() => {
    if (socket.on) {
      socket.removeAllListeners();
      socket.on('user-connected', data => {});

      socket.on('room-created', data => {
        const socketRoom = data.data;
        if (roomReference) {
          const tempRoomReference = { ...roomReference };
          tempRoomReference[socketRoom[`${isAgent ? 'customer_id' : 'agent_id'}`]] = {
            roomId: socketRoom.room_id,
            vpcIds: socketRoom.vendor_property_criteria_ids,
            propertyIds: socketRoom.property_ids,
          };
          dispatch({ type: CHATROOMREFERENCE, payload: tempRoomReference });
        }

        const toCompare = isAgent ? socketRoom.customer_id : socketRoom.agent_id;
        socket.emit('join-room', socketRoom.room_id);
        if (expectingRoom.includes(toCompare)) {
          dispatch(sendSeen(token, socketRoom.room_id, rooms));
          dispatch({
            type: FETCHINGALLROOMS.SUCCESS,
            payload: [
              {
                ...socketRoom,
                last_message: socketRoom.message,
                last_message_type: 'text',
                property_ids: socketRoom.property_ids,
                room_properties_vpc_ids: socketRoom.vendor_property_criteria_ids,
                thumbnail: isAgent ? socketRoom.customer_thumbnail : socketRoom.agent_thumbnail,
                unread_messages: 0,
                update_date: null,
                date: socketRoom.create_date,
                customer_name: socketRoom.customer_name,
                agent_name: socketRoom.agent_name,
              },
              ...rooms,
            ],
          });
          dispatch(clearChatState());
          history.push(`/chat?id=${socketRoom.room_id}`, { fromWithin: true });
        } else {
          dispatch({
            type: FETCHINGALLROOMS.SUCCESS,
            payload: [
              {
                ...socketRoom,
                last_message: socketRoom.message,
                last_message_type: 'text',
                property_ids: socketRoom.property_ids,
                room_properties_vpc_ids: socketRoom.vendor_property_criteria_ids,
                thumbnail: isAgent ? socketRoom.customer_thumbnail : socketRoom.agent_thumbnail,
                unread_messages: 0,
                update_date: null,
                date: socketRoom.create_date,
                customer_name: socketRoom.customer_name,
                agent_name: socketRoom.agent_name,
              },
              ...rooms,
            ],
          });
          // dispatch({ type: NUMBEROFUNREADMESSAGES.UPDATE, payload: 0 });
          !rooms[0] &&
            dispatch(
              getRoom(
                refresh_token,
                socketRoom.room_id,
                isAgent,
                undefined,
                socketRoom[`${isAgent ? 'customer_id' : 'agent_id'}`],
              ),
            );
        }
      });

      socket.on('typing-response', data => {
        dispatch(typingResponse(data.data, rooms, room, true));
      });

      socket.on('stopped-typing-response', data => {
        dispatch(typingResponse(data.data, rooms, room, false));
      });

      socket.on('send-message-server-response', data => {
        if (!chatOpen) {
          data.data.unread = true;
        }
        const id = isAgent ? data.data.customer_id : data.data.agent_id;
        data.data.room_id == room.roomId &&
          dispatch(messageResponse(messages, data, room, chatOpen));
        data.status.toLowerCase() !== 'error' &&
          dispatch(
            updateRoom(
              data.data,
              rooms,
              room.roomId,
              chatOpen,
              !(
                expectingRoom.includes(id) ||
                (data.data.message_type == 'meta' && data.data.author == userId)
              ),
            ),
          );
        expectingRoom.includes(id) &&
          data.data.message_type !== 'meta' &&
          dispatch({ type: EXPECTINGROOMEMOVE, payload: id });
      });
    }
  }, [socket, messages, rooms, room, token, expectingRoom, chatOpen, userId]);

  return <></>;
};

export default React.memo(InitializeChatApp);
