import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HTTP_STATUS, setAuthHeaders } from '../../utils';
import Api from '../../api';

const NAMESPACE = 'customerCriteria';

const initialState = {
  status: HTTP_STATUS.IDLE,
  message: undefined,
  customerCriteria: undefined,
};

export const getCustomerCreteria = createAsyncThunk(
  `${NAMESPACE}/fetchData`,
  async ({ authData }) => {
    await setAuthHeaders({ authData });
    const { data } = await Api.get('/customer/col/criteria');
    return data.payload;
  },
);

export const postCustomerCreteria = createAsyncThunk(`${NAMESPACE}/add`, async hkAnswers => {
  const { data } = await Api.post('/customer/col/criteria', hkAnswers);
  return data;
});

export const putCustomerCreteria = createAsyncThunk(`${NAMESPACE}/update`, async () => {});

export const deleteCustomerCreteria = createAsyncThunk(`${NAMESPACE}/remove`, async () => {});

const customerCriteriaSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCustomerCreteria.pending, state => {
        state.status = HTTP_STATUS.LOADING;
      })
      .addCase(getCustomerCreteria.rejected, (state, { error }) => {
        state.message = !error.status
          ? 'Error with your network connection'
          : error.message ?? 'SERVER ERROR';
        state.status = HTTP_STATUS.ERROR;
      })
      .addCase(getCustomerCreteria.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(postCustomerCreteria.pending, (state, action) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(postCustomerCreteria.fulfilled, (state, action) => {
        state.customerCriteria = action.payload;
        // todo - check that action.status === 'success'
        // status code 200 does not nesseccary mean success
        state.status = HTTP_STATUS.DONE;
      })
      .addCase(postCustomerCreteria.rejected, (state, { error }) => {
        state.status = HTTP_STATUS.ERROR;
        state.message = !error.status
          ? 'Error with your network connection'
          : error.message ?? 'SERVER ERROR';
      });
  },
});

export default customerCriteriaSlice.reducer;
