import produce from 'immer';
import { GET_POI_MARKERS } from '../types';

const intialState = {
  data: [],
  error: false,
  pending: false,
  markersLoaded: false,
};

const poiMarkersReducers = (state = intialState, action) => {
  switch (action.type) {
    case GET_POI_MARKERS.PENDING:
      return {
        ...state,
        error: false,
        pending: true,
      };
    case GET_POI_MARKERS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        pending: false,
        markersLoaded: true,
      };
    case GET_POI_MARKERS.ERROR:
      return {
        ...state,
        error: true,
        pending: false,
      };
    default:
      return state;
  }
};

export { poiMarkersReducers };
