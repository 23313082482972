const routines = (
  defaultState = [
    {
      location: '',
      method: 'drive',
      timeOfTheDay: 'morning',
      key: '1',
    },
    {
      location: '',
      method: 'drive',
      timeOfTheDay: 'evening',
      key: '2',
    },
  ],
  action,
) => {
  if (action.type === 'ADD_ROUTINE') {
    return [...action.payload];
  }
  return defaultState;
};

export default routines;
