/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { getPoiData, getPoisMarkers } from './poi.actions';

const NAMESPACE = 'poi';

const initialState = {
  activePoiProperty: null,
  map: null,
  maps: null,
  loading: false,
  error: false,
  errorMessage: '',
  poiActiveMarkers: [],
  polylines: [],
  pois: [],
  formData: {
    travelMode: 'drive',
    searchValue: '',
    time: '10',
  },
  poiMarkers: {
    data: [],
    error: false,
    pending: false,
    markersLoaded: false,
  },
};

const poiSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setActivePoiProperty(state, action) {
      state.activePoiProperty = action.payload;
    },
    setMapPoi(state, action) {
      state.map = action.payload;
    },
    setMapsPoi(state, action) {
      state.maps = action.payload;
    },

    setPoiMarkers(state, action) {
      state.poiMarkers.data = action.payload;
    },
    setPoiActiveMarkers(state, action) {
      state.poiActiveMarkers = action.payload;
    },
    setPoi(state, action) {
      state.pois = action.payload;
    },
    setPolylines(state, action) {
      state.polylines = action.payload;
    },
    setTimePoi(state, action) {
      state.formData.time = action.payload;
    },
    setTravelModePoi(state, action) {
      state.formData.travelMode = action.payload;
    },
    setSearchValuePoi(state, action) {
      state.formData.searchValue = action.payload;
    },
    removePoiData(state, action) {
      state.pois = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPoiData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPoiData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.pois = action.payload;
        state.markersLoaded = true;
      })
      .addCase(getPoiData.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(getPoisMarkers.pending, state => {
        state.poiMarkers.pending = true;
        state.poiMarkers.error = null;
      })
      .addCase(getPoisMarkers.fulfilled, (state, action) => {
        state.poiMarkers.pending = false;
        state.poiMarkers.data = action.payload;
        state.poiMarkers.error = null;
        state.poiMarkers.markersLoaded = true;
      })
      .addCase(getPoisMarkers.rejected, (state, action) => {
        state.poiMarkers.pending = false;
        state.poiMarkers.error = action.error;
      });
  },
});

export const {
  setActivePoiProperty,
  setMapPoi,
  setMapsPoi,
  setPoi,
  setPoiActiveMarkers,
  setPoiMarkers,
  setPolylines,
  setSearchValuePoi,
  setTimePoi,
  setTravelModePoi,
  removePoiData,
} = poiSlice.actions;

export default poiSlice.reducer;
