import * as types from '../types';

const initialState = {
  data: {},
  userData: {},
  error: false,
  pending: false,
  isAccountCreated: false,
  check: {
    data: [],
    error: false,
    pending: false,
    isAccountVerified: false,
  },
  resend: {
    data: [],
    error: false,
    pending: false,
    isCodeResent: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REGISTERING_USER.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
        check: {
          data: [],
          error: false,
          pending: false,
          isAccountVerified: false,
        },
        resend: {
          data: [],
          error: false,
          pending: false,
          isCodeResent: false,
        },
      };
    }
    case types.REGISTERING_USER.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: false,
        pending: false,
        isAccountCreated: true,
        userData: action.payload,
      };
    }
    case types.REGISTERING_USER.ERROR: {
      return {
        ...state,
        data: action.payload,
        error: true,
        pending: false,
      };
    }
    case types.REGISTERING_AGENT.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
        check: {
          data: [],
          error: false,
          pending: false,
          isAccountVerified: false,
        },
        resend: {
          data: [],
          error: false,
          pending: false,
          isCodeResent: false,
        },
      };
    }
    case types.REGISTERING_AGENT.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: false,
        pending: false,
        isAccountCreated: true,
        userData: action.payload,
      };
    }
    case types.REGISTERING_AGENT.ERROR: {
      return {
        ...state,
        data: action.payload,
        error: true,
        pending: false,
      };
    }
    case types.CLEAR_SIGNUP_ERRORS: {
      return {
        ...state,
        data: [],
        error: false,
        pending: false,
      };
    }
    case types.IS_VERIFIED: {
      return {
        ...state,
        check: {
          ...state.check,
          isAccountVerified: action.payload.isVerified,
          isAgent: action.payload.isAgent,
          isVetted: action.payload.isVetted,
          ...action.payload,
        },
      };
    }
    case types.CHECK_SECURITY_CODE.PENDING: {
      return {
        ...state,
        check: {
          ...state.check,
          error: false,
          pending: true,
        },
      };
    }
    case types.CHECK_SECURITY_CODE.SUCCESS: {
      return {
        ...state,
        data: [],
        check: {
          ...state.check,
          data: action.payload,
          error: false,
          pending: false,
          isAccountVerified: true,
        },
      };
    }
    case types.CHECK_SECURITY_CODE.ERROR: {
      return {
        ...state,
        check: {
          ...state.check,
          data: action.payload,
          error: true,
          pending: false,
        },
      };
    }
    case types.RESEND_SECURITY_CODE.PENDING: {
      return {
        ...state,
        resend: {
          ...state.resend,
          error: false,
          pending: true,
        },
      };
    }
    case types.RESEND_SECURITY_CODE.SUCCESS: {
      return {
        ...state,
        resend: {
          ...state.resend,
          data: action.payload,
          error: false,
          pending: false,
          isCodeResent: true,
        },
      };
    }
    case types.RESEND_SECURITY_CODE.ERROR: {
      return {
        ...state,
        resend: {
          ...state.resend,
          data: action.payload,
          error: true,
          pending: false,
        },
      };
    }
    case types.GET_CUSTOMER_AGREEMENT: {
      return {
        ...state,
        agreement_url: action.payload,
      };
    }
    case types.LOG_OUT: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};
