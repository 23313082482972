import * as types from '../types';

const initialState = {
  data: [],
  error: false,
  pending: false,
  isSocialAuthenticated: false,
  isSocialAccountCreated: false,
  redirectLinkFetched: false,
  mode: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SOCIAL_REDIRECT.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
        mode: action.mode,
      };
    }
    case types.FETCH_SOCIAL_REDIRECT.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: false,
        pending: false,
        redirectLinkFetched: true,
      };
    }
    case types.FETCH_SOCIAL_REDIRECT.ERROR: {
      return {
        ...state,
        data: action.payload,
        error: true,
        pending: false,
      };
    }
    case types.CLEAR_REDIRECT_ERRORS: {
      return {
        ...state,
        data: [],
        error: false,
        redirectLinkFetched: true,
      };
    }
    case types.SET_SOCIAL_PARAMS: {
      return {
        ...state,
        data: action.payload,
        isSocialAuthenticated: false,
        error: false,
      };
    }
    case types.LOGOUT_SOCIAL_USER: {
      return {
        ...initialState,
      };
    }
    case types.SOCIAL_AUNTENTICATION.PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
      };
    }
    case types.SOCIAL_AUNTENTICATION.SUCCESS: {
      return {
        ...state,
        data: action.payload.auth,
        isSocialAuthenticated: true,
        isSocialAccountCreated: action.isSocialAccountCreated,
        error: false,
        pending: false,
      };
    }
    case types.SOCIAL_AUNTENTICATION.ERROR: {
      return {
        ...state,
        data: action.payload,
        error: true,
        pending: false,
      };
    }
    default: {
      return state;
    }
  }
};
