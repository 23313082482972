const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const CREATINGROOM = asyncType('CREATINGROOM');
export const FETCHINGROOM = asyncType('FETCHINGROOM');
export const FETCHINGALLROOMS = asyncType('FETCHINGALLROOMS');

export const GET_CHAT_ROOM_PROMPTS = asyncType('GET_CHAT_ROOM_PROMPTS');
export const DELETE_CHAT_ROOM = asyncType('DELETE_CHAT_ROOM');
