import {
  FETCH_MARKETED_PROPERTIES,
  FETCHED_MARKETED_PROPERTY,
  UPDATED_MARKETED_PROPERTY,
  MARK_AS_COMPLETE,
} from '../types';

const state = {
  property: {},
  markAsComplete: {},
};

export const marketedPropertiesReducer = (initialState = state, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MARKETED_PROPERTIES.PENDING: {
      return {
        ...initialState,
        error: false,
        pending: true,
      };
    }
    case FETCH_MARKETED_PROPERTIES.SUCCESS: {
      return {
        ...initialState,
        error: false,
        pending: false,
        properties: payload,
      };
    }
    case FETCH_MARKETED_PROPERTIES.ERROR: {
      return {
        ...initialState,
        error: true,
        pending: false,
        message: 'Unable to get marketed properties',
      };
    }
    case FETCHED_MARKETED_PROPERTY.PENDING: {
      return {
        ...initialState,
        property: {
          error: false,
          pending: true,
          updated: false,
        },
      };
    }
    case FETCHED_MARKETED_PROPERTY.SUCCESS: {
      return {
        ...initialState,
        property: {
          error: false,
          pending: false,
          data: payload,
        },
      };
    }
    case FETCHED_MARKETED_PROPERTY.ERROR: {
      return {
        ...initialState,
        property: {
          error: true,
          pending: false,
          message: 'Unable to get property data, please reload the page',
        },
      };
    }
    case UPDATED_MARKETED_PROPERTY.PENDING: {
      return {
        ...initialState,
        property: {
          ...initialState.property,
          error: false,
          pending: true,
          updated: false,
          message: null,
        },
      };
    }
    case UPDATED_MARKETED_PROPERTY.SUCCESS: {
      return {
        ...initialState,
        property: {
          ...initialState.property,
          error: false,
          pending: false,
          updated: true,
          message: 'Property updated successfully',
        },
      };
    }
    case UPDATED_MARKETED_PROPERTY.ERROR: {
      return {
        ...initialState,
        property: {
          ...initialState.property,
          error: true,
          pending: false,
          message: 'Unable to update property data, please try again',
        },
      };
    }
    case MARK_AS_COMPLETE.PENDING: {
      return {
        ...initialState,
        markAsComplete: {
          ...initialState.markAsComplete,
          error: false,
          pending: true,
          updated: false,
          message: null,
        },
      };
    }
    case MARK_AS_COMPLETE.SUCCESS: {
      return {
        ...initialState,
        markAsComplete: {
          ...initialState.markAsComplete,
          error: false,
          pending: false,
          updated: true,
          message: 'Transacition data updated successfully',
        },
      };
    }
    case MARK_AS_COMPLETE.ERROR: {
      return {
        ...initialState,
        markAsComplete: {
          ...initialState.markAsComplete,
          error: true,
          pending: false,
          message: 'Unable to update transaction data, please try again',
        },
      };
    }
    default:
      return initialState;
  }
};
