import { io } from 'socket.io-client';
import { ApiEndpoints } from '../../../../Constants';

import * as types from '../types';

export const closeWebSocket = socket => {
  if (socket.connected) {
    socket.disconnect();
  }

  return {
    type: types.CLOSE_MAIN_WEBSOCKET,
  };
};

export const initialiseWebSocket = (token, webSocket) => async dispatch => {
  dispatch(closeWebSocket(webSocket));

  const socket = io(`${ApiEndpoints.main_io_endpoint}?token=${token}`, { forceNew: true });

  dispatch({ type: types.INITIALISE_MAIN_WEBSOCKET, payload: socket });
};
