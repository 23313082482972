import * as types from '../types';

const initialState = {
  loading: false,
  loading2: false,
  list: [],
  count: null,
  propertyData: null,
  propertyInfo: null,
  propertyWeather: null,
  center: { lat: 0, lng: 0 },
  currentIndex: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_SHORTLIST.PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_ALL_SHORTLIST.SUCCESS: {
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        count: action.payload.count,
      };
    }
    case types.GET_ALL_SHORTLIST.ERROR: {
      return { ...state, loading: false, list: [], count: null };
    }
    case types.SET_SHORTLIST_CURRENT_DATA.PENDING: {
      return { ...state, loading2: true };
    }
    case types.SET_SHORTLIST_CURRENT_DATA.SUCCESS: {
      return {
        ...state,
        loading2: false,
        propertyData: action.payload.data,
        propertyInfo: action.payload.info,
        propertyWeather: action.payload.weather,
        center: action.payload.center,
      };
    }
    case types.SET_SHORTLIST_CURRENT_DATA.ERROR: {
      return {
        ...state,
        loading2: false,
        propertyData: null,
        propertyInfo: null,
        propertyWeather: null,
        center: { lat: 0, lng: 0 },
      };
    }
    case types.SET_PROPERTY_INDEX: {
      return { ...state, currentIndex: action.payload };
    }
    case types.SET_PROPERTY_ID: {
      return { ...state, propertyId: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
