import * as types from '../types';
import { CHATOPEN, NUMBEROFUNREADMESSAGES } from '../types/messages';

import reducer from './root';

export const getMessages = reducer(types.FETCHINGMESSAGES, { messages: [], page: 0, end: false });

export const numberOfUnreadMessages = (state = 0, action) => {
  switch (action.type) {
    case NUMBEROFUNREADMESSAGES.REPLACE:
      return action.payload;

    case NUMBEROFUNREADMESSAGES.UPDATE:
      return state + action.payload;

    default:
      return state;
  }
};

export const chatOpen = (state = false, action) => {
  return action.type === CHATOPEN ? action.payload : state;
};
