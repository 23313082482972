const asyncType = type => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
  REMOVE: `${type}_REMOVE`,
  ADD_ONE: `${type}_ADD_ONE`,
});

export const SET_PROPERTY_INDEX = 'SET_PROPERTY_INDEX1';
export const GET_PROPERTIES = asyncType('GET_PROPERTIES');
export const GET_SAVED_PROPERTIES = asyncType('GET_SAVED_PROPERTIESs');
