import * as types from '../types';
import api from '../../../../api';

export const getUserDetails = token => async dispatch => {
  try {
    dispatch({ type: types.GET_USER_DETAILS.PENDING });

    const response = await api.get(`me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data } = response;
    const payload = {
      auth: data,
    };

    dispatch({ type: types.GET_USER_DETAILS.SUCCESS, payload });
  } catch (error) {
    dispatch({
      type: types.GET_USER_DETAILS.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message:
          (error.response && error.response.data && error.response.data.message) || error.message,
      },
    });
  }
};

export const updateUserDetails = (userData, token) => async dispatch => {
  try {
    dispatch({ type: types.UPDATE_USER_DETAILS.PENDING });

    const response = await api.put(`me`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data } = response;
    const payload = {
      auth: data,
    };

    dispatch({ type: types.UPDATE_USER_DETAILS.SUCCESS, payload });
  } catch (error) {
    dispatch({
      type: types.UPDATE_USER_DETAILS.ERROR,
      payload: {
        status: error.response && error.response.status ? error.response.status : error,
        error,
        message:
          (error.response && error.response.data && error.response.data.message) || error.message,
      },
    });
  }
};

export const clearUserBackendErrors = () => dispatch => {
  dispatch({ type: types.CLEAR_USER_ERRORS });
};

export default getUserDetails;
