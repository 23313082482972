import {
  ADD_PROPERTY,
  DELETE_PROPERTY,
  GET_PROPERTIES,
  UPDATE_PROPERTY_DB,
  UPDATE_PROPERTY,
  UPDATING_PROPERTY,
  CLOSE_SNACK_BAR,
} from '../types';

const state = {
  data: {},
  showSnackbar: false,
  isUpdating: false,
};

export const allPropertiesReducer = (initialState = {}, action) => {
  switch (action.type) {
    case GET_PROPERTIES.PENDING: {
      return {
        ...initialState,
        message: 'PENDING',
      };
    }
    case GET_PROPERTIES.SUCCESS: {
      return {
        ...initialState,
        allProperties: action.payload,
        message: 'SUCCESS',
        noCriteria: false,
      };
    }
    case GET_PROPERTIES.ERROR: {
      return {
        ...initialState,
        message: 'ERROR',
        noCriteria: true,
      };
    }
    default:
      return initialState;
  }
};

export const propertyReducer = (initialState = state, action) => {
  switch (action.type) {
    case ADD_PROPERTY.PENDING: {
      return {
        ...initialState,
        message: 'PENDING',
      };
    }
    case ADD_PROPERTY.SUCCESS: {
      return {
        ...initialState,
        data: action.payload.data,
      };
    }
    case ADD_PROPERTY.ERROR: {
      return {
        ...initialState,
        showSnackbar: true,
        message: action.payload,
      };
    }
    case UPDATE_PROPERTY_DB.PENDING: {
      return {
        ...initialState,
      };
    }
    case UPDATE_PROPERTY_DB.SUCCESS: {
      return {
        ...initialState,
        data: action.payload,
        message: 'Updated Successfully',
      };
    }
    case UPDATE_PROPERTY_DB.ERROR: {
      return {
        ...initialState,
      };
    }
    case DELETE_PROPERTY.PENDING: {
      return {
        ...initialState,
      };
    }
    case DELETE_PROPERTY.SUCCESS: {
      return {
        ...initialState,
        message: action.payload,
      };
    }
    case DELETE_PROPERTY.ERROR: {
      return {
        ...initialState,
        message: action.payload,
      };
    }
    case UPDATE_PROPERTY: {
      return {
        ...initialState,
        data: action.payload,
      };
    }
    case UPDATING_PROPERTY: {
      return {
        ...initialState,
        isUpdating: action.payload.data,
        updatedAddr: action.payload.addr,
      };
    }
    case CLOSE_SNACK_BAR: {
      return {
        ...initialState,
        showSnackbar: action.payload,
      };
    }
    default:
      return initialState;
  }
};
